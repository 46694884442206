<template>
  <portal to="dialog">
    <ws-dialog
        v-model="display"
        overlay-color="transparent"
        fullscreen-align="right"
        width="320"
        no-navigation
        :title="$t('EditHeader')"
        title-icon="mdi-pencil"
        not-centered
        no-padding
        margin-right="25px"
        margin-top="68px"
        margin-bottom="25px"
        border-radius="8"
        blank
        :deselect-blind-zone-x="280"
        height="90%"
        max-height="580px"
    >


      <template #header>
        <div class="d-flex justify-space-between  pt-2" style="width: 100%" >
          <ws-navigation-header
              style="width: 100%"
              v-model="navigation"
              :items="navigationSelect"
              no-number
              padding="4px"
          />
        </div>
        <!-- Return button -->
        <div
            v-if="selectedContent"
            class="d-flex justify-space-between align-center px-6 pt-6 ">
          <h5 @click="goBack"
              :style="`color : ${wsACCENT}`"
              class="pointer text-no-wrap"
          >
            <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
            {{ $t('GoBack') }}
          </h5>
          <h5 class="text-right">{{ selectedContentText }}</h5>
        </div>

      </template>

      <template #default>
        <mainMenuEditDialog
            v-if="displayMenuDialog"
            v-model="displayMenuDialog">
        </mainMenuEditDialog>

        <div  class="overflow-y-auto pa-6 fill-height" >
          <!-- Content -->
          <div v-if="navigation === 'content'" class="fill-height">

            <!-- Section selector -->
            <template v-if="!selectedContent">
              <v-hover
                  v-for="(item, i) in contentSelect" :key="i"
                  #default="{hover}">
                <v-sheet
                    @click="sectionAction(item.value)"
                    :color="hover ? wsACCENT + '11' : null"
                    :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${wsBACKGROUND}`"
                    :class="[{'mt-2' : i > 0 }]"
                    class="d-flex justify-space-between align-center pointer wsRoundedLight pl-4 pr-3 py-3 "
                    v-ripple
                >
                  <h4 :style="`color : ${wsDARKER}`" style="font-size: 14px">
                    {{ item.name }}
                  </h4>

                  <v-icon :color="wsDARKER">mdi-menu-right</v-icon>
                </v-sheet>
              </v-hover>
            </template>

            <!-- Section Content -->
            <div v-else class="fill-height">

              <!-- Logo-->
              <div v-if="selectedContent === 'logo'">
                <ws-text-field
                    @change="updateHeader()"
                    v-if="!header.config.logo"
                    :label="$t('Title')"
                    :placeholder="$t('EnterText')"
                    v-model="header.config.title"
                />
                <div v-if="header.config.logo">
                  <v-sheet
                      :color="wsLIGHTCARD"
                      class="wsRoundedLight"

                  >
                    <v-sheet
                        :color=" header.config.style
                            ? CONFIG_PARAM_COLOR('styles', header.config.style, 'bg')
                            : GET_AVALON_COLOR(header.config.background_color)"
                        style="border-top-left-radius: 4px;border-top-right-radius: 4px;"
                        class="pa-3"
                    >
                      <v-img
                          transition="xxx"
                          position="top"
                          contain
                          :src="header.config.logo"
                          class="wsRoundedLight"
                      />
                    </v-sheet>

                    <v-btn @click="$store.state.avalon.header.config.logo=null ; updateHeader()" text class="noCaps " :color="wsACCENT">
                      <v-icon class="mr-2">mdi-delete-outline</v-icon>
                      {{ $t('Delete') }}
                    </v-btn>
                  </v-sheet>
                  <a-parameter-slider
                      v-if="!MOBILE_VIEW"
                      @change="updateHeader"
                      v-model="$store.state.avalon.header.config.logo_height"
                      default="65"
                      :track-color="wsDARKLIGHT"
                      :value-color="wsACCENT"
                      :label="$t('LogoHeight')"
                      class="mt-4"
                      unit="px"
                      min="25"
                      :max="$store.state.avalon.header.config.height"
                  />
                </div>
                <ws-file-uploader v-else @success="updateLogo($event)"  class="mt-4" image public />
              </div>
              <!-- Button-->
              <div v-if="selectedContent === 'button'" class="pt-2">


                <v-sheet
                    v-if="!$store.state.avalon.header.config.hide_button"
                    :style="`border : 1px solid ${wsBACKGROUND}; border-radius : 8px`">
                  <!-- Primary Style-->
                  <div @click="changeButtonStyle('primary')" class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        @prevent="changeButtonStyle('primary')"
                        prevent-select radio :selected-color="wsACCENT" :value="header.config.style_button === 'primary'"  :small="false" :color="wsACCENT" class="mr-1 ml-2" />
                    <v-sheet
                        :color="wsACCENT"
                        class="d-flex align-center justify-center mr-3"
                        min-width="84" min-height="42"
                        dark
                    >
                      <h3 :style="`color : white`" >
                        Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonPrimary') }}</h5>
                  </div>
                  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                  <!-- Secondary Style-->
                  <div
                      @click="changeButtonStyle('secondary')"
                      class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        :value="header.config.style_button === 'secondary' "
                        :selected-color="wsACCENT"
                        :color="wsACCENT"
                        @prevent="changeButtonStyle('secondary')"
                        :small="false"
                        class="mr-1 ml-2"
                        prevent-select radio
                    />
                    <v-sheet
                        :style="`border : 2px solid ${wsACCENT}`"
                        class="d-flex align-center justify-center mr-3"
                        min-width="84" min-height="42"
                    >
                      <h3 :style="`color : ${wsACCENT}`">
                        Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonSecondary') }} </h5>
                  </div>
                  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                  <!-- Text Style-->
                  <div
                      @click="changeButtonStyle('text')"
                      class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        @prevent="changeButtonStyle('text')"
                        :value="header.config.style_button === 'text' "
                        :selected-color="wsACCENT"
                        :color="wsACCENT"
                        :small="false"
                        prevent-select radio
                        class="mr-1 ml-2"
                    />
                    <v-sheet
                        color="transparent"
                        class="d-flex align-center mr-3"
                        min-width="84" min-height="42"
                    >
                      <h3 :style="`color : ${wsACCENT}`" class="text-no-wrap">Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonText') }} </h5>
                  </div>
                </v-sheet>

                <!-- Button Rounded-->
                <a-parameter-slider
                    v-if="!$store.state.avalon.header.config.hide_button"
                    @change="updateHeader"
                    v-model="$store.state.avalon.header.config.button_radius"
                    default="65"
                    :label="$t('Rounded')"
                    :track-color="wsBACKGROUND"
                    :font-color="wsDARKER"
                    :value-color="wsACCENT"
                    class="mt-4"
                    min="0"
                    max="50"
                />
                <!-- Hide Button -->
                <a-parameter-switch
                    class="mt-5"
                    @input="updateHeader"
                    v-model="$store.state.avalon.header.config.hide_button"
                    label="avalon.header.hide_button"
                />

              </div>
              <!-- Socials-->
              <div v-if="selectedContent === 'social'"  class="fill-height">
                <!-- No Socials -->
                <div v-if="socialsItems.length === 0" class="d-flex align-center justify-center fill-height">
                  <div class="mt-n8">
                    <div class="d-flex justify-center">
                      <v-icon size="40" :color="wsDARKLIGHT">mdi-assistant</v-icon>
                    </div>
                    <h4  class="text-center mt-3">
                      {{ $t('avalon.header.socials.no_content') }}
                    </h4>
                    <ws-button
                        @click="addSocial"
                        label="avalon.header.socials.add"
                        height="40"
                        block
                        class="mt-5"
                    />

                  </div>

                </div>

                <div v-else>
                  <draggable
                      v-if="selectedSocialIndex === null"
                      v-model="header.config.socials"
                      :forceFallback="true"
                      :empty-insert-threshold="120"
                      v-bind="opt"
                      @start="drag = true"
                      @change="updateHeader"
                      handle=".topic-handle"
                      @end="drag = false"
                  >

                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <v-hover
                          #default="{hover}"
                          v-for="(item,index) in socialsItems" :key="index"
                      >
                        <v-sheet
                            @click="selectedSocialIndex = index ;"
                            :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                            class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                            height="48"
                        >
                          <div class="d-flex align-center shorten-text">
                            <v-sheet min-width="24" style="cursor: grab;"  class="topic-handle"  color="transparent">
                              <v-icon  :color="wsDARKLIGHT">mdi-drag-vertical</v-icon>
                            </v-sheet>

                            <h5 :style="!(item.name || item.link) ? `color : ${wsDARKLIGHT}` : ''" class="shorten-text" style="font-size: 14px; " >
                              {{ item.name || item.link || $t('avalon.header.socials.add_content') }}
                            </h5>
                          </div>
                          <div class="d-flex align-center">
                            <div v-if="hover" class="d-flex align-center">
                              <v-btn
                                  @click.stop="duplicateSocial(item, index)"
                                  small icon :color="wsDARKLIGHT">
                                <v-icon :size="20">mdi-content-copy</v-icon>
                              </v-btn>
                              <v-btn
                                  @click.stop="deleteSocial(index)"
                                  small icon :color="wsDARKLIGHT">
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </div>
                            <v-icon :color="wsACCENT">mdi-menu-right</v-icon>
                          </div>


                        </v-sheet>
                      </v-hover>
                    </transition-group>
                  </draggable>
                  <!-- Edit Item-->
                  <div v-else>
                    <ws-text-field
                        @change="setSocialLink"
                        v-model="$store.state.avalon.header.config.socials[selectedSocialIndex].link"
                        :label="$t('Link')"
                        :placeholder="$t('avalon.header.socials.enter_link')"
                        class="mb-5"
                        width="100%"
                    />
                    <ft-select
                        @input="selectSocial"
                        :items="SOCIALS_SELECT"
                        close-on-click
                    >
                      <ws-text-field
                          @change="updateHeader"
                          v-model="$store.state.avalon.header.config.socials[selectedSocialIndex].name"
                          :label="$t('SocialNetwork')"
                          :placeholder="$t('avalon.header.socials.choose_social')"
                          class="mb-5"
                          width="100%"
                      />
                    </ft-select>

                    <image-param-picker
                        @input="updateHeader"
                        v-model="$store.state.avalon.header.config.socials[selectedSocialIndex].img"
                    />

                    <a-parameter-slider
                        v-if="!MOBILE_VIEW"
                        @change="updateHeader"
                        v-model="$store.state.avalon.header.config.socials[selectedSocialIndex].size"
                        default="24"
                        :track-color="wsDARKLIGHT"
                        :value-color="wsACCENT"
                        :label="$t('Size')"
                        class="mt-4 pb-10"
                        unit="px"
                        min="12"
                        :max="$store.state.avalon.header.config.height"
                    />

                  </div>

                </div>

                <ws-button
                    v-if="selectedSocialIndex === null && socialsItems.length > 0"
                    @click="addSocial"
                    label="avalon.header.socials.add"
                    height="40"
                    block
                    class="mt-5"
                />

              </div>
              <!-- Other-->
              <div v-if="selectedContent === 'format'">
                <!-- Height -->
                <a-parameter-slider
                    v-if="!MOBILE_VIEW"
                    @change="updateHeader"
                    v-model="$store.state.avalon.header.config.height"
                    default="65"
                    :label="$t('Height')"
                    :track-color="wsBACKGROUND"
                    :font-color="wsDARKER"
                    :value-color="wsACCENT"
                    class="mt-4"
                    :min="$store.state.avalon.header.config.logo_height || '25'"
                    max="350"
                />
                <a-parameter-slider
                    v-if="MOBILE_VIEW"
                    @change="updateHeader"
                    v-model="$store.state.avalon.header.config.height_sm"
                    default="65"
                    :label="$t('Height')"
                    :track-color="wsBACKGROUND"
                    :font-color="wsDARKER"
                    :value-color="wsACCENT"
                    class="mt-4"
                    min="25"
                    max="350"
                />

                <a-parameter-switch
                    class="mt-5"
                    @input="updateHeader"
                    v-model="$store.state.avalon.header.config.sticky"
                    label="avalon.header.sticky"
                />
                <v-divider style="border-width: 1px" class="mt-3" :style="`border-color : ${wsBACKGROUND} `" />
                <a-parameter-switch
                    class="mt-5"
                    @input="updateHeader"
                    v-model="$store.state.avalon.header.config.overlap"
                    label="avalon.header.overlap"
                />
                <v-divider style="border-width: 1px" class="mt-3" :style="`border-color : ${wsBACKGROUND} `" />

                <v-expand-transition>
                  <div>
                    <a-parameter-slider
                        v-if="header.config.overlap"
                        @change="updateOpacity"
                        :value="100-header.config.overlay_opacity"
                        :default="30"
                        :label="$t('Opacity')"
                        :track-color="wsBACKGROUND"
                        :font-color="wsDARKER"
                        :value-color="wsACCENT"
                        class="mt-2"
                        min="0"
                        max="100"
                    />
                    <a-parameter-slider
                        v-if="header.config.overlap"
                        @change="updateHeader"
                        v-model="$store.state.avalon.header.config.blur"
                        :default="1"
                        :label="$t('HeaderBlur')"
                        :track-color="wsBACKGROUND"
                        :font-color="wsDARKER"
                        :value-color="wsACCENT"
                        class="mt-2"
                        min="0"
                        max="14"
                    />
                  </div>

                </v-expand-transition>


              </div>

            </div>

          </div>
          <!-- Style -->
          <div v-if="navigation === 'style'" class="fill-height">

            <ws-accordion
                :items="styleAccordionItems"
            >
              <template #item.design>
                <div class="pa-4">
                  <ws-select
                      class="mt-4"
                      @change="updateHeader"
                      v-model="header.config.design"
                      :items="[
                      {text : $t('Default') , value : 'default' },
                      {text : $t('Dream') , value : 'dream_style' }
                    ]"
                      :label="`${$t('Design')}`"
                      avalon-style
                      clearable
                  />
                </div>

              </template>

              <template #item.style>
                <div class="pa-4">
                  <h5 :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5 ">
                    {{ $t('BackgroundColorDescription') }}
                    <span @click="openColorSettings" class="font-weight-bold pointer linkHover" :style="`color : ${wsATTENTION}`">{{ $t('OpenSettings') }}</span>
                  </h5>
                  <v-hover
                      v-for="(style) in  AVALON_STYLES_LIST" :key="style"
                      #default="{hover}">
                    <v-sheet
                        @click="editHeaderStyle(style)"
                        :style="`box-shadow : 0 0 0 ${header.config.style === style || hover ? 2 : 1 }px ${wsDARKLIGHT} !important`"
                        class="mt-2 d-flex justify-space-between align-center pointer"
                        style="border-radius: 8px; padding : 6px 15px"
                        :color="CONFIG_PARAM_COLOR( 'styles', style , 'bg' )"
                    >
                      <h3 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'h1' )}`">Ab</h3>
                      <h5 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'p' )}`" > {{ $t('avalon.color_styles.' + style )   }} </h5>

                      <div style="width: 26px" />
                    </v-sheet>
                  </v-hover>
                </div>
              </template>
            </ws-accordion>


          </div>
        </div>

      </template>

      <template #footer>
        <v-sheet class="px-6 pb-3">
          <ws-button
              @click="resetChanges"
              :disabled="!resetActive"
              label="ResetSettings"
              height="40"
              block
              outlined
          />
          <h5 class="mt-2 text-center" :style="`color:${wsDARKLIGHT}`">{{ block.alias }}</h5>
        </v-sheet>
      </template>


  </ws-dialog>
  </portal>
</template>

<script>
import {mapActions, mapState} from "vuex";
import draggable from "vuedraggable";

import mainMenuEditDialog from "@/components/AvalonEditor/Dialogs/mainMenuEditDialog";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";

export default {
  name: "avalonHeaderSettings",
  components : {
    mainMenuEditDialog,
    draggable,
    imageParamPicker
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    block : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      //socials
      drag : false,
      selectedSocialIndex : null,

      navigation : 'content',
      display : false,
      preventClose : false,
      resetActive : false,
      bufferHeader : false,
      selectedContent : null,
      displayMenuDialog : false
    }
  },
  computed : {
    ...mapState('avalon' , ['mainMenu' , 'header' , 'pagesSelect' , 'coursesSelect']),
    ...mapState('avalonPage',['course']),

    styleAccordionItems() {
      return [
        { name : this.$t('Design') , value : 'design' , expanded : false} ,
        { name : this.$t('Style') , value : 'style', expanded : true}
      ]
    },

    socialsItems() {
      if ( !this.header.config.socials ) {
        return []
      }
      return this.header.config.socials
    },
    configStyle() {
      if ( !this.header.config ) {
        return null
      }
      return this.header.config.style
    },
    selectedContentText() {

      let item = this.contentSelect.find(el => el.value === this.selectedContent )

      if ( !item ) {
        return ''
      }
      return item.name
    },
    navigationSelect() {
      return [
        { text : this.$t('Content') , value : 'content' },
        { text : this.$t('Style')   , value : 'style'   }
      ]
    },

    layoutTypes() {
      return [
        { text : this.$t('Style') +' 1' , value : 1 },
        { text : this.$t('Style') +' 2' , value : 2 }
      ]
    },
    buttonActionTypes() {
      return [
        { text : this.$t('GoToDashboard') , value :  'dashboard' },
        { text : this.$t('OpenLogin') , value :  'auth' },
        { text : this.$t('Page') , value :  'page' },
        { text : this.$t('Link') , value :  'link' },
        { text : this.$t('EnrollOnCourse') , value :  'course' },
      ]
    },
    buttonStyleTypes() {
      return [
        { text : this.$t('avalon.button.style.plain_text') , value : 'text' },
        { text : this.$t('avalon.button.style.filled') , value : 'fill' },
        { text : this.$t('avalon.button.style.outlined') , value : 'outline' },
      ]
    },
    contentSelect() {
      return [
        { name : this.$t('MainMenuItems')     , value : 'menu'   , expanded : true },
        { name : this.$t('Logo')         , value : 'logo'   , expanded : false  },
        { name : this.$t('Socials')      , value : 'social' , expanded : false  },
        { name : this.$t('Button')       , value : 'button' , expanded : false  },
        { name : this.$t('AdditionalSettings') , value : 'format' , expanded : false  },
      ]
    },
    blockSections() {
      return [
        { name : this.$t('Style') , value : 'style' , icon : 'mdi-palette', expanded : true },
        { name : this.$t('MainMenu')  , value : 'menu' , icon : 'mdi-menu', expanded : true },
        { name : this.$t('Logo')    , value : 'logo', icon : 'mdi-image' , expanded : false  },
        { name : this.$t('Button')    , value : 'button', icon : 'mdi-button-cursor' , expanded : false  },
        { name : this.$t('format.title') , value : 'format', icon : 'mdi-arrow-all' , expanded : false  },

      ]
    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
        { text : this.$t('Video_') , value : 'video' , icon : 'mdi-video'},
        { text : this.$t('None')  ,  value : null   , icon : 'mdi-cancel'}
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    },
    configStyle(value) {
      this.$store.state.avalon.selectedBlockStyle = value
      this.$store.state.avalon.menuNavigationStyleTheme = value
    }
  },
  methods : {
    ...mapActions('avalon',[
        'ADD_MENU_ITEM',
        'EDIT_HEADER'
    ]),

    setSocialLink(value) {

      this.SOCIALS_SELECT.forEach(social => {
        if ( value && value.toLowerCase().includes(social.value) ) {
          this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].name = social.text;
          this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON(social.value)
        }
      })

      if ( value && value.toLowerCase().includes('t.me') ) {
        this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].name = 'Telegram';
        this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON('telegram')
      }

      this.updateHeader()
    },
    selectSocial($event) {
      this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON($event.toLowerCase())
      this.$store.state.avalon.header.config.socials[this.selectedSocialIndex].name = $event;
      this.updateHeader()
    },
    goBack() {
      if ( this.selectedContent === 'social' && (this.selectedSocialIndex || this.selectedSocialIndex === 0) ) {
        this.selectedSocialIndex = null
        return
      }
      this.selectedContent = null
    },
    // socials
    deleteSocial(index) {
      this.$store.state.avalon.header.config.socials.splice(index , 1)
      this.updateHeader()
    },
    duplicateSocial(item , index) {
      this.$store.state.avalon.header.config.socials.splice(index , 0 , this.COPY(item))
      this.updateHeader()
    },
    addSocial() {
      if ( !this.$store.state.avalon.header.config.socials ) {
        this.$store.state.avalon.header.config.socials = []
      }
      this.$store.state.avalon.header.config.socials.push({
        name : '',
        link : '',
        img : ''
      })
      this.$store.state.avalon.header = this.COPY(this.$store.state.avalon.header)

      this.updateHeader()
    },


    openColorSettings() {
      this.$store.state.avalon.menuNavigationStyleTheme = this.header.config.style
      this.$store.state.avalon.menuNavigation = 'styles_theme'
      this.$store.state.avalon.menuNavigationReturn = 'styles'
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true
    },
    changeButtonStyle(value) {
      this.$store.state.avalon.header.config.style_button = value
      this.updateHeader()
    },

    sectionAction(value) {
      if ( value === 'menu' ) {
        this.displayMenuDialog = true
        return
      }
      this.selectedContent = value
    },
    resetChanges() {
      this.$store.state.avalon.header = this.COPY(this.bufferHeader)
      this.updateHeader()
      this.resetActive = false
    },
    editHeaderStyle(style) {
      this.header.config.style = style
      this.updateHeader()
    },
    updateOpacity(value) {
      this.$store.state.avalon.header.config.overlay_opacity = 100 - value
      this.$store.state.avalon.header = this.COPY(this.$store.state.avalon.header)
      this.updateHeader()
    },
    updateHeader(updateTrigger) {
      this.resetActive = true
      this.$store.state.avalon.header = this.COPY(this.header)
      if ( updateTrigger ) {
        this.$store.state.avalon.updateHeaderTrigger++
      }
      this.EDIT_HEADER(this.header)
    },
    updateLogo($event) {
      this.header.config.logo = $event.url
      this.updateHeader()

    },

    addMenuItem() {
      this.ADD_MENU_ITEM()
    }
  },
  beforeMount() {
    if ( !this.$store.state.avalon.menuNavigationStyleTheme ) {
      this.$store.state.avalon.menuNavigationStyleTheme = this.header.config.style
    }
    this.$store.state.avalon.selectedElementType = 'header'
    this.$store.state.avalon.selectedBlockStyle = this.header.config.style

    this.CHECK_CONFIG_PARAM('styles')
    this.bufferHeader = this.COPY(this.header)
  },
  mounted() {
    this.display = this.value
  },
  beforeDestroy() {
    this.$store.state.avalon.selectedElementDesign = null
    this.$store.state.avalon.selectedElementType = null
    this.$store.state.avalon.selectedElementStyle = null
    this.$store.state.avalon.selectedBlockStyle = null
    this.$store.state.avalon.selectedBlockType = null
    this.$store.state.avalon.selectedBlockDesign = null
  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>