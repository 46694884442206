var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.onlyColors)?_c('v-sheet',{staticClass:"pointer d-flex justify-space-between align-center  py-2",attrs:{"color":"transparent"},on:{"click":_vm.select}},[_c('h5',{class:[{'font-weight-regular' : _vm.fontRegular}],style:(("color : " + (_vm.labelColor || _vm.wsDARKER)))},[_vm._v(_vm._s(_vm.$t(_vm.label)))]),_c('v-sheet',{staticStyle:{"border-radius":"50%"},style:(("border : " + (_vm.element === _vm.expandElement ? 2 : 1) + "px solid " + _vm.wsACCENT)),attrs:{"height":"22","width":"22","color":_vm.GET_AVALON_COLOR(_vm.color)}},[_c('span')])],1):_vm._e(),(_vm.divider)?_c('v-divider',{staticClass:"my-1",style:(("border-color : " + _vm.wsDARKLIGHT + ";"))}):_vm._e(),_c('v-expand-transition',[(_vm.expand || _vm.onlyColors)?_c('div',{class:[{'pt-3' : !_vm.onlyColors}]},[_c('v-sheet',{class:[{'pa-3' : !_vm.onlyColors}],staticStyle:{"border-radius":"8px"},attrs:{"color":!_vm.onlyColors ? _vm.wsLIGHTCARD : 'transparent'}},[(!_vm.onlyColors)?_c('h4',{style:(("color : " + _vm.wsDARKER))},[_vm._v(" "+_vm._s(_vm.$t('avalon.color_styles.choose_another_color'))+" ")]):_vm._e(),(!_vm.hidePalette)?_c('v-sheet',{staticClass:"mt-3 mb-3",class:[{'mt-3' : !_vm.onlyColors}],staticStyle:{"border-radius":"8px"},attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(configColor,index){return _c('v-hover',{key:configColor,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pointer",class:[
                      {roundedTopLeft : index === 0} ,
                      {roundedBottomLeft : index === 0} ,
                      {roundedTopRight : index === 5},
                      {roundedBottomRight : index === 5}
                    ],attrs:{"color":_vm.GET_AVALON_COLOR(configColor),"width":"100%","height":"32"},on:{"click":function($event){_vm.color = _vm.returnColor ? _vm.GET_AVALON_COLOR(configColor) : configColor}}},[_c('div',{class:[
                      {roundedTopLeft : index === 0} ,
                      {roundedBottomLeft : index === 0} ,
                      {roundedTopRight : index === 5},
                      {roundedBottomRight : index === 5}
                    ],staticStyle:{"width":"100%","height":"100%"},style:(hover || configColor === _vm.color ? ("border : 2px solid " + _vm.wsDARKLIGHT + ";") : null)})])]}}],null,true)})}),1)]):_vm._e(),(!_vm.hidePalette)?_c('h5',{staticClass:"pointer ml-n2",style:(("color : " + _vm.wsDARKER)),on:{"click":function($event){_vm.expandColorPicker = !_vm.expandColorPicker}}},[_c('v-icon',{attrs:{"color":_vm.wsDARKER}},[_vm._v("mdi-menu-"+_vm._s(!_vm.expandColorPicker ? 'right' : 'down'))]),_vm._v(" "+_vm._s(_vm.$t('ChooseColor' ))+" ")],1):_vm._e(),_c('v-expand-transition',[(_vm.expandColorPicker || _vm.hidePalette)?_c('div',[_c('ws-text-field',{staticClass:"mt-4",attrs:{"value":_vm.GET_AVALON_COLOR(_vm.color),"background-color":"white"},on:{"change":function($event){_vm.color =  $event}}}),_c('v-color-picker',{staticClass:"mt-3",staticStyle:{"background-color":"transparent"},attrs:{"value":_vm.GET_AVALON_COLOR(_vm.color),"hide-inputs":"","hide-mode-switch":""},on:{"input":_vm.changeSliderColor}})],1):_vm._e()])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }