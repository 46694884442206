<template>
  <v-sheet v-if="!nonMovingPart" color="transparent" >
    <ws-img :height="height"  :src="item.img" />

  </v-sheet>

  <div class="mt-4" v-else>
    <h4 :class="textClass" v-if="item.title" :style="BLOCK_TEXT_CSS(block , block.config.style , 'gallery-slide-wide' , 'title')" > {{ item.title }}</h4>
    <h4 :class="textClass" v-if="item.text" :style="BLOCK_TEXT_CSS(block , block.config.style , 'gallery-slide-wide' , 'text')" class="mt-3">{{ item.text }}</h4>
  </div>

</template>

<script>
export default {
  name: "gallerySlideVertical",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    nonMovingPart : {
      type : Boolean,
      default : false
    },
    blockConfig : {
      type : String,
      default : 'light_1'
    },
    block : {
      type : Object,
      default() { return { config : {}} }
    }
  },
  computed : {
    textClass() {
      let textClass = ''
      if ( this.blockConfig.description_align ) {
        return `text-${this.blockConfig.description_align}`
      }
      return textClass
    },
    height() {
      let multiplier = 1
      if ( this.SM || this.MOBILE_VIEW ) {
        multiplier = 0.25
      }
      return (this.blockConfig.image_height || 400) * multiplier
    }
  }
}
</script>

<style scoped>

</style>