<template>

  <!-- Design Settings-->
  <div v-if="design" class="pa-4">

    <v-menu offset-y  max-width="350"  >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet  v-bind="attrs" v-on="on" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pointer d-flex justify-space-between align-center pr-3">
          <v-sheet :color="wsLIGHTCARD" class="d-flex align-center justify-center wsRoundedLight mr-2 pa-2">
            <v-icon   :color="wsACCENT">mdi-palette</v-icon>
          </v-sheet>
          <h5 :style="`color : ${wsDARKER}`"> {{ selectedDesignName}} </h5>
          <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
        </v-sheet>
      </template>

      <v-sheet>

        <template v-for="(item , index) in designSelect">

          <v-hover #default="{hover}" :key="index">
            <v-sheet
                @click="block.config.design = item.value; updateSettings()"
                :color="hover ? wsLIGHTCARD : 'white' "  class="wsRoundedLight pointer d-flex justify-space-between align-center pr-3">
              <v-sheet :color="wsLIGHTCARD" class="d-flex align-center justify-center wsRoundedLight mr-2 pa-2">
                <v-icon  :color="wsACCENT">mdi-palette</v-icon>
              </v-sheet>
              <h5 :style="`color : ${wsDARKER}`"> {{  item.text  }} </h5>
              <div style="width: 24px" />
            </v-sheet>
          </v-hover>

          <v-divider :style="`border-color : ${wsDARKLIGHT}`" :key="'divider' + index" />
        </template>



      </v-sheet>

    </v-menu>


  </div>
  <!-- Format Settings-->
  <div v-else-if="format">
    <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.blocks_in_row') }}</h4>
    <ws-button-group
        v-model="block.config.columns_large"
        @input="$emit('input' , COPY(block.config))"
        :items="itemsPerPageSelect"
        class="mb-2 mt-2"
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_horizontal"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter_vertical"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_vertical"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.image_height"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="ImageHeight"
        min="0"
        max="500"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        @input="$emit('editing' , true)"
        v-model="block.config.block_radius"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Rounded"
        min="0"
        max="32"
        class="mt-4"
        light
    />


  </div>

  <!-- Content Settings-->
  <div v-else-if="!isStyle">


    <block-settings-navigation
        v-if="hasCourses"
        v-model="selectedContentCategory"
        :items="sliderContentSelect"
        :return-action="selectedItemIndex !== null ? () => { selectedItemIndex = null ; selectedContentCategory='items'  } : null"
        portal
    >
      <template #item.heading>

        <block-general-settings
            @input="updateSettings"
            v-model="block.config"
            :block="block"
            content
        />

      </template>
      <template #item.items>

        <!-- Categories-->
        <ws-select
            v-if="categoriesSelect.length > 0"
            class="mt-5"
            @input="updateSettings"
            v-model="block.config.category"
            :label="$t('Category')"
            :items="allCategoriesSelect"
            avalon-style
        />
        <!-- Items per page-->
        <a-parameter-slider
            @change="updateSettings(); "
            @input="$emit('editing' , true)"
            v-model="block.config.items_per_page"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="format.items_per_page"
            :min="block.config.columns_large"
            :max="block.config.columns_large*5"
            :step="block.config.columns_large"
            class="mt-4"
            light
        />
        <!-- Pagination-->
        <a-parameter-switch
            class="mt-6 "
            v-model="block.config.pagination"
            @input="updateSettings"
            label="Pagination"
            light
        />
        <v-divider
            class="mt-4 pb-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />
        <!-- All news Button Page Select -->
        <ws-select
            v-if="!block.config.pagination"
            class="mt-5"
            @input="updateSettings"
            v-model="block.config.all_items_page"
            :label="$t('all_items_page')"
            :items="[{ icon : 'mdi-circle-outline' ,  text : $t('NoDynamicTileButton') , value : null} , ...CURRENT_LANG_PAGES]"
            avalon-style
        />
        <ws-text-field
            v-if="!block.config.pagination && block.config.all_items_page"
            class="mt-5"
            @change="updateSettings"
            v-model="block.config.all_items_page_text"
            :label="$t('all_items_page_text')"
            :placeholder="$t('All')"
            avalon-style
            clearable
        />

        <a-parameter-switch
            v-model="block.config.display_description"
            @input="updateSettings"
            label="avalon.news.display_description"
            class="mt-6 "
            light
        />
        <v-divider
            class="mt-4 pb-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />
        <a-parameter-switch
            v-model="block.config.display_images"
            @input="updateSettings"
            label="avalon.courses.display_images"
            class="mt-6 "
            light
        />
        <v-divider
            class="mt-4 pb-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />
        <a-parameter-switch
            v-if="!block.config.category"
            v-model="block.config.display_categories"
            @input="updateSettings"
            label="avalon.news.display_categories"
            class="mt-6 "
            light
        />
        <v-divider
            class="mt-4 pb-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />



      </template>

    </block-settings-navigation>

    <div v-else  class="pt-16 fill-height">
      <div class="d-flex justify-center">
        <v-icon size="40" :color="wsDARKLIGHT">mdi-school</v-icon>
      </div>
      <h3 class="text-center">{{ $t('avalon.block.courses.addCourses') }}</h3>
      <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular text-center mt-3">
        {{ $t('avalon.block.courses.no_courses') }}
      </h5>

      <ws-button
          v-if="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'] , true)"
          :to="businessDashLink(`courses`)"
          label="CreateNewCourse"
          class="mt-6"
          block
          new-window
      />

    </div>


  </div>




</template>

<script>

import {mapActions , mapState } from "vuex";

export default {
  name: "blockTilesSettings",
  components : {
    blockSettingsNavigation : () => import("@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation"),
    blockGeneralSettings : () => import( "@/components/AvalonEditor/Dialogs/blocks/blockGeneralSettings" ),
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      hasCourses : false,
      categoriesSelect : [],
      selectedContentCategory : null,
      selectedItemIndex : null ,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,

    }
  },
  computed : {
    ...mapState('avalon' , ['editorOn' , 'selectedLang']),

    paginationSelect() {
      return [
        { text : this.$t('Pagination') , value : 'pagination' },
        { text : this.$t('SelectPages') , value : 'pages' }
      ]
    },

    allCategoriesSelect() {

      return [{ text : this.$t('All') , value : null } , ...this.categoriesSelect]
    },
    itemsPerPageSelect() {
      return [
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 }
      ]
    },
    sourcesSelect() {
      return [
        { text : this.$t('AllNews') , value : 'courses' },
        { text : this.$t('ScienceNews') , value : 'news' },
        { text : this.$t('Articles') , value : 'news' }
      ]
    },
    buttonActionsSelect() {
      return [
        { text : this.$t('OpenDetailsSidebar') , value : "sidebar" },
        { text : this.$t('OpenDetailsDialog') , value : "dialog" },
        { text : this.$t('OpenLink') , value : "link" },
        { text : this.$t('RegisterOnCourse') , value : "course" },
      ]
    },
    sliderContentSelect() {
      return [
        { text: this.$t('TitleAndDetails'), value: 'heading' },
        { text: this.$t('Content'), value: 'items' },
      ]
    },
    selectedDesignName() {
      if ( !this.block.config.design ) {
        return this.designSelect[0].text
      }
      let design = this.designSelect.find(el => el.value === this.block.config.design)

      if (!design ) {
        return this.designSelect[0].text
      }

      return design.text

    },
    designSelect() {
      return [
        { text : this.$t('Style') + ' 1' , value : 'style_1' },
        { text : this.$t('Style') + ' 2' , value : 'style_2' }
      ]
    }
  },
  methods : {
    ...mapActions('avalonPage' , ['GET_DYNAMIC_BLOCK_ITEMS' , 'GET_DYNAMIC_BLOCK_CATEGORIES']),

    addImage() {
      this.block.config.elements.push( { img : null })
      this.updateSettings()
    },
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },

    async initData() {
      this.categoriesSelect = await this.GET_DYNAMIC_BLOCK_CATEGORIES({ source : 'courses' , lang : this.selectedLang })
      let data = {
        lang : this.selectedLang,
        source : 'courses',
        items : 1,
        offset : 0,
      }
      let result = await this.GET_DYNAMIC_BLOCK_ITEMS(data)
      if ( result && result.items && result.items.length > 0) {
        this.hasCourses = true
      }
      this.loading = false

    }
  },
  mounted() {
      this.initData()
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>