<template>

  <div v-if="design">
    <h1>Navigation design is herere </h1>
  </div>

  <div v-else-if="!isStyle">

    <avalon-new-block-dialog
        v-if="displayNewBlockDialog"
        @save="addBlock($event)"
        v-model="displayNewBlockDialog"
    />

    <return-card
        label="EditImage"
        v-model="selectedItemIndex"
    >

      <template #default>


        <draggable
            v-if="selectedItemIndex === null"
            v-model="block.config.elements"
            :forceFallback="true"
            :empty-insert-threshold="120"
            v-bind="opt"
            @start="drag = true"
            @change="updateSettings"
            handle=".topic-handle"
            @end="drag = false">

          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-hover
                #default="{hover}"
                v-for="(item,index) in block.config.elements" :key="index"
            >
              <v-sheet
                  @click="selectedItemIndex = index"
                  :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                  class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                  height="48"
              >
                <div class="d-flex align-center shorten-text">
                  <v-sheet style="cursor: grab;"  class="topic-handle"  color="transparent">
                    <v-icon  :color="wsACCENT">mdi-drag-vertical</v-icon>
                  </v-sheet>

                  <h5 class="shorten-text" style="font-size: 14px; " >
                    {{ item.title }}
                  </h5>
                </div>
                <div class="d-flex align-center">
                  <v-btn
                      @click.stop="deleteItem(index)"
                      small icon :color="wsACCENT">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-icon :color="wsACCENT">mdi-menu-right</v-icon>
                </div>

              </v-sheet>
            </v-hover>
          </transition-group>
        </draggable>

        <ws-button
            class="mt-4"
            @click="addImage"
            label="Add"
            block
        />

      </template>

      <template #details>

        <v-sheet
            :color="wsLIGHTCARD"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            class="wsRoundedLight pa-5" >
          <div v-if="block.config.elements[selectedItemIndex].block">
            <h4 :style="`color : ${wsACCENT}`">{{ $t('Block') }} : #{{ block.config.elements[selectedItemIndex].block }}</h4>
            <h5 :style="`color : ${wsACCENT}`" class="mt-1">{{ $t('Type')  }} : {{ $t(block.config.elements[selectedItemIndex].block_type) }}</h5>
          </div>
          <div v-else class="d-flex justify-center align-center">
            <ws-button
                @click="openAddBlock"
                label="AddBlock"
            />
          </div>

        </v-sheet>

        <ws-text-field
            class="mt-7"
          @change="$emit('input' , block.config)"
          v-model="block.config.elements[selectedItemIndex].title"
          label="Title"
        />


        <div class="d-flex justify-center mt-5">
          <v-btn @click="deleteBlock" icon :color="wsACCENT" >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>



      </template>

    </return-card>

  </div>




</template>

<script>
import draggable from "vuedraggable";
import returnCard from "@/components/AvalonEditor/UI/returnCard";
import avalonNewBlockDialog from "@/components/AvalonEditor/Dialogs/blocks/avalonNewBlockDialog";
import {mapActions, mapState} from "vuex";
export default {
  name: "blockGallerySettings",
  components : {
    draggable,
    returnCard,
    avalonNewBlockDialog
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      displayNewBlockDialog : false,
      selectedItemIndex : null ,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
    }
  },
  computed : {
    ...mapState('avalon',[ 'selectedLang' ]),
  },
  methods : {
    ...mapActions('avalonPage',[
      'ADD_BLOCK'
    ]),

    async addBlock($event) {


      let data = $event
      data.page_id = this.block.page
      data.parent = this.block.id
      data.lang = this.selectedLang
      this.notify(data)
      let result = await this.ADD_BLOCK(data)
      if ( !result ) {
        return
      }
      this.block.config.elements[this.selectedItemIndex].block = result.id
      this.block.config.elements[this.selectedItemIndex].block_type = result.type
      this.updateSettings()
      this.displayNewBlockDialog = false

    },
    openAddBlock() {
      this.displayNewBlockDialog = true
    },
    deleteBlock() {
      this.notify('DeleteBlock')
    },
    addImage() {
      this.block.config.elements.push( { img : null })
    },
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.block.config)
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.block.config)
    },

  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>