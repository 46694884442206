<template>

  <!-- Format Settings-->
  <div v-if="format">
    <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.blocks_in_row') }}</h4>
    <ws-button-group
        v-model="block.config.columns_large"
        @input="$emit('input' , COPY(block.config))"
        :items="itemsPerPageSelect"
        class="mb-2 mt-2"
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_horizontal"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter_vertical"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_vertical"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.image_height"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="ImageHeight"
        min="40"
        max="500"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        @input="$emit('editing' , true)"
        v-model="block.config.block_radius"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Rounded"
        min="0"
        max="32"
        class="mt-4"
        light
    />


  </div>

  <!-- Content Settings-->
  <div v-else-if="!isStyle">

    <block-settings-navigation
        v-model="selectedContentCategory"
        :items="sliderContentSelect"
        :return-action="selectedItemIndex !== null ? () => { selectedItemIndex = null ; selectedContentCategory='items'  } : null"
        portal
    >
      <template #item.heading>
        <block-general-settings
            @input="updateSettings"
            v-model="block.config"
            :block="block"
            content
        />
      </template>
      <template #item.items>
        <draggable
            v-model="block.config.elements"
            :forceFallback="true"
            :empty-insert-threshold="120"
            v-bind="opt"
            @start="drag = true"
            @change="updateSettings"
            handle=".topic-handle"
            @end="drag = false">

          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-hover
                #default="{hover}"
                v-for="(item,index) in block.config.elements" :key="index"
            >
              <v-sheet
                  @click="selectedItemIndex = index ; selectedContentCategory='edit_item'"
                  :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                  class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                  height="48"
              >
                <div class="d-flex align-center shorten-text">
                  <v-sheet min-width="24" style="cursor: grab;"  class="topic-handle"  color="transparent">
                    <v-icon v-if="hover"  :color="wsACCENT">mdi-drag-vertical</v-icon>
                  </v-sheet>

                  <h5 class="shorten-text" style="font-size: 12px; " >
                    {{ item.title || item.text || item.description || $t('AddItemTitle') }}
                  </h5>
                </div>
                <div v-if="hover" class="d-flex align-center">
                  <v-btn
                      @click.stop="duplicateItem(index)"
                      small icon :color="wsDARKLIGHT">
                    <v-icon size="18">mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                      @click.stop="deleteItem(index)"
                      small icon :color="wsDARKLIGHT">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-icon :color="wsACCENT">mdi-menu-right</v-icon>
                </div>

              </v-sheet>
            </v-hover>
          </transition-group>
        </draggable>
        <portal to="block_settings_footer" >
        <ws-button
            class="mb-2"
            @click="addImage"
            label="Add"
            block
        />
        </portal>
      </template>
      <template #item.edit_item>
        <ws-text-field
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].title"
            :label="$t('Title')"
            :placeholder="$t('EnterTitle')"
            avalon-style
            clearable
        />
        <ws-text-field
            class="mt-4"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].text"
            :label="$t('Subtitle')"
            :placeholder="$t('EnterSubtitle')"
            avalon-style
            clearable
        />
        <ws-text-field
            class="mt-4"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].description"
            :label="$t('Description')"
            :placeholder="$t('enter_description')"
            avalon-style
            rows="1"
            clearable
            area
        />

        <ws-accordion
            class="mt-6"
            :items="imageAccordeonItems">
          <template #item.button>

            <div >
              <ws-text-field
                  class="pa-4"
                  @change="updateSettings"
                  v-model="block.config.elements[selectedItemIndex].button_text"
                  :placeholder="$t('EnterCardButtonText')"
                  :label="$t('Button')"
                  clearable
                  avalon-style

              />
              <div v-show="block.config.elements[selectedItemIndex].button_text">
                <div class="px-4">
                  <ws-select
                      v-if="block.config.elements[selectedItemIndex].button_text"
                      class="mt-4"
                      @change="updateSettings"
                      v-model="block.config.elements[selectedItemIndex].action"
                      :items="buttonActionsSelect"
                      :label="`${$t('ActionOnClick')}`"
                      :placeholder="$t('ChooseButtonAction')"
                      avalon-style

                  />
                  <ws-text-field
                      v-if="block.config.elements[selectedItemIndex].action === 'link'"
                      @change="updateSettings"
                      v-model="block.config.elements[selectedItemIndex].link"
                      :label="$t('Link')"
                      :placeholder="$t('EnterUrl')"
                      avalon-style
                      clearable
                      class="mt-4"
                  />
                  <ws-avalon-text-input
                      class="mt-4"
                      v-show="['dialog' , 'sidebar'].includes(block.config.elements[selectedItemIndex].action)"
                      @input="updateSettings"
                      :label="$t('SideBarText')"
                      v-model="block.config.elements[selectedItemIndex].additional_text" />
                </div>

                <v-sheet
                    class="mt-8"
                    :style="`border-top : 1px solid ${wsBACKGROUND};border-bottom : 1px solid ${wsBACKGROUND}; `">
                  <!-- Primary Style-->
                  <div @click="changeButtonStyle('primary')" class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        @prevent="changeButtonStyle('primary')"
                        prevent-select radio :selected-color="wsACCENT" :value="block.config.elements[selectedItemIndex].style_button === 'primary'"  :small="false" :color="wsACCENT" class="mr-1 ml-2" />
                    <v-sheet
                        :color="wsACCENT"
                        class="d-flex align-center justify-center mr-3"
                        min-width="84" min-height="42"
                        dark
                    >
                      <h3 :style="`color : white`" >
                        Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonPrimary') }}</h5>
                  </div>
                  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                  <!-- Secondary Style-->
                  <div
                      @click="changeButtonStyle('secondary')"
                      class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        :value="block.config.elements[selectedItemIndex].style_button === 'secondary' "
                        :selected-color="wsACCENT"
                        :color="wsACCENT"
                        @prevent="changeButtonStyle('secondary')"
                        :small="false"
                        class="mr-1 ml-2"
                        prevent-select radio
                    />
                    <v-sheet
                        :style="`border : 2px solid ${wsACCENT}`"
                        class="d-flex align-center justify-center mr-3"
                        min-width="84" min-height="42"
                    >
                      <h3 :style="`color : ${wsACCENT}`">
                        Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonSecondary') }} </h5>
                  </div>
                  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                  <!-- Text Style-->
                  <div
                      @click="changeButtonStyle('text')"
                      class="pointer d-flex align-center py-4" >

                    <ws-check-box
                        @prevent="changeButtonStyle('text')"
                        :value="block.config.elements[selectedItemIndex].style_button === 'text' "
                        :selected-color="wsACCENT"
                        :color="wsACCENT"
                        :small="false"
                        prevent-select radio
                        class="mr-1 ml-2"
                    />
                    <v-sheet
                        color="transparent"
                        class="d-flex align-center mr-3"
                        min-width="84" min-height="42"
                    >
                      <h3 :style="`color : ${wsACCENT}`" class="text-no-wrap">Ab
                      </h3>
                    </v-sheet>
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonText') }} </h5>
                  </div>
                </v-sheet>

                <ws-select
                    v-model="block.config.elements[selectedItemIndex].button_icon"
                    :items="buttonsIconsSelect"
                    :label="$t('Icon')"
                    :placeholder="$t('ChooseIcon')"
                    append-inner
                    clearable
                    avalon-style
                    class="px-4 pt-2 mt-4 "
                />

                <a-parameter-slider
                    @change="updateSettings"
                    v-model="block.config.elements[selectedItemIndex].button_radius"
                    default="65"
                    :label="$t('Rounded')"
                    :track-color="wsBACKGROUND"
                    :font-color="wsDARKER"
                    :value-color="wsACCENT"
                    class="px-4 pt-2 pb-6"
                    min="0"
                    max="50"
                />

              </div>
            </div>

          </template>
          <template #item.image>
            <image-param-picker
                @input="updateSettings"
                settings
                :vertical="block.config.elements[selectedItemIndex].img_vertical"
                :horizontal="block.config.elements[selectedItemIndex].img_horizontal"
                :contain="block.config.elements[selectedItemIndex].img_contain"
                @vertical="block.config.elements[selectedItemIndex].img_vertical = $event; updateSettings()"
                @horizontal="block.config.elements[selectedItemIndex].img_horizontal = $event; updateSettings()"
                @contain="block.config.elements[selectedItemIndex].img_contain = $event; updateSettings()"
                class="pa-4"
                v-model="block.config.elements[selectedItemIndex].img"
            />
          </template>
        </ws-accordion>
      </template>

    </block-settings-navigation>

  </div>

</template>

<script>

export default {
  name: "blockTilesSettings",
  components : {
    blockGeneralSettings : () => import("@/components/AvalonEditor/Dialogs/blocks/blockGeneralSettings"),
    imageParamPicker : () => import("@/components/AvalonEditor/UI/imageParamPicker"),
    blockSettingsNavigation : () => import("@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation"),
    wsAvalonTextInput : () => import("@/components/AvalonEditor/UI/wsAvalonTextInput/wsAvalonTextInput"),
    draggable : () => import("vuedraggable"),
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      selectedItemIndex : null ,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
    }
  },
  computed : {

    buttonsIconsSelect() {
      return [
        { text : this.$t('Arrow') , value : 'mdi-arrow-right'  , right_icon : 'mdi-arrow-right' },
        { text : this.$t('Plus') , value : 'mdi-plus' , right_icon : 'mdi-plus' },
      ]
    },
    imageAccordeonItems() {
      return [
          { name : this.$t('Button') , value : 'button' , expanded : true },
          { name : this.$t('Image') , value : 'image'   }
      ]
    },
    itemsPerPageSelect() {
      return [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 }
      ]
    },
    buttonActionsSelect() {
      return [
        { text : this.$t('OpenDetailsSidebar') , value : "sidebar" },
        { text : this.$t('OpenDetailsDialog') , value : "dialog" },
        { text : this.$t('OpenLink') , value : "link" },
        // { text : this.$t('RegisterOnCourse') , value : "course" },
      ]
    },
    sliderContentSelect() {
      return [
        { text: this.$t('Title'), value: 'heading' },
        { text: this.$t('Content'), value: 'items' },
        { text: this.$t('EditItem'), value: 'edit_item', hidden: true}
      ]
    },

    selectedDesignName() {
      if ( !this.block.config.design ) {
        return this.designSelect[0].text
      }
      let design = this.designSelect.find(el => el.value === this.block.config.design)

      if (!design ) {
        return this.designSelect[0].text
      }

      return design.text

    },
    designSelect() {
      return [
        { text : this.$t('Style') + ' 1' , value : 'style_1' },
        { text : this.$t('Style') + ' 2' , value : 'style_2' }
      ]
    }
  },
  methods : {
    addImage() {
      this.block.config.elements.push( { img : null })
      this.updateSettings()
    },
    changeButtonStyle(value) {
      this.block.config.elements[this.selectedItemIndex].style_button = value
      this.updateSettings()
    },

    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    duplicateItem(index) {
      let item =  this.COPY(this.block.config.elements[index])
      this.block.config.elements.splice(index , 0 , item)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    }

  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>