<template>
  <v-sheet
      v-window-resize="() =>getBlockWidth()"
      color="transparent"
      :width="blockWidthGeneral"
      class="pl-4"
      @mouseleave="mouseOverSlider = false"
      @mouseover="mouseOverSlider = true"

  >

    <!-- SLIDER CONTENT-->
    <div style="position: relative">

      <!-- Navigation -->
      <v-btn
          v-if="displayPrevious || SM || MOBILE_VIEW"
          @click="previousSlide "
          style="position: absolute; z-index: 9; top : 50%; left : 0;  "
          :style="`background-color : ${BLOCK_COLOR_PARAM(block ,'ux_arrow_bg', block.config.style || 'light_1') + (!displayPrevious ? '66' : '')};
                   margin-top: -${SM || MOBILE_VIEW ? 18 : 22}px
          `"
          :color="BLOCK_COLOR_PARAM(block , 'ux_arrow',block.config.style || 'light_1' )"
          icon
          :large="!(SM || MOBILE_VIEW)"
          :class="SM || MOBILE_VIEW ? 'ml-2' : 'ml-8'"
      >
        <v-icon >mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
          v-if="displayNext || SM || MOBILE_VIEW"
          @click="nextSlide"
          style="position: absolute; z-index: 9; right : 0; top : 50%"
          :style="`background-color : ${BLOCK_COLOR_PARAM(block , 'ux_arrow_bg', block.config.style || 'light_1') + (!displayNext ? '66' : '')};
                    margin-top: -${SM || MOBILE_VIEW ? 18 : 22}px
          `"
          :color="BLOCK_COLOR_PARAM(block , 'ux_arrow',block.config.style || 'light_1')"
          icon
          :large="!(SM || MOBILE_VIEW)"
          :class="SM || MOBILE_VIEW ? 'mr-6' : 'mr-8'"
      >
        <v-icon >mdi-arrow-right</v-icon>
      </v-btn>
      <!-- SLIDER -->
      <v-sheet
          color="transparent"
          :width="blockWidthCalculated"
          :style="!navigationWidth ? `margin-left : ${-scrollDistance}px;` : null"

          style="overflow-x: hidden !important; position: relative;
              transition: all 0.2s ease "
      >
        <v-sheet
            :style="navigationWidth ? `margin-left : ${-scrollDistance}px;` : null"
            style="top : 0; bottom: 0; left: 0;right: 0;
            transition: margin-left 0.2s ease"
            class="d-flex"
            color="transparent"
        >
          <div
              v-for="(item , index) in block.config.elements"
              :key="index"
              :style="`margin-right : ${marginRight}px`"
          >
            <!--          :style="`margin-left : -${scrollDistance}px`"-->
            <div class="d-flex align-center fill-height " >
              <v-sheet  :width="cardWidth" :max-width="cardWidth" color="transparent" class="fill-height">
                <slot name="item" :item="item">
                  <component
                      :is="designComponent"
                      :config="block.config"
                      :card-width="cardWidth"
                      :item="item"
                      :block="block"
                      @action="itemAction(item)"
                  />
                </slot>
              </v-sheet>

            </div>

          </div>
        </v-sheet>
      </v-sheet>

    </div>


    <!-- NON MOVING CONTENT -->
    <slot name="non-moving-part" :item="block.config.elements[index] || {}" :index="index"></slot>

    <!-- DOTS-->
    <div v-if="!block.config.hide_dots"
         :class="[
             { 'justify-center' :  block.config.description_align === 'center' },
             { 'justify-end' :  block.config.description_align === 'right' }
             ]"
         class="my-5 d-flex align-center"

    >
      <v-sheet
          v-for="(_,i) in block.config.elements || []" :key="i + 'dot'"
          height="8" :width="index === i ? 51 : 9"
          style="border-radius: 4px; transition: all 0.4s ease"
          :color="BLOCK_COLOR_PARAM(block , 'ux_slider-dot', block.config.style || 'light_1') + (index !== i ? '66' : '')"
          class="mr-1"
      />



    </div>

    <!-- DIALOGS-->
    <!-- Sidebar-->
    <slider-sidebar
        v-if="displaySidebar"
        v-model="displaySidebar"
        :item="selectedItem"
        :config="block.config.style"
        :popup="displayDialog"
    />

  </v-sheet>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "blockGallery",
  components : {
    blockSliderDesign1 : () => import("@/components/AvalonEditor/Editor/blocks/slider/blockSliderDesign1"),
    blockSliderDesign2 : () => import("@/components/AvalonEditor/Editor/blocks/slider/blockSliderDesign2"),
    sliderSidebar      : () => import("@/components/AvalonEditor/Editor/blocks/slider/sliderSidebar")
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    width : {
      default : 300
    },
    navigationWidth : {
      type : Number,
      default : 0
    },
    contentWidth : {
      type : Number,
      default : 840
    },
    designComponentName : {
      type : String
    }
  },
  data() {
    return {
      blockWidth : 1140,
      windowWidth : 1140,
      displaySidebar : false,
      displayDialog : false,
      selectedItem : {},
      index : 0,
      timeOutId : null,
      mouseOverSlider : false,
    }
  },
  computed : {
    ...mapState('avalon' , ['sideMenu']),

    availableWidth() {
      let editorSideMenuWidth = this.$store.state.avalon.sideMenu && this.$store.state.avalon.editorOn ? 280 : 0
      let width = this.windowWidth - editorSideMenuWidth

      return width > this.block.config.width ? this.block.config.width :  width
    },

    designComponent() {
      if ( this.designComponentName ) {
        return this.designComponentName
      }
      switch (this.block.config.design) {
        case 'style_1' : return 'blockSliderDesign1'
        case 'style_2' : return 'blockSliderDesign2'
        default:  return 'blockSliderDesign1'
      }
    },
    cardWidth() {
      if (this.MOBILE_VIEW ) {
        return parseInt(this.MOBILE_WIDTH ) - 32
      }
      if ( this.SM ) {
        return this.windowWidth - 32
      }

      if ( this.block.config.slide_width_percent ) {
        return this.availableWidth * (this.block.config.slide_width_percent/100) - 32
      }

      return this.width
    },
    blockWidthGeneral() {
      if ( this.navigationWidth ) {
        if ( this.windowWidth < this.block.config.width ) {
          return '100%'
        }
        return this.block.config.width - this.navigationWidth
      }
      return '100%'
    },
    blockWidthCalculated() {

      if ( this.MOBILE_VIEW ) {
        return this.MOBILE_WIDTH
      }
      if ( this.navigationWidth ) {
        return this.blockWidth
      }
      return this.blockWidth + this.scrollDistance
    },
    scrollDistance() {
      return (this.cardWidth + this.marginRight) * this.index
    },
    scrollRemain() {

      let length = this.block.config.elements.length
      let totalWidth = (length*this.cardWidth) + this.marginRight*(length-1)

      return totalWidth - this.blockWidth - this.scrollDistance
    },
    marginRight() {
      return 32
    },
    displayPrevious() {
      return this.scrollDistance > 0
    },
    displayNext() {
      return this.scrollRemain > 0
    }
  },
  watch : {
    sideMenu() {
      this.getBlockWidth()
    },
  },
  methods : {
    itemAction(item) {
      if ( this.$store.state.avalon.editorMode && !['sidebar','enroll_on_course','dialog'].includes(item.action) ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
        return
      }

      this.selectedItem = item

      if ( item.action === 'sidebar') {
        this.displayDialog = false
        this.displaySidebar = true
      }

      if ( item.action === 'dialog') {
        this.displayDialog = true
        this.displaySidebar = true
      }

    },
    nextSlide() {
      if ( !this.displayNext ) {
        return
      }
      // if ( this.$store.state.avalon.editorOn && !this.$store.state.avalon.previewMode ) {
      //   return
      // }
      this.index++
    },
    previousSlide() {
      if ( !this.displayPrevious ) {
        return
      }
      // if ( this.$store.state.avalon.editorOn && !this.$store.state.avalon.previewMode ) {
      //   return
      // }
      this.index--
    },
    getBlockWidth() {

      this.GET_WINDOW_WIDTH()

      setTimeout(()=> {
        this.windowWidth = this.WINDOW_WIDTH

        let blockWidth = this.block.config.width || 1140
        let editorSideMenuWidth = this.$store.state.avalon.sideMenu && this.$store.state.avalon.editorOn ? 280 : 0
        let width = this.windowWidth - editorSideMenuWidth

        // Regular block
        if (!this.navigationWidth ) {
          if ( (width ) < blockWidth ) {
            this.blockWidth = width - 16
          } else if ( width >= blockWidth ) {
            let halfSideDistance = (width - blockWidth) / 2
            halfSideDistance = halfSideDistance > 0 ?  halfSideDistance : 0
            this.blockWidth =  blockWidth + halfSideDistance - 16
          }
        } else {
          if ( (width ) < blockWidth ) {
            if ( this.SM ) {
              this.blockWidth = width - 16
            } else {
              this.blockWidth = width - this.navigationWidth  - 16
            }

          } else if ( width >= blockWidth ) {
            this.blockWidth = this.contentWidth - 16
          }
        }

        // this.blockWidth -= 16
      },1)

    },
    autoSlide() {

      if ( this.$store.state.avalon.editorOn && !this.$store.state.avalon.previewMode ) {
        return
      }

      if ( !this.$store.state.avalon.editorOn && !this.block.config.auto_change_slide ) {
        return
      }


      this.timeOutId = setTimeout(()=> {



        if ( !this.block.config.auto_change_slide ) {
          return
        }

        if ( !this.block.config.elements ) {
          return
        }
        if ( !this.mouseOverSlider ) {
          if (this.index < this.block.config.elements.length - 1) {
            this.nextSlide()
          } else {
            this.index = 0
          }
        }

        if ( this.timeOutId ) {
          clearTimeout(this.timeOutId)
        }

        this.autoSlide()
      } ,this.block.config.auto_change_slide * 1000)
    }
  },
  mounted() {
    this.autoSlide()
    setTimeout(()=> {
      this.GET_WINDOW_WIDTH()
      this.getBlockWidth()
    } , 10)
  }
}
</script>

<style scoped>

</style>