<template>
  <div>
    <div v-if="index === null">
      <slot name="default"></slot>
    </div>
    <div v-else class="justify-space-between overflow-hidden d-flex flex-column" >
      <div
          class="d-flex justify-space-between align-center mb-5">
        <h5 @click="index = null"
            :style="`color : ${wsACCENT}`"
            class="pointer text-no-wrap"
        >
          <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
          {{ $t('GoBack') }}
        </h5>
        <h5 v-if="label" class="text-right">{{ $t(label) }}</h5>
      </div>

      <slot name="details"></slot>

    </div>

  </div>

</template>

<script>
export default {
  name: "returnCard",
  props : {
    value : {
      type : Number,
      default : null
    },
    label : {
      type : String
    }
  },
  data() {
    return {
      index : null
    }
  },
  watch : {
    index() {
      if ( this.index !== this.value ) {
        this.$emit('input', this.index)
      }
    },
    value() {
      if ( this.index !== this.value ) {
        this.index = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.index = this.value
    }
  }
}
</script>

<style scoped>

</style>