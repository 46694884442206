import { render, staticRenderFns } from "./blockText.vue?vue&type=template&id=609d858d&scoped=true&"
import script from "./blockText.vue?vue&type=script&lang=js&"
export * from "./blockText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609d858d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VFadeTransition,VSheet})
