<template>
    <v-sheet
        v-click-outside="() => $emit('outside-click')"
        style="border-radius: 8px;"
        width="100%"
        max-height="400"
        class="overflow-y-auto "
    >
      <ws-text-field
          v-model="search"
          :placeholder="$t('SearchFont')"
          class="py-1"
          solo flat clearable
      />
      <v-divider :style="`border-color : ${wsBACKGROUND}`" />

      <v-hover
          v-for="font in fontsFiltered"
          #default="{hover}"
          :key="font"
      >
        <v-sheet
            @click="selectedFont = font"
            :color="hover ? wsLIGHTCARD : null" class="pointer">
          <v-sheet style="width: 100%" height="48" class="d-flex align-center" color="transparent">
            <div class="d-flex align-center pr-2" style="width: 100%">
              <v-icon
                  class="mx-2"
                  :color="wsACCENT"
              >
                {{ selectedFont === font ? 'mdi-checkbox-marked-circle' : ' mdi-checkbox-blank-circle-outline' }}
              </v-icon>
              <font :font="font" >
                <v-progress-linear class="wsRounded"  height="22" :color="wsLIGHTCARD" indeterminate />
              </font>

            </div>
          </v-sheet>
          <v-divider :style="`border-color : ${wsBACKGROUND}`" />

        </v-sheet>

      </v-hover>

      <div >

      </div>

    </v-sheet>
</template>

<script>
import font from "@/components/AvalonEditor/UI/sidebar/fonts/UI/font";

export default {
  name: "fontsMenu",
  props : {
    value : {
      type : String
    }
  },
  components : {
    font
  },
  data() {
    return {
      search : '',
      selectedFont : ''
    }
  },
  computed : {
    fontsFiltered() {
      let items = this.$store.state.avalon.fonts

      if ( this.search && this.search.length > 2 ) {
        items = items.filter(el => el.toLowerCase().includes(this.search.toLowerCase().trim()))
      }

      return items
    }
  },
  watch : {
    selectedFont() {
      if ( this.selectedFont !== this.value ) {
        this.$emit('input' , this.selectedFont)
      }
    },
    value() {
      if ( this.selectedFont !== this.value ) {
        this.selectedFont = this.value
      }
    }
  },
  async mounted() {
    this.selectedFont = this.value || ''
  }
}
</script>

<style scoped>

</style>