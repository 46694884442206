<template>
  <div
      @mouseover="hover = true"
      @mouseleave="hover = false"
      class="mx-3"
      style="position: relative"
  >

    <!-- Zero Block Content  -->
    <div  style="position: relative">
      <!-- Elements Wrapper  -->
      <div ref="wrapper" :style="wrapperStyle" :id="`zero_wrapper_${block.id}`"  style="transform-style: preserve-3d;"   >
        <!-- Elements  -->
        <template v-for="(element,i) in block.elements">
          <!-- Elements Body  -->
          <zero-element
              v-if="contentReady && !isCertificate"
              v-model="block.elements[i]"
              @toolbarAction="elementToolbarAction"
              @display-vdr="vdrActive = $event; blockElementsEditing = $event"
              @text-focus="textFocused = $event;blockElementsEditing = $event"
              :block="block"
              :template="template"
              :display-canvas="displayCanvas"
              :disable-parent-borders="pauseResize"
              :key="'content_' + element.id + block.id "
              :ref="'content_' + element.id"
              @update-block="block = COPY($event)"
              @update-array="handleUpdateArray"
              @check-below="moveBelowData = $event"
              :move-below-data="moveBelowData"
              @block-elements-editing="blockElementsEditing = $event"
              :blockElementsEditing="blockElementsEditing"
              @is-overlapping="handleOverlapArray($event)"
              :overlap-elements-array="overlapElementsArray"
              :arrange-mobile-overlap-trigger="arrangeMobileOverlapTrigger"
              @display-mobile-rearrange="$emit('display-mobile-rearrange',$event)"
              :max-height="maxHeight"
              :max-height-sm="maxHeightSM"
              :cellWidth="CELL_WIDTH"
              :cellHeight="rowHeight"
              :blockReadyTimeout="blockReadyTimeout"

          />
<!--          v-window-resize="() => windowResize(elements[i])"-->

          <!-- Element Layout  -->
          <zero-element-layout
              :ref="'layout_' + block.elements[i].id"
              :element="block.elements[i]"
              @update="block.elements[i] = $event"
              :config="block.config"
              :key="'layout_' + block.elements[i].id + block.id"
              :active="vdrActive"
              :block-id="block.id"
              :cellWidth="cellWidth"
              :cellHeight="cellHeight"
          />

        </template>


      <!-- Layout Cells-->
        <template v-for="row in rows"  >
          <div v-for="column in columns"
               class="wsRoundedLight"
               :style="cellStyle(row,column)"
               style="transition: none"
               :key="`cell_${row}_${column}`"
               ref="cell"
          />
        </template>
      </div>
    </div>

    <ws-dialog v-model="displayConfirmDeleteElement"
               :title="$t('avalon.element.delete')"
               @save="deleteElement($event)"
               display-confirm-delete
               @delete="deleteElement($event)"
    />

  </div>
</template>

<script>

import zeroElementLayout from "@/components/AvalonEditor/Editor/elements/zeroElement/zeroElementLayout";
import zeroElement from "@/components/AvalonEditor/Editor/elements/zeroElement/zeroElement";
import {mapActions, mapState} from "vuex";

export default {
  name: "BlockZero",
  props : {
    value : {
      type : Object,
      default() { return { config : {} , elements : [] } }
    },
    newElementTrigger : {
      type : Number,
      default : 0
    },
    template : {
      type : Boolean,
      default : false
    },
    editingVdr : {
      type : Boolean,
      default : false
    },
    noResize : {
      type : Boolean,
      default : false
    },
    isCertificate : {
      type : Boolean,
      default : false
    },
  },
  components : {
    zeroElementLayout,
    zeroElement,
  },
  data() {
    return {
      blockReadyTimeout : false,
      block : {  config : {} , elements : [] },
      // for move below function
      blockElementsEditing : false,

      moveBelowData : null,
      textFocused : false,
      overlapMatrix : {},
      disableParentBorders : false,
      disableParentTrigger : 0,

      hover : false,
      resizing : false,
      rowsBeforeEdit : 0,
      resizeButtonY : 0,
      resizeTrigger : 0,
      cellWidth : 0,
      cellHeight : 0,

      displayCanvas : false,

      lockAutoResize : false,
      pauseResize : false,
      displayConfirmDeleteElement : false,
      selectedElement : null,

      // rows : 10,
      // columns : 24,

      vdrActive : false,

      displayResize : false,
      contentReady : false,


      width: 10,
      height: 10,



      // Update elements variables
      updateElementsArray : [],
      updateElementsTimeout : null,
      overlapElementsArray : [],
      arrangeMobileOverlapTrigger : 0,

      heightResizeY : 0,
      isHeightResizeDragging : false,
      heightResizeStartY : 0,
      heightResizeInitialY : 0
    }
  },
  computed : {
    ...mapState('avalon',['rowHeightChangeTrigger' , 'triggerMobileTextOverlapCheck' , 'sideMenu' ]),
    displayHeightResizeHandle() {
      return (this.hover || this.resizing) && !this.noResize
    },
    CELL_WIDTH() {
      let ebw = this.EDITOR_BLOCK_WIDTH(this.block.config.width , true , this.block.config.full_width || false)
      return (ebw - ((this.columns - 1 ) * this.gridGapHorizontal))/ this.columns
    },
    rowHeight() {
      let value = 24

      if ( this.EDITOR_FONT_COEFFICIENT(this.block.config.width) !== 1 && !this.MOBILE_VIEW ) {
        return value * this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      }

      return value

    },
    INITIAL_CELL_WIDTH() {
      let ebw = this.block.config.width
      return (ebw - ((this.columns - 1 ) * this.gridGapHorizontal))/ this.columns
    },
    gridGapVertical() {
      let coefficient = this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      if ( this.MOBILE_VIEW  ) {
        return 4
      }
      let value = this.block.config.gridGapVertical || 4

      return value * coefficient
    },
    gridGapHorizontal() {
      let coefficient = this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      if ( this.MOBILE_VIEW  ) {
        return 4
      }
      let value = this.block.config.gridGapHorizontal || 4

      return value * coefficient
    },
    currentMaxHeight() {
      return this.MOBILE_VIEW ? this.maxHeightSM : this.maxHeight
    },
    maxHeight() {

      if ( !Array.isArray(this.block.elements)) {
        return 0
      }
      if ( this.block.elements.length < 1 ) {
        return 0
      }

      let array = this.block.elements.map(el => el.y2)
      return Math.max(...array)
    },
    maxWidth() {

      if ( !Array.isArray(this.block.elements)) {
        return 0
      }
      if ( this.block.elements.length < 1 ) {
        return 0
      }

      let array = this.block.elements.map(el => el.x2)
      return Math.max(...array)
    },
    maxHeightSM() {

      if ( !Array.isArray(this.block.elements)) {
        return 0
      }
      if ( this.block.elements.length < 1 ) {
        return 0
      }

      if ( this.block.elements.length === 0 ) {
        return 0
      }
      let array = this.block.elements.map(el => el.smY2)
      return Math.max(...array)
    },
    rows() {
      return !this.MOBILE_VIEW ? (this.avalonBlockConfig(this.block, 'rows') || 12) : (this.avalonBlockConfig(this.block, 'rows_sm') || 12)
    },
    columns() {
      if ( this.MOBILE_VIEW ) {
        return 6
      }
      return this.block.config.columns || 24
    },
    wrapperStyle() {

      let columnsValue = ''
      for ( let i = 0; i< this.columns ; i++) {
        columnsValue += '1fr '
      }

      let rowsValue = ''
      for ( let j = 0; j< this.rows ; j++) {
        rowsValue += `${this.rowHeight}px `
      }

      return '' +
          `position : relative;` +
          `display: grid;` +
          // `transition: all 0.3s ease;` +
          `grid-gap: ${this.gridGapVertical}px ${this.gridGapHorizontal}px;` +
          `grid-template-columns : ${columnsValue}  ;` +
          `grid-template-rows : ${rowsValue} ;` +
          `width : 100% ;`

    },
  },
  watch: {

    MOBILE_VIEW() {
      if ( !this.sideMenu ) {
        this.blockReadyTimeout = false
        setTimeout(() => {
          this.blockReadyTimeout = true
        },1000)
      }
    },
    sideMenu(value) {
      if ( !value ) {
        this.blockReadyTimeout = false
        setTimeout(() => {
          this.blockReadyTimeout = true
        },1000)
      }

    },


    value : {
      handler(value) {
        if ( !this.EQ(this.value , this.block)) {
          this.block = this.COPY(value)
        }
      },
      deep : true
    },
    block : {
      handler(value) {
        if ( !this.EQ(this.value , this.block)) {
          this.$emit('input' , this.COPY(value))
        }
      },
      deep : true
    },

    maxWidth() {
      this.$emit('max-width' , this.maxWidth)
    },
    editingVdr(value) {
      this.vdrActive = value
    },
    maxHeight(value) {
      if ( this.noResize ) {
        return
      }

      if ( this.maxHeight >= this.block.config.rows ) {
        this.block.config.rows = this.maxHeight
        this.block = this.COPY(this.block)
      }

      this.$emit('maxHeight' , value)
    },
    maxHeightSM(value) {
      if ( this.noResize ) {
        return
      }

      if ( this.maxHeightSM >= this.block.config.rows_sm ) {
        this.block.config.rows_sm = this.maxHeightSM
        this.block = this.COPY(this.block)
      }

      this.$emit('maxHeightSM' , value)

    },
    'overlapElementsArray.length'(value) {
      this.$emit('display-mobile-rearrange' , value > 0)
    },
    displayHeightResizeHandle(value) {
      this.$emit('display-handle-resize', value)
    }

  },
  methods : {
    ...mapActions('avalonPage',[
        'EDIT_ELEMENT',
        'DELETE_ELEMENT',
        'DUPLICATE_ELEMENT',
        'EDIT_ELEMENTS_ARRAY',
        'EDIT_BLOCK_DRAFT',
    ]),

    handleKeyDown(event) {
      if ( this.textFocused ) {
        return
      }

      if  (event.key && ( event.key.toLowerCase() === "delete" || (event.key.toLowerCase() === "backspace" && event.metaKey))) {
        let index = this.block.elements.findIndex(el => el.id === this.$store.state.avalon.selectedElement)
        if ( index === -1 ) {
          return
        }
        this.selectedElement = this.block.elements[index].id
        this.deleteElement();
      }
    },

    // Block Resize Handle Functions
    heightResizeDragStart(event) {
      window.addEventListener('mouseup', this.heightResizeDragStop);
      window.addEventListener('mousemove', this.heightResizeHandleDrag);
      this.isHeightResizeDragging = true
      this.vdrActive = true
      this.heightResizeStartY = event.clientY
      this.heightResizeInitialY = this.heightResizeY
    },
    heightResizeDragStop() {
      window.removeEventListener('mouseup', this.heightResizeDragStop);
      window.removeEventListener('mousemove', this.heightResizeHandleDrag);
      this.isHeightResizeDragging = false;
      this.vdrActive = true
    },
    heightResizeHandleDrag(event) {
      let deltaY = event.clientY - this.heightResizeStartY
      let y = this.heightResizeInitialY + deltaY
      this.heightResizeY = y
    },

    // Overlap, Auto Resize Functions
    arrangeMobileOverlap() {
      this.arrangeMobileOverlapTrigger++
      setTimeout(()=>{
        this.block.config.rows_sm = this.maxHeightSM
        this.updateBlock()
      },500)
    },
    handleUpdateArray(element) {
      let index = this.updateElementsArray.findIndex(el => el.id === element.id)
      if ( index === -1 ) {
        this.updateElementsArray.push(element)
      } else {
        this.updateElementsArray[index] = this.COPY(element)
        this.updateElementsArray = this.COPY(this.updateElementsArray)
      }
      if ( this.updateElementsTimeout ) {
        clearTimeout(this.updateElementsTimeout)
      }

      this.updateElementsTimeout = setTimeout(()=> {
        this.EDIT_ELEMENTS_ARRAY({elements : this.updateElementsArray})
        this.updateElementsArray=[]
      },1000)
    },
    handleOverlapArray({ value , id }) {
      if ( value && !this.overlapElementsArray.includes(id) ) {
        this.overlapElementsArray.push(id)
      }
      if ( !value && this.overlapElementsArray.includes(id) ) {
        this.overlapElementsArray = this.overlapElementsArray.filter(el => el !== id )
      }
    },


    // Elements functions
    elementToolbarAction($event) {
      this.selectedElement = $event.id
      if ( $event.action === 'delete' ) {
        this.deleteElement()
        return
      }
      if ( $event.action === 'duplicate' ) {
        this.duplicateElement($event.element)
      }
    },
    async deleteElement() {

      let result = await this.DELETE_ELEMENT(this.selectedElement)

      if ( !result ) {
        this.displayConfirmDeleteElement = false
        return this.notify(this.$t("Error"))
      }

      let index = this.block.elements.findIndex(el => el.id === this.selectedElement)
      if ( index !== -1 ) {
        this.block.elements.splice(index, 1)
      }
      this.displayConfirmDeleteElement = false
      this.$store.state.avalon.selectedElement = null
    },
    async duplicateElement(element) {
      this.$emit('duplicate' , element )
    },

    // Technical
    async updateBlock() {
      let result = await this.EDIT_BLOCK_DRAFT(this.block)
      if ( !result ) {
        return
      }
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block))

    },
    cellStyle(row,column) {
      let style = `grid-area : ${row} / ${column} / ${row} / ${column} ; `;
      if ( this.vdrActive ) {
        style += `border : 1px solid #00000055;`
      }
      return style
    },
    initBlock() {
      if ( this.value ) {
        this.block = this.COPY(this.value)
      }

      this.$emit('maxHeight' , this.maxHeight)
      this.$emit('maxHeightSM' , this.maxHeightSM)
      this.$emit('max-width' , this.maxWidth)
      this.$emit('cell_dimensions', {
        width : this.CELL_WIDTH,
        height : this.rowHeight,
        gapHorizontal : this.gridGapHorizontal,
        gapVertical : this.gridGapVertical,
      })
      this.contentReady = true
      this.blockReadyTimeout = false
      setTimeout(() => {
        this.blockReadyTimeout = true
      },1000)

    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown )
    this.initBlock()
    this.$emit('rearrange-function' , this.arrangeMobileOverlap)
    this.$emit('handle-resize-function' , this.heightResizeDragStart)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown )
  }
}
</script>

<style scoped>
</style>