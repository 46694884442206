<template>
  <div>
    <v-menu offset-y  max-width="350"  >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
            v-bind="attrs" v-on="on"
            :style="`border: 1px solid ${wsDARKLIGHT} !important`"
            class="mt-2 d-flex justify-space-between align-center pointer mb-6"
            style="border-radius: 8px; padding : 6px 15px"
            :color="!isCustom ? CONFIG_PARAM_COLOR( 'styles', style , 'bg' ) : '#ffffff'"
            height="48"
        >
          <h3 :style="`color : ${!isCustom ? CONFIG_PARAM_COLOR( 'styles', style , 'h1' ) : wsACCENT}`">Ab</h3>

          <h5 :style="`color : ${!isCustom ? CONFIG_PARAM_COLOR( 'styles', style , 'p' ) : wsACCENT}`" > {{ $t('avalon.color_styles.' + (!isCustom ? style : 'custom') )   }} </h5>

          <v-icon :color="!isCustom ? CONFIG_PARAM_COLOR( 'styles', style , 'p' ) : wsACCENT" >mdi-menu-down</v-icon>

        </v-sheet>
      </template>

      <v-sheet class="wsRoundedLight">
        <v-hover
            v-for="(styleName,index) in  AVALON_STYLES_LIST" :key="styleName"
            #default="{}">
          <v-sheet
              @click="style = styleName; $emit('input' , style )"
              class="d-flex justify-space-between align-center pointer"
              style=" padding : 6px 15px"
              :color="CONFIG_PARAM_COLOR( 'styles', styleName , 'bg' )"
              :style="index !== 5 ? `border-bottom : 1px solid ${wsDARKLIGHT}` : null"
              height="48"
          >
            <h3 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', styleName , 'h1' )}`">Ab</h3>
            <h5 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', styleName , 'p' )}`" > {{ $t('avalon.color_styles.' + styleName )   }} </h5>
            <div style="width: 26px" />
          </v-sheet>
        </v-hover>

      </v-sheet>

    </v-menu>


  </div>
</template>

<script>
export default {
  name: "colorStylePicker",
  props : {
    value : {
      type : String,
      default : 'light_1'
    },
    noCustom : {
      type : Boolean,
      default : false
    },
    isCustom : {
      type : Boolean,
      default : false
    },

  },
  data() {
    return {
      style : 'light_1'
    }
  },
  watch : {
    value() {
      if ( this.value !== this.style ) {
        this.style = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.style = this.value
    }
  }
}
</script>

<style scoped>

</style>