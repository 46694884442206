<template>
  <!-- Color Styles-->
  <div v-if="!fonts">
    <!-- Default Styles params-->
    <div v-if="!hasColorsVariables" class="pa-5">
      <h5 :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
        {{ $t('BackgroundColorDescription') }}
      </h5>

      <v-hover
          v-for="(value,style) in  CONFIG_PARAM( 'styles' )" :key="style"
          #default="{hover}">
        <v-sheet
            @click="editBlockStyle(style)"
            :style="`box-shadow : 0 0 0 ${block.config.style === style || hover ? 2 : 1 }px ${wsDARKLIGHT} !important`"
            class="mt-2 d-flex justify-space-between align-center pointer"
            style="border-radius: 8px; padding : 6px 15px"
            :color="CONFIG_PARAM_COLOR( 'styles', style , 'bg' )"
        >
          <h3 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'h1' )}`">Ab</h3>
          <h5 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'p' )}`" > {{ $t('avalon.color_styles.' + style )   }} </h5>

          <div style="width: 26px" />
        </v-sheet>
      </v-hover>

    </div>
    <!-- Custom blocks params-->
    <div v-else class="pa-4">

      <color-style-picker
          @input="changeColorStyle"
          v-model="block.config.style"
          :is-custom="Object.keys(block.config.color_styles).length !== 0 "
      />

      <h5 v-if="Object.keys(block.config.color_styles).length === 0" :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
        {{ $t('BlockColorStyleDescription') }}
        <span @click="openColorSettings" class="font-weight-bold pointer linkHover" :style="`color : ${wsATTENTION}`"> {{ $t('ColorSettings') }}</span>
      </h5>

      <div v-for="(params , group , index) in colorsVariables" :key="group">
        <h4 :style="`color : ${wsDARKER}`" :class="[{'mt-3' : index !== 0}]">{{ $t(GET_COLORS_PARAMS_LOCALES(group)) }}</h4>
        <color-param-picker
            :label="GET_COLORS_PARAMS_LOCALES(group , param)"
            @input="updateBlockColorParam(param , $event)"
            v-for="(param , i) in params" :key="group + i"
            :value = "block.config.color_styles[param] || CONFIG_PARAM('styles' , block.config.style , param )"
            @expand="expandedElement = $event"
            :expanded-element="expandedElement"
        />
      </div>


    </div>
  </div>
  <!-- Font Styles-->
  <div v-else class="pa-4">

    <template v-if="hasFontsVariables">

      <!-- Information and reset button -->
      <h5 v-if="Object.keys(block.config.fonts_styles).length === 0" :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
        {{ $t('BlockFontsStyleDescription') }}
        <span @click="openFontsSettings" class="font-weight-bold pointer linkHover" :style="`color : ${wsATTENTION}`"> {{ $t('FontsSettings') }}</span>
      </h5>
      <h5 v-else class="font-weight-bold mb-5 pointer linkHover" @click="resetToConfigFonts" :style="`color : ${wsATTENTION}`"> {{ $t('ResetFontsToConfigValues') }}</h5>

      <!-- Fonts Params  -->
      <div v-for="(params , group , index) in fontsVariables" :key="group">

        <h4 :style="`color : ${wsDARKER}`" :class="[{'mt-3' : index !== 0}]">{{ GET_FONT_PARAM_NAME(group) }}</h4>

        <template  v-for="(param , i) in params" >
          <font-style-param-picker
              @input="changeFontParam(group , param , $event )"
              :key="group + i + 'font' "
              :label="GET_FONT_PARAM_NAME( group , param )"
              :value="block.config.fonts_styles[group + '__' + param ] || CONFIG_PARAM('fonts','elements',group, param ) "
          />
        </template>

      </div>

    </template>


  </div>



</template>

<script>
import colorStylePicker from "@/components/AvalonEditor/UI/colorStylePicker";
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
import fontStyleParamPicker from "@/components/AvalonEditor/UI/fontStyleParamPicker";
export default {
  name: "blockStyleSettings",
  components: {
    colorParamPicker,
    colorStylePicker,
    fontStyleParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
      default() { return {} }
    },
    fonts : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      expandedElement : null
    }
  },
  computed : {
    hasColorsVariables() {

      if ( !this.block.type ) {
        return false
      }

      if ( !this.$store.state.avalon.blocksConfig[this.block.type] ) {
        return false
      }

      if ( !this.$store.state.avalon.blocksConfig[this.block.type]['variables'] ) {
        return false
      }

      return !!this.$store.state.avalon.blocksConfig[this.block.type]['variables']['colors']

    },
    hasFontsVariables() {

      if ( !this.block.type ) {
        return false
      }

      if ( !this.$store.state.avalon.blocksConfig[this.block.type] ) {
        return false
      }
      if ( !this.$store.state.avalon.blocksConfig[this.block.type]['variables'] ) {
        return false
      }

      return !!this.$store.state.avalon.blocksConfig[this.block.type]['variables']['fonts']

    },
    colorsVariables() {
      let items = this.$store.state.avalon.blocksConfig[this.block.type]['variables']['colors'] || {}
      let itemsFiltered = {}
      Object.keys(items).forEach(key => {
        let data = key.split('@')
        let design = this.block.config.design || null

        if ( (!design && !data[1]) || (design && !data[1]) ) {
          itemsFiltered[data[0]] = items[key]
        } else if (design && design === data[1]) {
          itemsFiltered[data[0]] = items[key]
        }

      })
      return itemsFiltered
    },
    fontsVariables() {
      let items = this.$store.state.avalon.blocksConfig[this.block.type]['variables']['fonts'] || {}
      let itemsFiltered = {}
      Object.keys(items).forEach(key => {
        let data = key.split('@')
        let design = this.block.config.design || null

        if ( (!design && !data[1]) || (design && !data[1]) ) {
          itemsFiltered[data[0]] = items[key]
        } else if (design && design === data[1]) {
          itemsFiltered[data[0]] = items[key]
        }

      })
      return itemsFiltered
    }
  },
  methods : {

    openFontsSettings() {
      this.$store.state.avalon.menuNavigationElement = 'other'
      this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true
    },
    openColorSettings() {
      this.$store.state.avalon.menuNavigationStyleTheme = this.block.config.style || 'light_1'
      this.$store.state.avalon.menuNavigation = 'styles_theme'
      this.$store.state.avalon.menuNavigationReturn = 'styles'
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true
    },
    resetToConfigFonts() {
      this.block.config.fonts_styles = {}
      this.updateSettings()
    },
    changeFontParam(element, param , value ) {
      this.block.config.fonts_styles[element + '__' + param ] = value
      this.updateSettings()
    },
    updateBlockColorParam(param , value ) {
      this.block.config.color_styles[param] = value
      this.updateSettings()
    },
    changeColorStyle() {
      this.block.config.color_styles = {}
      this.updateSettings()
    },
    editBlockStyle(style) {
      this.block.config.overlay_color = this.CONFIG_PARAM('styles',style , 'bg')
      this.block.config.style = style
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    }
  },
  beforeMount() {
    if ( Array.isArray( this.block.config.color_styles) ) {
      this.block.config.color_styles = {}
      this.updateSettings()
    }
    if ( !this.block.config.color_styles ) {
      this.block.config.color_styles = {}
      this.updateSettings()
    }

    if ( Array.isArray( this.block.config.fonts_styles) ) {
      this.block.config.fonts_styles = {}
      this.updateSettings()
    }
    if ( !this.block.config.fonts_styles ) {
      this.block.config.fonts_styles = {}
      this.updateSettings()
    }


  }
}
</script>

<style scoped>

</style>