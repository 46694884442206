<template>

  <v-sheet
      v-if="!(MOBILE_VIEW || SM)"
      v-window-resize="() => getBlockWidth()"
      color="transparent" style="height: 100%" class="d-flex" >

    <!-- Navigation-->
    <v-sheet
        v-if="!(MOBILE_VIEW || SM)"
        class="fill-height"
        style="position: relative"
        color="transparent"
        :min-width="sideNavigationWidth"
        :max-width="sideNavigationWidth"
        :width="sideNavigationWidth"
    >
      <!-- Bottom Trigger -->
      <v-sheet
          v-intersect="intersector"
          style="position:absolute; bottom: 0; left: 0; right: 0" color="transparent"   :width="sideNavigationWidth" />

      <!-- Navigation content -->
      <v-sheet
          :style="isSticky ? `position : fixed; top : ${HEADER_TOP_PADDING}px` : null"
          ref="navigation"
          color="transparent"
          class="pa-6"
          :width="sideNavigationWidth"
          style="pointer-events: none"
      >
        <!-- Menu Items -->
        <v-fade-transition>
          <div v-if="!bottomItersect">
            <v-hover #default="{hover}"   v-for="(item , i) in  block.config.elements" :key="'items_' + i"
                  class="mb-4"
            >
              <h4
                  style="pointer-events: auto"
                  class="pointer"
                  :style="CONFIG_TEXT_CSS(block.config.style , 'navigation' , 'text' , null , hover  )">
                {{ item.title }}
              </h4>
            </v-hover>
          </div>
        </v-fade-transition>
      </v-sheet>
    </v-sheet>

    <!-- Content -->
    <v-sheet
        height="100%"
        ref="content"
        :width="blockWidth" color="transparent">

      <template   v-for="(childBlock,i) in children" >
        <block-editor
            child
            :parent="false"
            v-if="childBlock.type !== 'navigation' "
            :block="childBlock"
            :elements="getBlockElements(childBlock.id)"
            :key="'child_block_' + i + childBlock.id + updateTrigger"
            :navigation-width="sideNavigationWidth"
            :content-width="contentWidth"
        />
      </template>
    </v-sheet>
  </v-sheet>
  <div v-else style="width: 100%">
    <template   v-for="(childBlock,i) in children" >
      <block-editor
          child
          :parent="false"
          v-if="childBlock.type !== 'navigation' "
          :block="childBlock"
          :elements="getBlockElements(childBlock.id)"
          :key="'child_block_' + i + childBlock.id + updateTrigger"
          :navigation-width="sideNavigationWidth"
          :content-width="contentWidth"
      />
    </template>
  </div>



</template>

<script>
import {mapState} from "vuex";
import BlockEditor from "@/components/AvalonEditor/Editor/blocks/BlockEditor";

export default {
  name: "blockNavigation",
  components: {BlockEditor},
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    children : {
      type : Array,
      default() { return [] }
    },
    childrenElements : {
      type : Array,
      default() { return [] }
    },
  },
  data() {
    return {
      updateTrigger : 0,
      navigationTopPosition : null,
      isSticky : false,
      bottomItersect : false,
      width : 300,
      blockWidth : 1140
    }
  },
  computed : {
    ...mapState('avalon' , ['scrollPosition']),
    sideNavigationWidth() {
      return this.block.config.sidebar_width || 300
    },
    contentWidth() {
      let blockWidth = this.block.config.width || 1140
      return blockWidth - this.sideNavigationWidth
    }
  },
  watch : {
    scrollPosition() {
      this.getNavigationTopPosition()
    },
    MOBILE_VIEW() {
      this.getBlockWidth()
      setTimeout(this.getBlockWidth , 300)
    }
  },
  methods : {
    getBlockWidth() {
      if ( this.MOBILE_VIEW ) {
        this.blockWidth = this.MOBILE_WIDTH
        return
      }
      let width = window.innerWidth
      this.windowWidth = width


      let sideMenuWidth = this.$store.state.avalon.sideMenu ? 280 : 0
      width = width - sideMenuWidth
      this.blockWidth = width < this.block.config.width ? width : this.block.config.width
      if ( !this.SM ) {
        this.blockWidth = this.blockWidth - this.sideNavigationWidth
      }


    },
    getNavigationTopPosition() {
      if ( !this.$refs.content ) {
        return
      }
      let content = this.$refs.content.$el
      let rect = content.getBoundingClientRect()
      if ( rect.y < ( this.HEADER_TOP_PADDING ) ) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    intersector(entries) {

      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.bottomItersect = false
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.bottomItersect = true
        }
      }
    },

    getBlockElements(id) {
      if (this.childrenElements.length === 0 ) {
        return []
      }
      return this.childrenElements.filter(el=>el.block_id === id)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateTrigger++
    })
  },
  beforeMount () {
    this.getBlockWidth()
    if(this.$options.components)
      this.$options.components.BlockEditor = require("@/components/AvalonEditor/Editor/blocks/BlockEditor").default
  }
}
</script>

<style scoped>

</style>