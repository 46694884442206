<template>
  <portal to="dialog_top">
  <ws-dialog
      width="900"
      v-model="display"
      :title="title"
      show-delete
      :cancel-text="$t('Finish')"
      hide-save
      max-height="640px"
      height="90%"
      not-centered
  >
    <div  v-if="displaySubmenuEdit" class="d-flex">
      <h4
          @click="exitSubmenuEdit"
          class="pointer mb-5 pr-1 py-1 wsRoundedLight"
          :style="`color : ${wsACCENT}`"
          style="font-size: 13px; user-select: none;"
          v-ripple
      >
        <v-icon  style="margin-right: 6px" :color="wsACCENT">mdi-arrow-left</v-icon>
        {{ $t('GoBack') }}
      </h4>
    </div>

    <transition-group name="fade" v-if="items.length > 0 " >
        <main-menu-item
            v-for="(item, i ) in items" :key="i + item.value + 'menu_item'"
            @open-submenu-edit="openSubmenuEdit"
            :child="displaySubmenuEdit"
            :item="item"
        />
    </transition-group>
    <div v-else class="fill-height d-flex align-center justify-center">
      <div>
        <div class="d-flex justify-center" :class="[{ 'mt-n16' : displaySubmenuEdit }]">
          <v-icon :color="wsDARKLIGHT"  size="72" >mdi-file</v-icon>
        </div>
        <h3 class="text-center mt-6" :style="`color : ${wsDARKER}`">
          {{  $t( !displaySubmenuEdit ? 'AddMenuItemsPlaceholder'  : 'AddSubMenuItemsPlaceholder') }}
        </h3>
      </div>
    </div>

    <template #delete>

      <ws-button
          @click="addMenuItem"
          :label="!displaySubmenuEdit ? 'AddMenuItem' : 'AddSubMenuItem'"
      />

    </template>





  </ws-dialog>
  </portal>
</template>

<script>
import {mapActions, mapState} from "vuex";
import mainMenuItem from "@/components/AvalonEditor/Dialogs/mainMenu/mainMenuItem";

export default {
  name: "mainMenuEditDialog",
  components : {
    mainMenuItem,
  },
  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      displaySubmenuEdit : false,
      selectedItemIndex : null,
      bufferMainMenu : null,
      newItems : [],
      deletedItems : [],
    }
  },
  computed : {
    ...mapState('avalon' , ['mainMenu' , 'selectedLang']),

    title() {
      if ( this.displaySubmenuEdit ) {

        return this.$t('EditItemSubMenu') + ' / ' + this.mainMenu[this.selectedItemIndex].text
      }
      return this.$t('EditMainMenu')
    },
    items() {
      return this.displaySubmenuEdit && this.selectedItemIndex !== null ? this.mainMenu[this.selectedItemIndex].children : this.mainMenu
    },

  },
  watch : {
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if (this.display !== this.value) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('avalon',[
      'ADD_MENU_ITEM',
      'EDIT_MENU_ITEM'
    ]),
    exitSubmenuEdit() {
      this.displaySubmenuEdit = false
      this.selectedItemIndex = null
    },
    openSubmenuEdit(itemId ) {
      this.selectedItemIndex = this.mainMenu.findIndex(el => el.value === itemId)
      this.displaySubmenuEdit = true
    },
    async editMenuItem(data) {
      data.lang = this.selectedLang
      let result = await this.EDIT_MENU_ITEM(data)
      if ( !result ) {
        return
      }
      this.$store.state.avalon.mainMenu[this.selectedItemIndex].type = 'submenu'
      this.$store.state.avalon.mainMenu = this.COPY(this.mainMenu)

    },
    async addMenuItem() {
      let childId = null

      if ( this.selectedItemIndex !== null ) {
        if ( this.mainMenu[this.selectedItemIndex].type !== 'submenu' ) {
          let data = this.mainMenu[this.selectedItemIndex]
          data.type = 'submenu'
          await this.editMenuItem(data)
        }
        childId = this.mainMenu[this.selectedItemIndex].value
      }
      this.ADD_MENU_ITEM(childId)
    }
  },
  beforeMount() {
    this.bufferMainMenu = this.COPY(this.mainMenu)
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>

</style>