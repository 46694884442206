<template>

  <!-- lineheight-->
  <div style="position: relative">

    <v-hover #default="{hover}">
      <v-sheet
          color="transparent"
          style="transition: background-color 0.2s ease"
          class="pt-3"
      >
        <div class="d-flex justify-space-between align-center">
          <h5 :style="`color : ${wsDARKER}`" class="font-weight-regular" >
            {{ $t('Font') }}
          </h5>

          <div
              @click="displayFonts = !displayFonts"
              class="pointer d-flex justify-end align-center " style="max-width: 160px;">
            <h5
                style="width: 100%"

                :style="`color : ${wsACCENT}`" class=" cut-text text-right"
            >
              {{  selectedFont || $t('Choose')  }}
            </h5>
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </div>


        </div>
        <v-divider class="mt-3" :style="`border-color : ${hover ? wsACCENT : wsDARKLIGHT}`" />
      </v-sheet>
    </v-hover>







    <v-expand-transition>
      <fonts-menu
          @outside-click="displayFonts = false"
          style="position: absolute; z-index: 999;"
          v-if="displayFonts"
          v-model="selectedFont"
      />
    </v-expand-transition>



  </div>

</template>

<script>
import fontsMenu from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontsMenu";
import fonts_list from "@/assets/json/fonts_list.json";

export default {
  name: "fontFamilySelect",
  props : {
    value : {
      type : String
    }
  },
  components : {
    fontsMenu
  },
  data() {
    return {
      search : '',
      selectedFont : '',
      displayFonts : false,
    }
  },
  computed : {
    fontsFiltered() {
      let items = this.$store.state.avalon.fonts

      if ( this.search && this.search.length > 2 ) {
        items = items.filter(el => el.toLowerCase().includes(this.search.toLowerCase().trim()))
      }

      return items
    }
  },
  watch : {
    selectedFont() {
      if ( this.selectedFont !== this.value ) {
        this.$emit('input' , this.selectedFont)
      }
    },
    value() {
      if ( this.selectedFont !== this.value ) {
        this.selectedFont = this.value
      }
    }
  },
  async mounted() {
    this.selectedFont = this.value || ''
  },
  beforeMount() {
    this.$store.state.avalon.fonts = fonts_list
  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>