<template>
    <v-sheet
        :min-height="!block.is_vertical ? 564 : 1128"
        :height="!block.is_vertical ? 564 : 1128"
        width="800"
        color="transparent"
        class="wsRoundedHalf">
    <div style="position:relative;">

      <!-- :: BLOCK CONTENT -->
      <div :style="layoutStyle( )"
           class="d-flex justify-center"
           style="position : relative">

        <v-sheet
            :color="GET_AVALON_COLOR(avalonBlockConfig(block, 'overlay_color' ))"
            :style="`opacity : ${avalonBlockConfig(block, 'overlay_opacity' )/100}`"
            class="fill-height"
            width="100%"
            style="position:absolute;">
        </v-sheet>

        <v-sheet
            :dark="avalonBlockConfig(block, 'dark_mode')"
            :width="blockWidth"
            :style="style"
            color="transparent"
        >
          <certificate-zero-view
              v-if="!block.type"
              :block="block"
              :elements="blockElements"
              :certificate-record="certificateRecord"
          />

        </v-sheet>
      </div>

    </div>
  </v-sheet>
</template>

<script>
import certificateZeroView from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateZeroView";
import { mapState} from "vuex";


export default {
  name: "blockEditor",
  components : {
    certificateZeroView
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    triggerUpdate : {
      type : Number,
      default : 0
    },
    overlap : {
      type : Boolean,
      default : false
    },
    footer : {
      type : Boolean,
      default : false
    },
    certificateRecord : {
      type : Object ,
      default() { return {} }
    }
  },
  data() {
    return {
      edit : false,
      displaySettings : false,
      blockElements : [],
      maxHeight : 0,
      maxHeightSM : 0,

    }
  },
  computed : {
    ...mapState('avalon',[ 'editorMode' , 'header' , 'scrollPosition' , 'selectedLang' ]),
    style() {
      let style = ""

      let overlap = this.overlap && this.header.config.overlap ? this.header.config.height : 0
      if ( this. SM ) {
        overlap = this.overlap && this.header.config.overlap ? this.header.config.height_sm : 0
      }

      // let sticky = this.header.config.sticky && this.scrollPosition > 52
      if ( this.avalonBlockConfig(this.block, 'full_height') ) {
        style += `height : 100vh;`
      }

      if ( !this.SM) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding')   && this.block.config ) {
          if ( this.block.config.padding_top || this.block.config.padding_top === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top !== undefined ? this.block.config.padding_top : this.avalonConfig.block.padding_top) + overlap }px;`
          }
          if ( this.block.config.padding_bottom || this.block.config.padding_bottom === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom !== undefined ? this.block.config.padding_bottom : this.avalonConfig.block.padding_bottom}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top') + overlap }px;`
        }

      }

      if ( this.SM) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding_sm')   && this.block.config ) {
          if ( this.block.config.padding_top_sm || this.block.config.padding_top_sm === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top_sm !== undefined ? this.block.config.padding_top_sm : this.avalonConfig.block.padding_top_sm) + overlap }px;`
          }
          if ( this.block.config.padding_bottom_sm || this.block.config.padding_bottom_sm === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom_sm !== undefined ? this.block.config.padding_bottom_sm : this.avalonConfig.block.padding_bottom_sm}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top_sm') + overlap }px;`
        }

      }

      return style
    },
    blockWidth() {
      if ( this.avalonBlockConfig(this.block, 'full_width')) {
        return '100%'
      }
      if ( this.avalonBlockConfig(this.block, 'width') ) {
        return this.avalonBlockConfig(this.block, 'width').toString()
      }
      return '100%'
    },
    sticky() {
      return this.overlap ? ( this.header.config.sticky && this.scrollPosition > 52 ) : false
    },
  },
  watch : {
    triggerUpdate() {
      this.initBlock()
    },
  },
  methods : {

    // technical
    layoutStyle(  ) {
      let style = 'transition : -webkit-box-shadow 0.3s ease, -moz-box-shadow 0.3s ease, box-shadow 0.3s ease;'

      let sticky = this.header.config.sticky
      let stickyHeight = sticky ? this.header.config.height : 0

      if ( this.SM ) {
        stickyHeight = sticky ? this.header.config.height_sm : 0
      }

      if ( sticky && this.overlap ) {
        style += `margin-top : ${stickyHeight}px` + ';'
      }

      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'color' ) {
        if ( !this.block.config ) {
          style += `background-color : ${ this.avalonConfig.colors.background_main }` + ';'
        } else {
          if ( this.block.config.color && this.avalonConfig.colors[this.block.config.color] ) {
            style += `background-color : ${this.avalonConfig.colors[this.block.config.color]}` + ';'
          } else {
            style += `background-color : ${this.block.config.color}` + ';'
          }
        }
      }
      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'image' ) {
        style += `background: url('${this.avalonBlockConfig(this.block, 'image' )}');
                  background-size : cover;
                  position:relative;`
        if ( !this.SM ) {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_y' )}%;`
        } else {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_sm_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_sm_y' )}%;`
        }
      }


      return style
    },
    initBlock() {

      if ( !this.block.config || Object.keys(this.block.config).length === 0 ) {
        this.block.config = {
          rows  : this.avalonConfig.block.rows,
          rows_sm : this.avalonConfig.block.rows_sm,
          auto_resize_mobile : true,
          background_type : this.avalonConfig.block.background_type,
          full_width : this.avalonConfig.block.full_width,
          vertical_padding : this.avalonConfig.block.vertical_padding
        }
      }

      this.blockElements = JSON.parse(JSON.stringify(this.elements))
    }

  },
  mounted() {
    this.initBlock()
  }

}
</script>

<style scoped>

</style>