<template>
  <dash-page-new
        no-padding
        :side-navigation="templateId ? avalonAdminTemplateNavigationItems : null"
        :navigation-return-route="localeLink('avalon_editor/templates/' + this.templateId)"
        ref="page_preview"
        @scroll="scroll = $event"
        no-header
        :preheader-color="wsDARK"
        :no-border-radius="!sideMenu"
        key="editor"
        :no-content-condition="entity.blocks.length === 0 && !sideMenu && !page.includes('no-lang') && !previewMode && !LOADING && !newsAlias"
        no-content-text="AddFirstBlock"
        no-content-icon="mdi-widgets"
        no-content-button-text="AddBlock"
        :no-content-button-action="() => { openAddBlock(entity.blocks + 1 ) }"
        @get-dom="dashPage = $event"
    >

      <template  #pre_header.wide>
        <v-sheet
            color="#9AB2C4"
            style="position:fixed; top : 0; right: 0; left : 0; padding: 2px 0; z-index : 999999; transition: all 0.3s ease"
            :style="`left : ${sideMenu ? '280px' : '0'};  border-top-left-radius: ${sideMenu ? '8px' : 0}; ${previewMode ? 'top : -45px' : ''}`"
            class="d-flex align-center justify-space-between px-4 "
            :height="EDITOR_PREHEADER_HEIGHT"

        >

          <!-- Editor mode and save button-->
          <div class="d-flex align-center">
            <!-- Toggle editor mode-->
            <v-hover :key="preHeaderUpdateTrigger" #default="{hover}">
              <ws-tooltip
                  :text="$t(sideMenu ? 'CollapseMenu' : 'ExpandMenu')"
                  :hidePointer="!sideMenu"
              >
                <template >
                  <v-btn
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      @click="toggleSideMenu();preHeaderUpdateTrigger++"
                      class="px-2 noCaps"
                      height="30"
                      min-width="30"
                      text

                  >
                    <v-icon v-if="!sideMenu || $store.state.avalon.menuOnlyStylesAndFonts" color="white">mdi-arrow-left</v-icon>
                    <span style="color : white" v-else>{{ $t('Edit') }}</span>
                  </v-btn>
                </template>

              </ws-tooltip>
            </v-hover>

            <!-- Edit Actions-->
            <template v-if="!sideMenu || $store.state.avalon.menuOnlyStylesAndFonts">
              <v-hover #default="{hover}">
                <v-btn
                    :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                    light
                    :color="wsDARKER"
                    text
                    @click="saveChanges"
                    class="noCaps px-2"
                    height="30"
                >
            <span style="color : white" >
                 {{ $t('Publish') }}
            </span>
                </v-btn>
              </v-hover>
              <div style="width: 25px" >
                <ft-select
                    v-if="!newsAlias"
                    :items="publishActionsSelect"
                >
                  <v-hover #default="{hover}">
                    <v-btn
                        :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                        light
                        :color="wsDARKER"
                        text
                        class="noCaps px-0"
                        height="30"
                        min-width="20"
                    >
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </v-btn>
                  </v-hover>
                </ft-select>
              </div>
              <!-- Styles and fonts -->
              <v-hover #default="{hover}">
                <ws-tooltip :text="$t('StylesAndFontsTooltip')">
                  <v-btn
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      @click="openStylesAndFonts"
                      class="px-1"
                      height="30"
                      min-width="30"
                      text
                  >
                    <v-icon color="white">mdi-brush-variant</v-icon>
                  </v-btn>
                </ws-tooltip>

              </v-hover>
              <!-- Undo -->
              <v-hover #default="{hover}">
                <ws-tooltip :text="$t('Undo')">
                  <v-btn
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      @click="undo"
                      class="px-1"
                      height="30"
                      min-width="30"
                      text
                  >
                    <v-icon color="white">mdi-arrow-u-left-top</v-icon>
                  </v-btn>
                </ws-tooltip>

              </v-hover>
              <!-- Redo -->
              <v-hover #default="{hover}">
                <ws-tooltip :text="$t('Redo')">
                  <v-btn
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      @click="redo"
                      class="px-1"
                      height="30"
                      min-width="30"
                      text
                  >
                    <v-icon color="white">mdi-arrow-u-right-top</v-icon>
                  </v-btn>

                </ws-tooltip>
              </v-hover>
            </template>


          </div>

          <!-- Page name and page selector-->
          <div class="d-flex align-center">
            <div class=" pr-1 cut-text" style="max-width: 450px;" >
              <h5 class="text-center cut-text " >{{   shortenText( course.name ||  entity.title)   }} </h5>
              <h6 class="text-center cut-text" style="font-size: 10px; font-weight: 600;" :style="`color : ${wsBACKGROUND}`" > {{ entity.active ? $t('Published') : $t('NotPublished') }}</h6>
            </div>
            <!-- Page selector dropdown-->
            <div v-if="!sideMenu || $store.state.avalon.menuOnlyStylesAndFonts" style="width: 25px" >
              <ft-select
                  value
                  v-if="!newsAlias"
                  :items="pagesSelect"
                  min-width="250px"
                  left
              >
                <template >
                  <v-hover #default="{hover}">
                    <v-btn
                        :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                        light
                        :color="wsDARKER"
                        text
                        class="noCaps px-0"
                        height="30"
                        min-width="20"
                    >
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <template #append.top="{close}">
                  <div class="px-3 pt-3">
                    <ws-button @click="$store.commit('avalonPage/OPEN_NEW_PAGE');close()" label="AddPage" block/>
                  </div>

                </template>

              </ft-select>
            </div>
          </div>


          <!-- Language Screen size select and preview mode-->
          <div class="d-flex align-center">
            <!-- Language -->
            <ft-select
                @input="changeLanguage"
                :value="selectedLang"
                :disabled="languagesSelect.length === 0 "
                :items="languagesSelect"
            >
              <v-hover #default="{hover}">
                <ws-tooltip :text="$t('ChangePageLanguage')">
                  <v-btn
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      class="pl-1 mr-1 pr-0 noCaps text-no-wrap"
                      height="30"
                      min-width="30"
                      text
                  >
                      <span style="color : white">
                            {{ selectedLang.toUpperCase() }}
                      </span>
                    <v-icon color="white">mdi-menu-down</v-icon>

                  </v-btn>
                </ws-tooltip>
              </v-hover>
            </ft-select>
            <!-- Screen size -->
            <v-sheet
                :color="wsBACKGROUND"
                :style="`border: 2px solid ${wsBACKGROUND} !important`"
                class="wsRoundedLight d-flex"
            >
              <ws-tooltip :text="$t('DesktopView')">
                <v-icon
                    v-ripple
                    @click="toggleMobileView(false)"
                    :style="`background: ${!MOBILE_VIEW ? wsATTENTION : wsBACKGROUND}`"
                    style=" border-top-left-radius: 3px; border-bottom-left-radius: 3px"
                    :color="!MOBILE_VIEW ? 'white' : wsACCENT"
                    class="px-2 pointer">
                  mdi-laptop
                </v-icon>
              </ws-tooltip>

              <v-divider vertical :style="`border-color : ${wsBACKGROUND}`"></v-divider>
              <ws-tooltip :text="$t('MobileView')">
                <v-sheet   v-ripple class="fill-height" :color="MOBILE_VIEW ? wsATTENTION : wsBACKGROUND">
                  <v-icon
                      @click="toggleMobileView(true)"

                      style="border-top-right-radius: 3px; border-bottom-right-radius: 3px"
                      size="18"
                      :color="MOBILE_VIEW ? 'white' : wsACCENT"
                      class="px-3 pointer">
                    mdi-cellphone
                  </v-icon>
                </v-sheet>
              </ws-tooltip>
            </v-sheet>
            <!-- Preview mode -->
            <div class="d-flex align-center">
              <v-hover #default="{hover}">
                <ws-tooltip :text="$t('PreviewMode')" hide-pointer>
                  <v-btn
                      @click="togglePreviewMode(true)"
                      :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                      light
                      :color="wsDARKER"
                      class="px-1"
                      height="30"
                      min-width="30"
                      text
                  >
                    <v-icon>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </ws-tooltip>
              </v-hover>

            </div>
          </div>

        </v-sheet>
      </template>
      <template #default>
        <!-- Placeholder for edit mode preheader -->
        <v-sheet
            v-if="!page.includes('no-lang')"
            :color="wsDARKLIGHT"
            :height="previewMode ? 0 : 40"
            style="transition: all 0.3s ease"
        />
        <!-- Page Edit Content-->
        <v-sheet
            v-if="!page.includes('no-lang')"
            class="d-flex justify-center"
            :color="wsLIGHTCARD"
            style="transition: opacity 0.2s ease"
          >


          <!-- Preview Mode Return Arrow-->
          <v-fade-transition>
            <div
                v-if="previewMode "
                style="position: fixed; top : 0; right : 0;z-index: 9999">

                <div style="position : relative">
                  <div    @click="togglePreviewMode(false)" :style="`border-top: 58px solid ${wsBACKGROUND};`" class="preview-triangle pointer" />
                  <v-btn
                      @click="togglePreviewMode(false)"
                      :color="wsDARKER"
                      style="z-index: 2"
                      dark
                      icon
                  >
                    <v-icon>mdi-arrow-bottom-left</v-icon>
                  </v-btn>
                </div>


            </div>
          </v-fade-transition>


            <div :style="`width : ${MOBILE_VIEW ? MOBILE_WIDTH : '100%' };`"  >
              <avalonHeaderEditor
                  v-if="entity.blocks.length > 0 || newsAlias "
                  @scroll-to-block="scrollToBlock"
              />

              <avalonNewsEditor
                  v-if="newsAlias"
                  :alias="newsAlias"
                  :publish-trigger="newsPublishTrigger"
                  :unpublish-trigger="newsUnPublishTrigger"
              />
              <!-- Sticky padding top fix-->
              <v-sheet color="transparent" v-if="header.config.sticky && !header.config.overlap && entity.blocks.length > 0 && !previewMode && !sideMenu"
                       :height="(SM || MOBILE_VIEW) ? header.config.height_sm : header.config.height"
              />
              <!-- Page blocks-->
              <template v-if="!newsAlias">
                <transition-group name="fade" >
                <div :ref="`block_${blockId}`"   v-for="(blockId,i) in entity.blocks" :key="blockId" >
                  <blockEditor
                      :block-id="blockId"
                      @add="handleAddBlock($event , false , i)"
                      @copy="copyBlock($event , i)"
                      @paste="copyBlock($store.state.avalon.bufferCopyBlockId  , i , $event  )"
                      @move="moveBlock( $event , i )"
                      @delete="openDeleteBlock(blockId , false , $event.number)"
                      @scroll-to-end="scrollToBlock(blockId  , true , $event)"
                      @newElement="elements.push($event)"
                      :update-number-trigger="updateNumberTrigger"

                      :key="'block_' + blockId"
                      :overlap="i === 0"
                      :is-first="i === 0"
                      :is-last="i === entity.blocks.length - 1"
                      :deleted-element-id="deletedElementId"
                      :added-element-type="addedElementType"
                      :update-block-id="updateBlockId"
                      :index="i"
                      :id="`#${blockId}`"
                  />
                </div>
                </transition-group>
              </template>

              <!-- Footer -->
              <avalon-footer-editor
                  v-if="(entity.blocks.length > 0 || newsAlias) && !(previewMode || sideMenu)"
                  @add="handleAddBlock($event , true)"
                  @delete="openDeleteBlock($event , true , $event.number)"
                  @newElement="elements.push($event)"
                  :trigger-update="updateFooterTrigger"
              />

            </div>

          </v-sheet>

        <v-sheet class="fill-height d-flex align-center justify-center" v-else>


          <div style="max-width: 600px">

            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsDARKLIGHT" >mdi-translate-variant</v-icon>
            </div>

            <h2 class="text-center"> {{ $t('avalon.page.editor.change_lang.title')}} </h2>
            <h4 style="line-height: 1.9"
                :style="`color : ${wsDARKER}`"
                class="mt-2 font-weight-regular text-center">
              {{ $t('avalon.page.editor.change_lang.text') }}
            </h4>
            <div class="d-flex justify-center mt-8">

              <!-- cancelOpenLang-->
              <ws-button
                  @click="copyLangPage"
                  label="CreateNewPage"
                  outlined
                  class="mr-2"
              />
              <ft-select
                  v-if="existingLanguagePages.length > 0"
                  @input="copyLangPage($event)"
                  :items="existingLanguagePages"
              >
                <ws-button
                    :click-stop="existingLanguagePages.length < 1"
                    label="CopyPage"
                />

              </ft-select>


            </div>
          </div>
        </v-sheet>


      </template>
      <template #dialog>

        <!-- Small Screen Warning -->
        <portal v-if="widthDisableEditor"  to="dialog_top">
          <v-sheet class="d-flex align-center justify-center"  style="position: fixed; top:0;bottom: 0;left: 0;right: 0;z-index: 999999999999999">
            <div>
              <div class="d-flex justify-center">
                <v-icon size="80" :color="wsACCENT">mdi-overscan</v-icon>
              </div>
                <h3 style="width: 80%" class="text-center mx-auto mt-3">{{ $t('ConstructorSmallWidthWarning') }}</h3>
                <h4  style="width: 80%" class="text-center mx-auto font-weight-regular ">{{ $t('ConstructorSmallWidthWarningDescription') }}</h4>

              <div class="d-flex justify-center mt-3">
                <ws-button
                    :to="businessDashLink('')"
                    label="Return"
                />
              </div>
            </div>

          </v-sheet>
        </portal>



        <!-- New Block Dialog-->
        <avalon-new-block-dialog
            v-if="displayNewBlockDialog"
            @save="addBlock($event)"
            v-model="displayNewBlockDialog"
            :number="newBlockNumber"
            :footer="addFooter"
        />
        <!-- Delete Block Dialog-->
        <ws-dialog v-model="displayDeleteBlockDialog"
                   :title="$t('avalon.block.delete')"
                   @save="deleteBlock"
                   display-confirm-delete
                   @delete="deleteBlock"
        />

        <!-- Languages Selector Dialog-->
        <avalon-language-selector
            v-if="displayLangSettingsDialog"
            v-model="displayLangSettingsDialog"
        />

      </template>

    </dash-page-new>
</template>

<script>

import avalonHeaderEditor from "@/components/AvalonEditor/Editor/header/avalonHeaderEditor";
import blockEditor from "@/components/AvalonEditor/Editor/blocks/BlockEditor";
import avalonNewBlockDialog from "@/components/AvalonEditor/Dialogs/blocks/avalonNewBlockDialog";
import avalonFooterEditor from "@/components/AvalonEditor/Editor/footer/avalonFooterEditor";
import avalonNewsEditor from "@/components/AvalonEditor/Editor/avalonNewsEditor";
import {mapActions, mapState} from "vuex";
import avalonLanguageSelector from "@/components/AvalonEditor/settings/UI/avalonLanguageSelector";

export default {
  name: "avalonPreview",
  components : {
    avalonHeaderEditor,
    avalonFooterEditor,
    blockEditor,
    avalonNewBlockDialog,
    avalonNewsEditor,
    avalonLanguageSelector
  },
  props : {
    page : {
      type : String,
      default : ''
    },
    newsAlias : {
      type : String
    },
    id : {
      type : String
    },
    templateId : {
      type : String
    },
    courseAutoTemplate : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      preHeaderUpdateTrigger : 0,
      newsPublishTrigger : 0,
      newsUnPublishTrigger : 0,
      preview : false,
      entity : {
        blocks : []
      },
      blocks : [],
      elements : [],
      displayNewBlockDialog : false,
      displayDeleteBlockDialog : false,
      displayCopyBlockDialog : false,
      newBlockNumber : 0,
      selectedBlock : {},
      scroll : 0,
      addFooter : false,
      updateFooterTrigger : 0,
      deletedElementId : null,
      addedElementType : null,
      updateBlockId : null,
      displayNoLangDialog : false,
      copyPageForNewLang : true,
      notPageInit : false,
      selectedLangBuffer : 'ua',
      pageEditContentTrigger : 0,
      prepareMobileView : false,
      prepareMobileViewOpacity : false,

      addBlockIndex : 0,
      updateNumberTrigger : 0,
      selectedBlockNumber : null,

      dashPage : null,
      widthDisableEditor : false,
      displayLangSettingsDialog : false
    }
  },
  computed : {
    ...mapState('avalon',[ 'sideMenu','editorMode' , 'previewMode' , 'mobileView' , 'mobileWidth' , 'header' , 'footer' , 'selectedLang' ]),
    ...mapState('avalonPage',[ 'course' ]),


    existingLanguagePages() {
      if ( !this.page ) {
        return []
      }
      let items = []

      if ( this.page.includes('$homepage') ) {
        this.$store.state.avalon.pagesSelect.filter(el => el.homepage ).forEach(page => {
          let newPage = this.COPY(page)
          if ( !items.map(el => el.id).includes(page.id) ) {
            newPage.text = page.lang.toUpperCase() + ', ' + page.id
            items.push(newPage)
          }

        })
      } else {
        let alias = this.entity.alias
        if ( this.page.includes('no-lang')) {
          alias = this.page.split('no-lang_')[1]
        }

        this.$store.state.avalon.pagesSelect.filter(el => el.alias === alias ).forEach(page => {
          let newPage = this.COPY(page)
          newPage.text = page.lang.toUpperCase()
          items.push(newPage)
        })
      }


      return items
    },

    pagesSelect() {

      let courses = []
      this.$store.state.avalon.coursesSelect.forEach(course => {
        courses.push(
            {
              text: course.text,
              value : course.value,
              page_id : course.page[this.$store.state.avalon.selectedLang] || null,
              no_edit : !course.page[this.$store.state.avalon.selectedLang],
              is_course : true,
              path: this.businessPublicLink(`page/${course.page[this.$store.state.avalon.selectedLang] || (course.value +'/course')}/editor`),
            },
        )
      })

      let items = [

        {
          text: this.$t('HomePage'),
          value : (this.CURRENT_LANG_HOMEPAGE ?  this.CURRENT_LANG_HOMEPAGE.value : 'homepage').toString(),
          icon: 'mdi-home',
          path: this.businessPublicLink(this.CURRENT_LANG_HOMEPAGE ? `page/${this.CURRENT_LANG_HOMEPAGE.value}/editor` : 'blank/homepage/editor'  ),
          edit : false,
          page_id : this.CURRENT_LANG_HOMEPAGE ? this.CURRENT_LANG_HOMEPAGE.value : null,
          is_published : !!this.CURRENT_LANG_HOMEPAGE
        },
      ]

      this.CURRENT_LANG_PAGES.forEach(page => {
        // if ( !page.folder ) {
          items.push(
              {
                text: page.text,
                value : page.value.toString(),
                icon: 'mdi-file',
                route: this.businessPublicLink(`page/${page.value}/editor`),
                edit : false,
                page_id : page.value,
                alias : page.alias,
                is_published : page.is_published
              }
          )
        // }

      })


      return items

    },


    previewData() {
      return {
        entity : this.entity,
        blocks : this.blocks,
        elements : this.elements
      }
    },
    isHomepage() {
      return this.entity.homepage
    },

    publishActionsSelect() {
      let items =  [
        { text : this.$t('PublishAll')    , action : () => this.saveChangesAll() },
        { text : this.$t('CancelChanges') , action : () => this.cancelPageChanges() }
      ]

      if ( this.entity.active ) {
        items.splice(1,0,{ text : this.$t('UnPublish')     , action : () => this.unPublishPage() } )
      }
      if ( this.newsAlias ) {
        items.splice(0,1)
      }

      return items
    },
    mobileSizesSelect() {
      return [
        { text : 'Iphone SE'           , value : '320px' , icon : 'mdi-apple'   },
        { text : 'Iphone 8'            , value : '375px' , icon : 'mdi-apple'   },
        { text : 'Iphone 8 plus'       , value : '414px' , icon : 'mdi-apple'   },
        { text : 'Samsung Galaxy S8'   , value : '360px' , icon : 'mdi-android' },
        { text : 'Samsung Galaxy Note' , value : '400px' , icon : 'mdi-android' },
      ]
    },
    languagesSelect() {
      let items = []

      this.AVALON_LANGS.forEach(lang=>{
        items.push({text: lang.toUpperCase(), value: lang})
      })

      items.push({ text : this.$t('avalon.language.lang_settings') , value : 'settings' })

      return items
    }
  },
  watch : {

    // WINDOW_SIZE_WATCHERS
    AVALON_EDITOR_WINDOW_WIDTH(value) {
     this.handleWorkAreaWidth(value)
    },

    page() {
      this.entity = {
        blocks : []
      }
      this.$store.state.avalon.recalculateSizes = false
      this.$store.state.avalon.updateElementsArray = {}
      if ( !this.notPageInit ) {
        this.initPage()
      }
    },
    LANG() {
      // if ( this.previewMode ) {
      //   this.initPage()
      // }
    },
    scroll(value) {
      this.$store.state.avalon.scrollPosition = value
    },
  },
  methods : {
    ...mapActions('avalonPage',[
        'GET_TEMPLATE_PAGE_EDIT',
        'GET_PAGE_EDIT',
        'ADD_PAGE',
        'ADD_BLOCK',
        'DELETE_BLOCK',
        'COPY_BLOCK',
        'MOVE_BLOCK',
      'DELETE_ELEMENT'
    ]),
    ...mapActions('avalon' , [ 'SAVE_CHANGES' , 'CANCEL_CHANGES' , 'SAVE_CHANGES_ALL', 'UNPUBLISH_PAGE']),


    openStylesAndFonts() {
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true
      this.$store.state.avalon.menuNavigation = 'settings'
    },

    handleWorkAreaWidth(value) {
      if ( value < 450 ) {
        this.widthDisableEditor = true
      } else {
        this.widthDisableEditor = false
      }
      if ( !this.MOBILE_VIEW && value < 800 ) {
        this.toggleMobileView(true)
      }
    },

    cancelOpenLang() {
      this.$store.state.avalon.selectedLang = this.selectedLangBuffer
      this.displayNoLangDialog = false
    },
    shortenText(text = '' ) {
      if ( text.length > 60 ) {
        return text.slice(0,60) + ' ...'
      }
      return text
    },

    async copyLangPage(pageId = null) {
      let alias = this.page.split('no-lang_')[1]
      let navigationPage = this.$store.state.avalon.pagesSelect.find(el => el.alias === alias )
      let data = {
        lang : this.selectedLang,
        title : this.page.includes('$homepage') ? this.$t('Homepage') :  navigationPage.text,
        name : this.page.includes('$homepage') ? this.$t('Homepage') :  navigationPage.text,
        alias : alias,
        is_lang_homepage : this.page.includes('$homepage')
      }
      if ( this.entity.homepage ) {
        data.homepage = true
      }

      if ( pageId ) {
        data.id = pageId
      }

      let result = await this.ADD_PAGE(data)

      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.$store.state.avalon.pagesSelect.push(result)

      this.$router.push(this.businessPublicLink('page/' + result.id + '/editor'))

    },
    changeLanguage(lang) {
      if (lang === 'settings') {
        this.displayLangSettingsDialog = true
        return
      }
      this.$store.state.avalon.selectedLang = lang

      if ( this.page ) {
        setTimeout(()=> {

          let pageValue = null

          if ( this.entity.course && this.entity.course.uuid ) {
            let courseNavigationItem = this.$store.state.avalon.coursesSelect.find(el => el.value === this.entity.course.uuid)
            if ( courseNavigationItem && courseNavigationItem.page && courseNavigationItem.page[lang]) {
              pageValue = courseNavigationItem.page[lang]
            } else {
              pageValue = this.entity.course.uuid + '/course'
            }
          }

          else if ( this.entity.homepage || this.page === 'no-lang_$homepage' ) {
            pageValue = this.CURRENT_LANG_HOMEPAGE.value
          } else {
            let navigationPage = this.CURRENT_LANG_PAGES.find(el => el.alias === this.entity.alias )
            pageValue = navigationPage.value
          }

          this.$router.push(this.businessPublicLink('page/' + pageValue + '/editor'))
        } , 100)
      }



    },
    // buffer functions
    undo() {

      let { method, data , tooltip } = this.$store.state.avalon.undoBuffer[this.$store.state.avalon.undoBuffer.length - 1]
      this.notify(this.$t('Undo') + ': ' + this.$t(tooltip))

      let methodAction = this.bufferFunctions(method)
      this.POP_UNDO_BUFFER()
      if ( data ) {
        if ( data.length === 1 ) {
          methodAction(data[0])
        } else if ( data.length === 2 ) {
          methodAction(data[0],data[1])
        } else if ( data.length === 3 ) {
          methodAction(data[0],data[2])
        }
      } else {
        methodAction()
      }
      this.ACTIVATE_REDO()
    },
    redo() {
      if ( !this.REDO_BUFFER_ACTION ) {
        return
      }

      let { method, data , tooltip } = this.REDO_BUFFER_ACTION

      this.notify(this.$t('Redo') + ': ' + this.$t(tooltip))
      let methodAction = this.bufferFunctions(method)
      this.POP_REDO_BUFFER()
      if ( data ) {
        if ( data.length === 1 ) {
          methodAction(data[0])
        } else if ( data.length === 2 ) {
          methodAction(data[0],data[1])
        } else if ( data.length === 3 ) {
          methodAction(data[0],data[2])
        }
      } else {
        methodAction()
      }
    },
    bufferFunctions(method) {
      let action = {
        deleteElement : this.deleteElement,
        addElement : this.addElement
      }
      if ( action[method]) {
        return action[method]
      }
      return () => {}
    },
    async deleteElement(element) {
      let result = await this.DELETE_ELEMENT(element)
      if ( !result ) {
        return this.notify(this.$t("Error"))
      }
      let index = this.elements.findIndex(el => el.id === element)
      if ( index !== -1 ) {
        this.elements.splice(index, 1)
      }
      this.deletedElementId = element
    },
    addElement(type , blockId) {
      this.addedElementType = type
      this.updateBlockId = blockId
      setTimeout(()=> {
        this.addedElementType = null
        this.updateBlockId = null
      },1)
    },
    ///



    visibilityCondition(config) {
      if (!config) {
        return true
      }
      if ( this.MOBILE_VIEW ) {
        if ( config.visibility_mobile === false ) {
          return false
        }
      }

      if ( !this.MOBILE_VIEW ) {
        if ( config.visibility_desktop === false ) {
          return false
        }
      }

      return true
    },

    toggleMobileView(value) {
      this.$store.state.avalon.mobileView = value
    },

    async unPublishPage() {
      let result = await this.UNPUBLISH_PAGE(this.page)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.entity.active = false

      let index = this.$store.state.avalon.pagesSelect.findIndex(el => el.value === this.entity.id )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.pagesSelect[index].is_published = false
      this.$store.state.avalon.pagesSelect = this.COPY(this.$store.state.avalon.pagesSelect)

      this.notify(this.$t('PageUnPublished') )
    },

    async saveChanges() {
      if ( this.newsAlias ) {
        this.newsPublishTrigger++
        return
      }
      let result = await this.SAVE_CHANGES(this.page)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.entity.active = true

      let index = this.$store.state.avalon.pagesSelect.findIndex(el => el.value === this.entity.id )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.pagesSelect[index].is_published = true
      this.$store.state.avalon.pagesSelect = this.COPY(this.$store.state.avalon.pagesSelect)

      this.notify(this.$t(result.activated ? 'PagePublished' : 'ChangesPublished') , 'success')
    },
    async cancelPageChanges() {
      let result = await this.CANCEL_CHANGES(this.page)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.initPage()
      this.notify(this.$t( 'PageChangesReversed') )
    },

    async saveChangesAll() {
      let result = await this.SAVE_CHANGES_ALL()
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.entity.active = true
      this.$store.state.avalon.pagesSelect.forEach((_,index) => {
        this.$store.state.avalon.pagesSelect[index].is_published = true
      })
      this.notify(this.$t('AllPagesPublished') , 'success')
    },

    async addBlock(data) {

      data.page_id = this.entity.id
      data.is_footer = this.addFooter
      data.lang = this.selectedLang
      let result = await this.ADD_BLOCK(data)

      if ( !this.addFooter ) {
        this.entity.blocks.splice(this.addBlockIndex || -1 , 0, result.id )
        this.$store.state.avalonPage.newBlockNumber = result.number
        this.$store.state.avalonPage.newBlockId = result.id
        this.$store.state.avalonPage.blockNumberUpdateDirection = 1
        this.updateNumberTrigger++
        this.scrollToBlock(result.id)
      } else {
        this.updateFooterTrigger++
      }

      this.displayNewBlockDialog = false

    },
    scrollToBlock(id , end = false  ) {
      if ( !id ) {
        return
      }
      if ( String(id).includes('#') ) {
        id = id.replace('#' , '')
      }
      setTimeout(() => {
        let targetBlock = this.$refs[`block_${id}`][0]
        let scrollPosition = targetBlock.offsetTop -  this.dashPage.$el.offsetTop - (this.header.config.height || 50)
        if ( end ) {
          scrollPosition += targetBlock.getBoundingClientRect().height - 500
        }

        if ( this.dashPage && this.dashPage.$el && targetBlock ) {
          this.dashPage.$el.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          })
        }
      },100)
    },
    async moveBlock({block , direction} , index ) {

      let data = this.COPY(block)
      data.direction = direction
      let result = await this.MOVE_BLOCK(data)
      if ( !result ) {
        return
      }

      this.entity.blocks[index] = this.entity.blocks[index + direction];
      this.entity.blocks[index+direction] = block.id
      this.entity = this.COPY(this.entity)

      let blockNumber = block.number

      this.$store.state.avalonPage.blockMoveData = this.COPY({ old : blockNumber , value : blockNumber + direction })
      this.$store.state.avalonPage.blockMoveTargetData = this.COPY({ old : blockNumber + direction , value : blockNumber , initialId : block.id })

      this.scrollToBlock(block.id)

    },
    async copyBlock(block , index = 0 , pasteNumber) {
      let data = this.COPY(block)
      data.page_id = this.entity.id
      data.number = block.number + 1
      if ( pasteNumber || pasteNumber === 0 ) {
        data.number = pasteNumber + 1
      }

      let result = await this.COPY_BLOCK(data)
      if ( !result ) {
        return
      }
      this.entity.blocks.splice( index + 1,0 , result.id )

      this.$store.state.avalonPage.newBlockNumber = result.number
      this.$store.state.avalonPage.newBlockId = result.id
      this.$store.state.avalonPage.blockNumberUpdateDirection = 1
      this.updateNumberTrigger++

      this.displayCopyBlockDialog = false
      this.selectedBlock = null

      this.scrollToBlock(result.id)

    },
    async deleteBlock() {
      let result = await this.DELETE_BLOCK(this.selectedBlock)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }

      if ( !this.addFooter ) {
        let index = this.entity.blocks.findIndex(el => el === this.selectedBlock)

        if ( index === -1 ) {
          return
        }
        this.entity.blocks.splice( index , 1 )

        this.$store.state.avalonPage.newBlockNumber = this.selectedBlockNumber
        this.$store.state.avalonPage.blockNumberUpdateDirection = -1
        this.$store.state.avalonPage.newBlockId = this.selectedBlock

        this.updateNumberTrigger++
      } else {
        this.updateFooterTrigger++
      }

      this.displayDeleteBlockDialog = false
      this.selectedBlock = null


    },

    handleAddBlock($event , fromFooter = false , index) {

      this.addBlockIndex = index + $event.direction

      this.addFooter = fromFooter

      if ( $event.type === 'choose' ) {
        this.openAddBlock($event.number , fromFooter)
      }
    },

    openAddBlock(number , addFooter = false) {
      this.addFooter = addFooter
      this.newBlockNumber = number
      this.displayNewBlockDialog = true
    },
    openCopyBlock(block) {
      this.notify(block)
      this.selectedBlock = block
      this.displayCopyBlockDialog = true
    },
    openDeleteBlock(block , addFooter = false , number = null) {
      this.addFooter = addFooter
      this.selectedBlock = block
      this.selectedBlockNumber = number
      this.displayDeleteBlockDialog = true
    },
    toggleSideMenu() {
      if ( this.$store.state.avalon.menuOnlyStylesAndFonts)  {
        this.$store.state.avalon.menuNavigation = 'pages'
        this.$store.state.avalon.menuOnlyStylesAndFonts = false

        this.$store.state.avalon.selectedElementDesign = null
        this.$store.state.avalon.selectedElementType = null
        this.$store.state.avalon.selectedElementStyle = null
        this.$store.state.avalon.selectedBlockStyle = null
        this.$store.state.avalon.selectedBlockType = null
        this.$store.state.avalon.selectedBlockDesign = null


      } else {
        this.$store.state.avalon.menuOnlyStylesAndFonts = false
        this.$store.state.avalon.sideMenu = !this.$store.state.avalon.sideMenu
      }

    },
    async togglePreviewMode(value) {

      if ( !value ) {
        this.$router.push(this.businessPublicLink('page/' + this.entity.id + '/editor'))

      }

      if ( value ) {
        this.$store.state.avalon.previewFromSideMenu = this.$store.state.avalon.sideMenu
        this.$store.state.avalon.sideMenu = false
      } else if (this.$store.state.avalon.previewFromSideMenu) {
        this.$store.state.avalon.sideMenu = true
      }

      this.$store.state.avalon.mobileView = false
      this.$store.state.avalon.previewMode = value
    },
    //technical
    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },
    getBlockChildren(parentId) {
      if ( this.blocks.length === 0 ) {
        return []
      }
      return this.blocks.filter(el => el.parent === parentId)
    },
    getChildrenElements(parentId) {
      if ( this.getBlockChildren(parentId).length === 0 ) {
        return []
      }
      let items = [];
      this.getBlockChildren(parentId).forEach(block => {
        items = [...items , ...this.getBlockElements(block.id)]
      })
      return items
    },
    async initPage() {
      if ( !this.page ) {
        return
      }
      if ( this.page.includes('no-lang')) {
        this.handleNavigationChange()
        return
      }
      this.$store.state.avalon.recalculateSizes = false
      let result = false

      if ( !this.id ) {
        result  = await this.GET_PAGE_EDIT({id : this.page , lang : this.selectedLang })
      } else {
        result  = await this.GET_TEMPLATE_PAGE_EDIT(this.id)
      }
      this.handleNavigationChange()
      // TODO: Zero Recalculate - This shit could have been cause of all my problems (Without it nothing works, check this shit carefully)
      if ( this.$store.state.avalon.recalculateSizesTimeOut ) {
        clearTimeout(this.$store.state.avalon.recalculateSizesTimeOut)
      }
      this.$store.state.avalon.recalculateSizesTimeOut = setTimeout(() => {
        this.$store.state.avalon.updateElementsArray = {}
        this.$store.state.avalon.recalculateSizes = true

      } , 1500)



      if ( result.page.id.toString() !== this.page.toString() && !this.previewMode  ) {
        this.notPageInit = true
        this.$router.push(this.businessPublicLink('page/' + result.page.id + '/editor'))
        setTimeout(()=> { this.notPageInit = false } , 1)
      }
      this.$store.state.avalon.redoBuffer = []
      this.$store.state.avalon.undoBuffer = []

      this.$store.state.avalonPage.currentPage = this.page
      this.$store.state.avalonPage.course = {}
      this.entity = {}



      if ( !result ) {
        return
      }
      this.entity = result.page
      if ( result.page.course ) {
        this.$store.state.avalonPage.course = result.page.course
      }

      if ( this.entity.lang !== this.selectedLang ) {
        this.$store.state.avalon.selectedLang = result.page.lang
      }



    },
    handleNavigationChange() {

      setTimeout(()=> {
        if ( this.page ) {
          this.$store.state.avalon.selectedPage = this.page
        }
      },100)

    },
    processSelectionChange(event) {
      event.preventDefault()
      this.$store.state.avalon.selectionChange++
      this.$store.state.avalon.selection = window.getSelection()
    },
    onScroll() {
      if ( this.SM ){
        this.scroll = window.top.scrollY
      }
    }
  },
  mounted() {

    this.initPage()
    window.addEventListener("scroll", this.onScroll)
  },
  beforeMount() {
    this.handleWorkAreaWidth()
    this.$store.state.avalon.recalculateSizes = false
    this.$store.state.avalon.updateElementsArray = {}
  },
  beforeDestroy() {
    this.$store.state.avalonPage.currentPage = null
    window.removeEventListener("scroll" , this.onScroll)
  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.preview-triangle {
  position: absolute;
  top : 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-left: 58px solid transparent ;
}
</style>