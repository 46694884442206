<template>
  <div>


    <font-param-select
        v-model="fontStyle.setup"
        param-style
        no-hover
        :label-text="label"
    />
    <v-expand-transition>
      <v-sheet class="" :color="wsLIGHTCARD" v-if="fontStyle.setup === 'custom'">
        <!-- Family-->
        <font-family-select
            v-model="fontStyle.family"
        />
        <!-- Font Size -->
        <a-parameter-slider
            :value="fontStyle.size"
            @change="fontStyle.size = $event"
            style="width: 100%"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="FontSize"
            min="10"
            max="100"
            light
        />
        <a-parameter-slider
            :value="fontStyle.size_sm"
            @change="fontStyle.size_sm = $event"
            style="width: 100%"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="FontSizeSmall"
            min="10"
            max="100"
            light
        />
        <!-- Weight-->
        <font-param-select
            v-model="fontStyle.weight"
        />
        <!-- Style -->
        <font-param-select
            v-model="fontStyle.style"
            font-style
        />
        <!-- Spacing -->
        <a-parameter-slider
            :value="fontStyle.lineHeight"
            @change="fontStyle.lineHeight = $event"
            style="width: 100%"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="avalon.config.fonts.line_height"
            min="90"
            max="300"
            unit="%"
            light
        />
        <!-- Line Height -->
        <a-parameter-slider
            :value="fontStyle.spacing"
            @change="fontStyle.spacing = $event"
            style="width: 100%"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="avalon.config.fonts.letter_spacing"
            min="0"
            max="50"
            light
        />
        <!-- Case -->
        <font-param-select
            v-model="fontStyle.case"
            font-case
        />
      </v-sheet>
    </v-expand-transition>

  </div>
</template>

<script>
import fontParamSelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontParamSelect";
import fontFamilySelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontFamilySelect";
export default {
  name: "fontStyleParamPicker",
  components : {
    fontParamSelect,
    fontFamilySelect
  },
  props : {
    label : {
      type : String,
      default : 'Param'
    },
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      style : '',
      fontStyle : {}
    }
  },
  watch : {
    fontStyle : {
      handler() {
        if ( !this.EQ(this.value , this.fontStyle ) ) {
          this.$emit('input' , this.fontStyle)
        }
      },
      deep : true
    },
    value : {
      handler() {
        if ( !this.EQ(this.value , this.COPY(this.fontStyle) ) ) {
         this.fontStyle = this.COPY(this.value)
        }
      },
      deep : true
    },
  },
  mounted() {
    if ( Object.keys(this.value).length > 0 ) {
      this.fontStyle = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>

</style>