<template>
  <div class="pa-4">

    <template v-if="elementsDesignConfig[type]">

      <template v-for="(item,i ) in  elementsDesignConfig[type]">
        <preview-element
            v-if="element" :key="i"
            @click="design = item.value;"
            :element="item.element"
            :width="238"
            :block-style="blockStyle"
            :class="[{'mt-2' : i > 0}]"
            :is-selected="design === item.value"
        />
        <div    v-else :key="i"    @click.stop="design = item.value;">
          <preview-block
              width="238"
              :block="item.block"
              :block-style="blockStyle"
              :elements="item.block.elements"
              class="mt-3"
          />
        </div>


      </template>

    </template>


  </div>
</template>

<script>
import elementsDesignConfig from "@/assets/json/avalon/elements_design_config.json"
import previewElement from "@/components/AvalonEditor/Templates/previewElement";
import previewBlock from "@/components/AvalonEditor/Templates/previewBlock";

export default {
  name: "designSelector",
  components : {
    previewElement,
    previewBlock
  },
  props : {
    value : {
      type : String
    },
    type : {
      type : String,
      default : 'tiles'
    },
    element : {
      type : Boolean,
      default : false
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      design : "",
      elementsDesignConfig : {}
    }
  },
  watch : {
    value() {
      if ( this.design !== this.value ) {
        this.desgin = this.value
      }
    },
    design() {
      if ( this.design !== this.value ) {
        this.$emit('input' , this.design)
      }
    }
  },
  mounted() {
    this.elementsDesignConfig = elementsDesignConfig
    if ( this.value ) {
      this.design = this.value
    }
  }

}
</script>

<style scoped>

</style>