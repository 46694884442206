export default {
  computed : {
      defaultElements() {
          return {
              text: {
                  type : 'text',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 3,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 2,
                  config : {
                      has_background : false,
                      mobile_resize : true,
                      valign : 'top',
                      rounded : 8,
                      is_outlined : false,
                      padding_top : 0,
                      padding_bottom : 0,
                      padding_left : 0,
                      padding_right : 0
                  },
                  z: 25,
                  active: false,
                  text : "<p>Введіть ваш текст</p>"
              },
              button: {
                  type : 'button',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 2,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 2,
                  z: 25,
                  active: false,
                  config :  {
                      background_color : 'accent' ,
                      text_color : 'text_light',
                      outlined: false,
                      rounded : 4,
                      style : "primary"
                  },
              },
              modal: {
                  type : 'modal',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 2,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 2,
                  z: 25,
                  active: false,
                  config :  {
                      design : "sidebar_1",
                      background_color : 'accent' ,
                      text_color : 'text_light',
                      outlined: false,
                      rounded : 4,
                      style : "primary"
                  },
              },
              image: {
                  type : 'image',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 8,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 6,
                  z: 25,
                  active: false,
                  config :  {
                      contain : false ,
                      rounded : 4,
                      alt : ''
                  },
              },
              video: {
                  type : 'video',
                  x1: 1,
                  x2: 15,
                  y1: 1,
                  y2: 10,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 6,
                  z: 25,
                  active: false,
                  config :  {
                      video : null
                  },
              },
              rectangle: {
                  type : 'rectangle',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 8,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 6,
                  z: 24,
                  active: false,
                  config :  {
                      background_color : 'medium',
                      rounded : 4 ,
                  },
              },
              line: {
                  type : 'line',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 1,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 1,
                  z: 24,
                  active: false,
                  config :  {
                      color : 'accent' ,
                      rounded : 4 ,
                  },
                  vertical : false
              },
              course_name: {
                  type : 'course_name',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 3,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 3,
                  z: 25,
                  active: false,
              },
              course_price: {
                  type : 'course_price',
                  x1: 1,
                  x2: 5,
                  y1: 1,
                  y2: 2,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 2,
                  z: 25,
                  active: false,
              },
              form: {
                  type : 'form',
                  x1: 1,
                  x2: 10,
                  y1: 1,
                  y2: 10,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 8,
                  config : {
                      has_background : false,
                      background_color : "#00000000",
                      type : 'lead',
                      fields :['name' , 'phone' , 'email' , 'comment' ],
                      button_color : 'accent',
                      button_style : 'primary',
                      button_text_color : 'text_light',
                      button_rounded : 15,
                      button_margin_top : 15,
                      button_margin_left : 0,
                      button_margin_right : 0,
                      has_labels : true,
                      fields_padding : 10,
                      label_color : 'accent'
                  },
                  z: 25,
                  active: false,
                  text : this.$t('Send') ,
                  json : [
                      { type : 'text' , label : this.$t('Name') , value : 'firstname' }
                  ]
              },
              accordion: {
                  type : 'accordion',
                  x1: 1,
                  x2: 15,
                  y1: 1,
                  y2: 12,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 8,
                  z: 25,
                  active: false,
                  config :  {
                      elements : [
                          { title : "Topic 1" , text : "" , expanded : false },
                          { title : "Topic 2" , text : "" , expanded : false },
                          { title : "Topic 3" , text : "" , expanded : false }
                      ],
                      multiple_expand : false,
                      display_line : true,
                      size : 'large',
                      expand_first : true,
                      heading_font_style : 'h2',
                      text_font_style : 'p'
                  },
              },
              list: {
                  type : 'list',
                  x1: 1,
                  x2: 15,
                  y1: 1,
                  y2: 12,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 8,
                  z: 25,
                  active: false,
                  config :  {
                      elements : [
                          { title : "Building the Foundation: Early Childhood Education" , text : "Explore the critical role of early childhood education in shaping lifelong learning and development." },
                          { title : "Harnessing Technology for 21st Century Learning" , text : "Discover how technology can enhance education, fostering digital literacy and preparing students for the future."  },
                          { title : "Empowering Teachers as Agents of Change" , text : "Explore initiatives that empower educators, enabling them to inspire students and drive educational innovation."  }
                      ],
                     style : 'rows'
                  },
              },
              'course-content': {
                  type : 'course-content',
                  x1: 1,
                  x2: 15,
                  y1: 1,
                  y2: 12,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 8,
                  z: 25,
                  active: false,
                  config :  {
                      course : null
                  },
              },
              course_pricing: {
                  type : 'list',
                  x1: 1,
                  x2: 15,
                  y1: 1,
                  y2: 12,
                  smX1: 1,
                  smX2: 6,
                  smY1: 1,
                  smY2: 8,
                  z: 25,
                  active: false,
                  config :  {
                      course : null
                  },
              },

          }
      },
  }
}