<template>

  <div ref="block">


  <div v-if="!($store.state.avalon.previewMode || $store.state.avalon.sideMenu)"  :style="blockTopMarginFromHeader" style="user-select: none; ">
    <!-- Block Navigation Settings -->
    <v-hover v-if="block.type === 'navigation'" #default="{hover}">
      <v-sheet :color="wsLIGHTACCENT"  class="py-2 px-16 d-flex align-center justify-center" height="52">
        <v-sheet :width="block.config.width || 1140" style="position: relative" color="transparent" class="d-flex justify-center align-center" >
          <h5  :style="`color : ${wsACCENT}`" class="text-center">{{ $t('NavigationBlock') }} #{{block.id}}</h5>

          <v-fade-transition>
            <v-sheet

                v-if="hover"
                :style="`border : 1px solid ${wsBACKGROUND}`"
                class="d-flex align-center"
                style="z-index: 250; border-radius: 8px; position: absolute; right: 0"
            >
              <v-btn @click="displaySettings = true" :color="wsACCENT" icon >
                <v-icon size="20">mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="$emit('delete',block)"  :color="wsACCENT" icon >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
              <v-divider  :style="`border-color : ${wsBACKGROUND}`" vertical/>
              <v-btn v-if="!isFirst"  @click="$emit('move',{block : block , direction : -1})"  :color="wsACCENT" icon >
                <v-icon size="20">mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn v-if="!isLast"  @click="$emit('move',{block : block , direction : 1})" :color="wsACCENT" icon >
                <v-icon size="20">mdi-arrow-down</v-icon>
              </v-btn>
            </v-sheet>
          </v-fade-transition>

        </v-sheet>
      </v-sheet>
    </v-hover>

    <!-- Block Content -->
    <v-hover v-model="hover">
<!--  TODO: ASK George about this :     @input="$store.state.avalon.selectedElement = null "-->
      <div style="position:relative;">

        <!-- Add Block Top Button -->
        <v-fade-transition>
          <div
              v-if="(hover || edit) && editorMode && !selectedElement && !isFirst && !block.parent"
              class="d-flex justify-center"
              style="position: absolute; width: 100%; z-index : 301 ;"
              :style="overlap && header.config.overlap ? `top : ${!MOBILE_VIEW ? header.config.height : header.config.height_sm}px` : ''"
          >
            <v-btn
                @click="addBlock(-1,'choose')"
                :color="wsATTENTION"
                class="mt-n4"
                style="z-index : 999; background-color: white"
                icon >
              <v-icon large>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </v-fade-transition>

        <!-- Block Edit Buttons -->
        <v-fade-transition>
          <div
              v-if="displayBlockEditCondition"
              class="pa-5  d-flex justify-space-between"
              style=" z-index: 9999; width: 100%; position: absolute;pointer-events: none; transition: all 0.2s ease"
              :style="blockEditButtonsStyle "
          >


            <!-- Add Element  -->
            <div v-if="!MOBILE_VIEW" style="z-index: 250; height: 40px; pointer-events: all">
              <ft-select
                  v-if="(hover || edit) && !block.type"
                  :items="BLOCK_ELEMENTS_SELECT(block)"
                  @input="addElement($event)"
                  :icon-color="wsATTENTION"
                  @expand="edit = $event"
              >
                <v-btn
                    style="background-color: white; border-radius: 8px"
                    :color="wsBACKGROUND"
                    outlined
                    class="noCaps"
                    height="40"
                >
                  <span :style="`color : ${wsACCENT}`">
                    <v-icon size="20" :color="wsACCENT">mdi-plus</v-icon>
                    {{ $t('AddElement') }}
                  </span>
                </v-btn>
              </ft-select>
            </div>
            <!-- Block Edit  -->
            <v-sheet
                :style="`border : 1px solid ${wsBACKGROUND}`"
                style="border-radius: 8px; pointer-events: all"
            >
              <div   class="d-flex align-center">
                <v-btn @click="displaySettings = true" :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 7px 0 0 7px;" >
                  <v-icon size="20">mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="!footer" @click="$emit('copy',block)" :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 0" >
                  <v-icon size="20">mdi-content-copy</v-icon>
                </v-btn>
                <v-btn @click="$emit('delete',block)"  :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 0"  >
                  <v-icon size="20">mdi-delete</v-icon>
                </v-btn>
                <ft-select
                    :items="blockEditActionsSelect"
                    @expand="blockEditDropdown = $event"
                >
                  <v-btn :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 0" >
                    <v-icon size="20">mdi-menu-down</v-icon>
                  </v-btn>
                </ft-select>
                <v-divider v-if="!footer" :style="`border-color : ${wsBACKGROUND}`" vertical/>
                <v-btn v-if="!footer && !isFirst" @click="$emit('move',{block : block , direction : -1})"  :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 0" >
                  <v-icon size="20">mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn v-if="!footer && !isLast" @click="$emit('move',{block : block , direction : 1})" :color="wsACCENT" min-width="34" height="40" class="px-0" text style="border-radius: 0 7px 7px 0" >
                  <v-icon size="20">mdi-arrow-down</v-icon>
                </v-btn>
              </div>

              <!-- Mobile RearrangeButton-->
              <div v-if="MOBILE_VIEW && displayMobileRearrange">
                <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                <ws-button @click="rearrangeMobileOverlapFunction" text block label="Rearrange"></ws-button>
              </div>


            </v-sheet>
          </div>
        </v-fade-transition>

        <!-- BLOCK CONTENT -->

        <div :style="layoutStyle(hover)"
              @mouseover="$store.state.avalon.selectedBlockId = block.id"
              class="d-flex justify-center"
              style="position : relative "
        >

          <v-sheet
              v-if="block.config.background_type === 'image' "
              :color="GET_AVALON_COLOR(avalonBlockConfig(block, 'overlay_color' ))"
              :style="`opacity : ${avalonBlockConfig(block, 'overlay_opacity' )/100}`"
              class="fill-height"
              width="100%"
              style="position:absolute;"
          />

          <v-sheet
              :width="blockWidth"
              :style="style"
              color="transparent"
          >

            <!-- Title and Subtitle-->
            <div v-if="block.type && (block.config.title || block.config.subtitle)" class="mb-12 px-4">
              <h1 v-if="block.config.title"
                  :class="[{'text-center' : block.config.heading_align === 'center'},{'text-right' : block.config.heading_align === 'right'}]"
                  :style="BLOCK_TEXT_CSS(block,  block.config.style , 'block' , 'title')"
              >
                {{ block.config.title }}
              </h1>
              <h3 v-if="block.config.subtitle"
                  :class="[{'text-center' : block.config.heading_align === 'center'},{'text-right' : block.config.heading_align === 'right'}]"
                  :style="BLOCK_TEXT_CSS(block , block.config.style , 'block'  , 'subtitle' )"
              >
                {{ block.config.subtitle }}
              </h3>
            </div>

            <block-zero
                v-if="!block.type"
                v-model="block"
                @updateBlock="updateBlock(block)"
                @cell_dimensions="cellDimensions = $event"
                @maxHeight="maxHeight = $event"
                @maxHeightSM="maxHeightSM = $event"
                @max-width="maxWidth = $event"
                :key="'zero' + block.id"
                :template="template"
                :editingVdr="displaySettings || displayVdr"
                @duplicate="duplicateElement($event)"
                @display-mobile-rearrange="displayMobileRearrange = $event"
                @rearrange-function="rearrangeMobileOverlapFunction = $event"
                @handle-resize-function="handleHeightResize = $event"
                @display-handle-resize="displayHeightResizeHandle = $event"
            />
            <block-courses
                v-if="block.type === 'courses'"
                :block="block"
            />
            <block-news
                v-if="block.type === 'news' "
                :block="block"
            />
            <block-contact-form
                v-if="block.type === 'contact_form' "
                :block="block"
            />
            <block-gallery
                v-if="block.type === 'gallery' "
                :block="block"
                :navigation-width="navigationWidth"
                :content-width="contentWidth"
            />
            <block-tiles
                v-if="block.type === 'tiles' "
                :block="block"
            />
            <block-tiles-dynamic
                v-if="['tiles_news','tiles_courses'].includes(block.type)"
                :block="block"
            />
            <block-course-pricing
                v-if="block.type === 'course-pricing'"
                :block="block"
            />
            <block-slider
                v-if="block.type === 'slider1' "
                :block="block"
                :navigation-width="navigationWidth"
                :content-width="contentWidth"
            />
            <block-text
                v-if="block.type === 'block-text' "
                :block="block"
                @update-block="updateBlock"
            />
            <block-navigation
                v-if="block.type === 'navigation' && parent "
                :key="'parentBlock' + block.id"
                :block="block"
                :children="children"
                :children-elements="childrenElements"
            />

            <block-calendar
                v-if="block.type === 'calendar'"
                :block="block"
            />

          </v-sheet>
        </div>

        <!-- Add Block Bottom Button -->
        <v-fade-transition>
          <div
              v-if="(hover || edit) && editorMode && !footer && !selectedElement && !block.parent"
              class="d-flex justify-center"
              style="position: absolute; width: 100%; z-index : 999"
          >
            <v-btn
                @click="addBlock(1, 'choose')"
                :color="wsATTENTION"
                class="mt-n4"
                style="z-index : 999; background-color: white"
                icon >
              <v-icon large>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </v-fade-transition>

        <height-resize-handle
            v-if="!block.type && (hover || MOBILE_VIEW && $store.state.avalon.selectedBlockId === block.id) && block.config"
            @input="block = COPY(block)"
            @update="updateBlock"
            v-model="block.config[DIM_KEY['rows']]"
            :block="block"
            @dragging="displayVdr = $event"
            :max-height="MOBILE_VIEW ? maxHeightSM : maxHeight"
        />

      </div>


    </v-hover>


    <!-- Block Settings Controls-->
    <v-expand-x-transition>
      <avalonBlockSettings
          @close="displaySettings = false "
          v-if="displaySettings "
          v-model="displaySettings"
          :block="block"
          @update="updateBlock($event)"
          :maxHeight="maxHeight"
          :maxHeightSM="maxHeightSM"
          :max-width="maxWidth"
          @editing="editing = $event"
      />
    </v-expand-x-transition>

  </div>
  <view-block
      v-else
      :block="block"
      :elements="block.elements"
      :is-first="isFirst"
      :overlap="overlap"
  />
<!--    v-show="($store.state.avalon.previewMode || $store.state.avalon.sideMenu)"-->
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import BlockCourses from "@/components/AvalonEditor/Editor/blocks/blockCourses";
import BlockNews from "@/components/AvalonEditor/Editor/blocks/blockNews";
import BlockContactForm from "@/components/AvalonEditor/Editor/blocks/blockContactForm";
import blockZero from "@/components/AvalonEditor/Editor/blocks/ZeroBlock/BlockZero";
import heightResizeHandle from "@/components/AvalonEditor/Editor/blocks/ZeroBlock/heightResizeHandle";
import avalonBlockSettings from "@/components/AvalonEditor/Dialogs/blocks/AvalonBlockSettings";
import avalonDefaultElements from "@/mixins/avalon/avalonDefaultElements";
import blockCoursePricing from "@/components/AvalonEditor/Editor/blocks/tiles/blockCoursePricing";
import blockTiles from "@/components/AvalonEditor/Editor/blocks/tiles/blockTiles"
import blockTilesDynamic from "@/components/AvalonEditor/Editor/blocks/tiles/blockTilesDynamic"
import blockGallery from "@/components/AvalonEditor/Editor/blocks/gallery/blockGallery"
import blockSlider from "@/components/AvalonEditor/Editor/blocks/slider/blockSlider"
import blockNavigation from "@/components/AvalonEditor/Editor/blocks/blockNavigation"
import blockText from "@/components/AvalonEditor/Editor/blocks/blockText";
import blockCalendar from "@/components/AvalonEditor/Editor/blocks/calendar/BlockCalendar.vue";


export default {
  name: "blockEditor",
  components : {
    viewBlock,
    blockZero ,
    blockTiles ,
    blockTilesDynamic,
    blockGallery,
    blockSlider,
    blockNavigation,
    BlockCourses,
    blockCoursePricing,
    BlockNews,
    BlockContactForm,
    blockText,
    avalonBlockSettings,
    heightResizeHandle,
    blockCalendar
  },
  mixins : [avalonDefaultElements],
  props : {
    blockId : {
      type : Number,
      default : null
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    overlap : {
      type : Boolean,
      default : false
    },
    isFirst : {
      type : Boolean,
      default : false
    },
    isLast : {
      type : Boolean,
      default : false
    },
    footer : {
      type : Boolean,
      default : false
    },
    template : {
      type : Boolean,
      default : false
    },
    deletedElementId : {
      type : Number
    },
    addedElementType : {
      type : Text
    },
    updateBlockId : {
      type : Number
    },
    children : {
      type : Array,
      default() { return [] }
    },
    childrenElements : {
      type : Array,
      default() { return [] }
    },
    child : {
      type : Boolean,
      default : false
    },
    parent : {
      type : Boolean,
      default : true
    },
    navigationWidth : {
      type : Number,
      default : 0
    },
    contentWidth : {
      type : Number,
      default : 0
    },
    updateNumberTrigger : {
      type : Number,
      default : 0,
    },
    index : {
      type : Number,
      default : 0,
    }
  },
  data() {
    return {
      hover : false,
      blockLoaded : false,
      edit : false,
      editing : false,
      displaySettings : false,
      blockElements : [],
      cellDimensions : {},
      newElementTrigger : 0,
      maxHeight : 0,
      maxHeightSM : 0,
      maxWidth : 0,
      block : {
        config : {}
      },

      blockEditDropdown : false,

      displayMobileRearrange : false,
      rearrangeMobileOverlapFunction : () => {},
      handleHeightResize :  () => {},
      displayHeightResizeHandle : false,
      displayVdr : false,

      blockEditTopCorrectionDistance : 0,
      blockEditPosition : 0,

    }
  },
  computed : {
    ...mapState('avalon',[
      'editorMode' ,
      'header' ,
      'scrollPosition' ,
      'selectedLang' ,
      'selectedElement' ,
      'selectedBlockId'
    ]),
    ...mapState('avalonPage',[
        'blockMoveData' ,
        'blockMoveTargetData'
    ]),
    displayBlockEditCondition() {
      return ((this.hover || this.edit || this.blockEditDropdown) && this.editorMode && !this.selectedElement  && this.block.type !== 'navigation' && !this.MOBILE_VIEW) || (this.MOBILE_VIEW && !this.selectedElement && this.$store.state.avalon.selectedBlockId === this.block.id)
    },
    updateMoveTrigger() {
      return Object.keys(this.$store.state.avalonPage.blockNumberUpdateObject).length
    },

    blockEditActionsSelect() {
      let items =  [
        // { text : this.$t('Disable') , action : this.disableBlock },
        { text : this.$t('Copy') , action : this.copyBlockToBuffer },
      ]

      if ( this.$store.state.avalon.bufferCopyBlockId ) {
        items.push({ text : this.$t('Paste') , action : this.pasteBlock })
      }

      if ( !this.block.is_template ) {
        items.push({ text : this.$t('SaveAsTemplate') , action : this.saveBlockAsTemplate })
      }

      return items
    },

    blockEditButtonsStyle() {

      let topMargin = 25 + this.blockEditTopCorrectionDistance;

      if ( this.header.config.overlap && this.header.config.sticky && this.isFirst) {
        topMargin += this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']*2
      } else if (this.header.config.overlap && this.isFirst) {
        topMargin += this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
      }

      let style = `top : ${topMargin}px;`

      if ( this.MOBILE_VIEW ) {
        style += `margin-left : ${this.MOBILE_WIDTH};`
      }

      return style
    },
    blockTopMarginFromHeader() {
     return  this.isFirst && this.header.config.overlap ? `margin-top : -${!this.MOBILE_VIEW ? this.header.config.height : this.header.config.height_sm}px` : ''
    },
    style() {
      let style = ""

      let overlap = this.overlap && this.header.config.overlap ? this.header.config.height : 0
      if ( this.MOBILE_VIEW ) {
        overlap = this.overlap && this.header.config.overlap ? this.header.config.height_sm : 0
      }
      if ( this.header.config.sticky ) {
        overlap = overlap*2
      }

      if ( this.avalonBlockConfig(this.block, 'full_height') ) {
        style += `height : 100vh;`
      }

      if ( !this.MOBILE_VIEW) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding')   && this.block.config ) {
          if ( this.block.config.padding_top || this.block.config.padding_top === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top !== undefined ? this.block.config.padding_top : this.avalonConfig.block.padding_top) + overlap }px;`
          }
          if ( this.block.config.padding_bottom || this.block.config.padding_bottom === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom !== undefined ? this.block.config.padding_bottom : this.avalonConfig.block.padding_bottom}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top') + overlap }px;`
        }

      }

      if ( this.MOBILE_VIEW) {

        style += `padding-top : ${(this.block.config.padding_top_sm || 0 ) + overlap }px;`
        style += `padding-bottom : ${this.block.config.padding_bottom_sm || 0 }px;`


      }



      return style
    },
    blockWidth() {
      if ( this.avalonBlockConfig(this.block, 'full_width')) {
        return '100%'
      }
      if ( this.MOBILE_VIEW ) {
        return '100%'
      }
      if ( this.avalonBlockConfig(this.block, 'width') ) {
        return this.avalonBlockConfig(this.block, 'width').toString()
      }
      return '100%'
    },
    addBlocksSelect() {
      return [
        { text : this.$t('BlankBlock')   , value: 'blank'   , icon : 'mdi-book-variant' },
        { text : this.$t('ChooseBlock')  , value: 'choose'  , icon : 'mdi-magnify' },
      ]
    },
    sticky() {
      return this.overlap ? ( this.header.config.sticky && this.scrollPosition > 43 ) : false
    },
  },
  watch : {

    // update block data
    blockMoveData() {

      if ( this.blockMoveData.old && this.blockMoveData.old === this.block.number && this.blockMoveData.value !== this.block.number ) {
        this.block.number =  this.blockMoveData.value
        this.$store.state.avalonPage.blockMoveData = {}
      }
    },
    blockMoveTargetData() {

      if ( this.blockMoveTargetData.old && this.blockMoveTargetData.old === this.block.number && this.block.id !== this.blockMoveTargetData.initialId ) {
        this.block.number =  this.blockMoveTargetData.value
        this.$store.state.avalonPage.blockMoveTargetData = {}
      }
    },
    updateNumberTrigger() {

        if ( this.block.id !== this.$store.state.avalonPage.newBlockId &&  this.block.number >= this.$store.state.avalonPage.newBlockNumber ) {
          this.block.number += this.$store.state.avalonPage.blockNumberUpdateDirection
        }

    },
    // buffer function
    deletedElementId(value) {
      if ( !value ) {
        return
      }
      let index = this.blockElements.findIndex(el => el.id === value)
      if ( index !== -1 ) {
        this.blockElements.splice(index, 1)
      }
    },
    addedElementType(value) {
      if ( !value ) {
        return
      }
      if ( this.updateBlockId === this.block.id ) {
        this.addElement(value)
      }

    },
    // block edit buttons
    scrollPosition(){
      this.checkBlockEditPosition()
    },
    displayBlockEditCondition(value){
      if ( value ) {
        setTimeout(()=>{
          this.checkBlockEditPosition()
        },100)

      }
    }
  },
  methods : {
    ...mapActions('avalonPage',[
        'ADD_ELEMENT',
        'EDIT_BLOCK_DRAFT',
        'GET_BLOCK_EDITOR',
        'DUPLICATE_ELEMENT'
    ]),

    checkBlockEditPosition(){
      if (!this.displayBlockEditCondition) {
        this.blockEditTopCorrectionDistance = 0
        return
      }
      let element = this.$refs.block
      if ( !element ) {
        this.blockEditTopCorrectionDistance = 0
        return
      }
      let top = element.getBoundingClientRect().y
      let bottom = element.getBoundingClientRect().bottom



      let scrollStart = this.EDITOR_PREHEADER_HEIGHT

      if ( this.header.config.overlap && this.header.config.sticky && this.isFirst   ) {
        scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']*2
      }
      else if ( this.header.config.overlap && this.isFirst   ) {
        scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
      }

      if ( this.header.config.sticky ) {
        scrollStart += this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
      }

      if (top <  scrollStart && bottom > scrollStart + 80 ) {
        this.blockEditTopCorrectionDistance = -top + scrollStart
      } else {
        this.blockEditTopCorrectionDistance = 0
      }
    },
    // Block Dropdown Edit Action

    disableBlock() {
      this.notify('disableBlock')
    },
    cutBlock() {
      this.notify('cutBlock')
    },
    copyBlockToBuffer() {
      this.$store.state.avalon.bufferCopyBlockId = this.COPY(this.block)
      this.notify(this.$t('BlockCopied'))
    },
    pasteBlock() {
      this.$emit('paste' , this.block.number)
      this.$store.state.avalon.bufferCopyBlockId = null
    },
    async saveBlockAsTemplate() {
      this.block.is_template = true
      await this.updateBlock(this.block)
      this.notify(this.$t('BlockSavedAsTemplate'))
    },

    async addElement(type) {

      let data = Object.assign({},this.defaultElements[type])
      data.lang = this.selectedLang
      data.block_id = this.block.id
      data.y1  += this.maxHeight
      data.y2  += this.maxHeight
      data.smY1  += this.maxHeightSM
      data.smY2  += this.maxHeightSM

      if ( this.block.config.rows_sm < data.smY2 ) {
        this.block.config.rows_sm = data.smY2
        await this.updateBlock(this.block)
      }

      if ( this.block.config.rows < data.y2 ) {
        this.block.config.rows = data.y2
        await this.updateBlock(this.block)
      }

      let result = await this.ADD_ELEMENT(data)
      if ( !result ) {
        return
      }

      this.ADD_UNDO_BUFFER('deleteElement' , [result.id] , 'AddElement' )
      this.ADD_REDO_BUFFER('addElement' , [type , this.block.id ] , 'AddElement' , false)


      let cellsWidth  = result[this.DIM_KEY['x2']] - result[this.DIM_KEY['x1']] +1
      let cellsHeight = result[this.DIM_KEY['y2']] - result[this.DIM_KEY['y1']] +1
      let cellWidth = this.cellDimensions.width
      let cellHeight = this.cellDimensions.height

      result.width = (cellsWidth * cellWidth) + ((cellsWidth-1)*this.cellDimensions.gapHorizontal )
      result.height = (cellsHeight * cellHeight) + ((cellsHeight-1)*this.cellDimensions.gapVertical )
      result.x = (result[this.DIM_KEY['x1']] - 1) * (cellWidth+this.cellDimensions.gapHorizontal)
      result.y =  (result[this.DIM_KEY['y1']] - 1) * (cellHeight+this.cellDimensions.gapVertical)

      result.new = true
      this.block.elements.push(result)

      this.scrollToBlockEnd(result)
    },
    async duplicateElement(element) {
      let data = Object.assign({} , element)

      data.y1 += (element.y2 - element.y1 ) + 1
      data.y2 += (element.y2 - element.y1 ) + 1
      data.smY1  = this.maxHeightSM + 1
      data.smY2  = this.maxHeightSM + 1 + (element.smY2 - element.smY1 )

      if ( this.block.config.rows_sm < data.smY2 ) {
        this.block.config.rows_sm = data.smY2
        await this.updateBlock()
      }

      if ( this.block.config.rows < data.y2 ) {
        this.block.config.rows_sm = data.y2
        await this.updateBlock()
      }

      let result = await this.DUPLICATE_ELEMENT(data)
      if ( !result ) {
        return
      }

      let cellsWidth  = result[this.DIM_KEY['x2']] - result[this.DIM_KEY['x1']] +1
      let cellsHeight = result[this.DIM_KEY['y2']] - result[this.DIM_KEY['y1']] +1
      let cellWidth = this.cellDimensions.width
      let cellHeight = this.cellDimensions.height

      result.width = (cellsWidth * cellWidth) + ((cellsWidth-1)*this.cellDimensions.gapHorizontal )
      result.height = (cellsHeight * cellHeight) + ((cellsHeight-1)*this.cellDimensions.gapVertical )
      result.x = (result[this.DIM_KEY['x1']] - 1) * (cellWidth+this.cellDimensions.gapHorizontal)
      result.y =  (result[this.DIM_KEY['y1']] - 1) * (cellHeight+this.cellDimensions.gapVertical)

      result.new = true
      this.block.elements.push(result)

      this.scrollToBlockEnd()


    },
    async updateBlock(block = null) {


      if ( !block ) {
        block = this.COPY(this.block)
      }

      let result = await this.EDIT_BLOCK_DRAFT(block)
      if ( !result ) {
        return
      }

      this.block = this.COPY(block)
    },

    addBlock(direction , type ) {


      let number = this.block.number + direction
      let data = {
        number : number,
        direction : direction,
        type : type
      }

      this.$emit('add', data )
    },

    // technical
    scrollToBlockEnd() {
      this.$emit('scroll-to-end' )
    },

    layoutStyle(hover ) {
      let style = 'transition : -webkit-box-shadow 0.3s ease, -moz-box-shadow 0.3s ease, box-shadow 0.3s ease;'

      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'color' ) {

        let color = this.block.config.style
            ? this.BLOCK_COLOR_PARAM(this.block ,  'bg' )
            : this.GET_AVALON_COLOR(this.block.config.color)
        style += `background-color : ${color}` + ';'
      }


      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'image' ) {
        style += `background: url('${this.avalonBlockConfig(this.block, 'image' )}');
                  background-size : cover;
                  position:relative;`
        if ( !this.MOBILE_VIEW ) {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_y' )}%;`
        } else {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_sm_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_sm_y' )}%;`
        }
      }

      if ( (hover || this.edit || this.blockEditDropdown) && this.editorMode && this.block.type !== 'navigation' ) {
        style += `-webkit-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `-moz-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
      }

      return style
    },

    async initBlock() {
      this.blockLoaded = false
      let result = await this.GET_BLOCK_EDITOR(this.blockId)
      if ( !result ) {
        this.blockLoaded = true
        return
      }

      this.block = this.COPY(result)

      this.blockLoaded = true

    }

  },
  mounted() {
    this.initBlock()
  }

}
</script>

<style scoped lang="scss">
</style>