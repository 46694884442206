<template>

  <!-- Design Settings-->
  <div v-if="design" class="pa-4">

  </div>
  <!-- Format Settings-->
  <div v-else-if="format">
    <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.blocks_in_row') }}</h4>
    <ws-button-group
        v-model="block.config.columns_large"
        @input="$emit('input' , COPY(block.config))"
        :items="itemsPerPageSelect"
        class="mb-2 mt-2"
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_horizontal"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.gutter_vertical"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="format.rows.gutter_vertical"
        min="0"
        max="40"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        v-model="block.config.image_height"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="ImageHeight"
        min="40"
        max="500"
        class="mt-4"
        light
    />

    <a-parameter-slider
        @change="updateSettings(); "
        @input="$emit('editing' , true)"
        v-model="block.config.block_radius"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Rounded"
        min="0"
        max="32"
        class="mt-4"
        light
    />


  </div>

  <!-- Content Settings-->
  <div v-else-if="!isStyle">

    <block-settings-navigation
        v-model="selectedContentCategory"
        :items="sliderContentSelect"
        :return-action="selectedItemIndex !== null ? () => { selectedItemIndex = null ; selectedContentCategory='items'  } : null"
        portal
    >
      <template #item.heading>
        <block-general-settings
            @input="updateSettings"
            v-model="block.config"
            :block="block"
            content
        />

        <ws-text-field
            class="mt-7"
            @change="updateSettings"
            v-model="block.config.button_text"
            :placeholder="$t('EnterCardButtonText')"
            :label="$t('CardButtonText')"
        />
      </template>
      <template #item.items>

        <a-parameter-select
            class="mt-4"
            @input="updateSettings"
            v-model="block.config.course"
            :items="coursesSelect"
            label="Course"
            :label-color="wsACCENT"
            :value-color="wsACCENT"
            null-text="Auto"
            label-top
            clearable
        />
        <v-divider
            class="mt-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />

        <div v-if="!block.config.course"  class="pt-16">
          <div class="d-flex justify-center">
            <v-icon size="40" :color="wsDARKLIGHT">mdi-wallet</v-icon>
          </div>
          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular text-center mt-3">
            {{ $t('avalon.course-pricing.auto_plans' ) }}
          </h5>
        </div>
        <div v-else-if="!selectedCourse.has_payment_plans
        || (selectedCourse.has_payment_plans && selectedCourse.payment_plans_select && selectedCourse.payment_plans_select.length === 0  ) "
             class="pt-16"
        >
          <span style="display: none">{{ selectedCourse.has_payment_plans }}</span>
          <div class="d-flex justify-center">
            <v-icon size="40" :color="wsDARKLIGHT">mdi-wallet</v-icon>
          </div>
          <h5 :style="`color : ${wsACCENT}`" class="font-weight-regular text-center mt-3">
            {{ $t(selectedCourse.has_payment_plans ? 'avalon.course-pricing.no_plans' : 'avalon.course-pricing.no_plans_settings') }}
          </h5>
          <ws-button
              :to="businessDashLink(`courses/${selectedCourse.value}/${!selectedCourse.has_payment_plans ? 'settings' : 'payment_plans'}`)"
              :label="selectedCourse.has_payment_plans ? 'avalon.course-pricing.add' :  'OpenCourseSettings'"
              class="mt-6"
              block
              new-window
          />

        </div>


        <div v-else-if="selectedCourse.payment_plans_select && selectedCourse.payment_plans_select.length > 0" class="mt-6">
          <h5 :style="`color : ${wsACCENT}`" class="mb-6"> {{ $t('avalon.course-pricing.pricing_avalon_edit_title') }} </h5>

          <ws-accordion
              :items="plansAccordionItems"
          >
            <template #item="{item}">
              <div class="pa-3">
                <image-param-picker
                    @input="editPlanImage($event, item);"
                    :value="item.img"
                />

                <ws-text-field
                    @change="editPlanDescription($event, item);"
                    :value="item.description && item.description !== ' ' ? item.description : ''"
                    :label="$t('Description')"
                    :placeholder="$t('EnterDescription')"
                    class="mt-6"
                    area
                />

              </div>

            </template>
          </ws-accordion>

        </div>


      </template>
      <template #item.edit_item>
        <ws-text-field
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].title"
            :label="$t('Title')"
        />
        <ws-text-field
            class="mt-4"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].text"
            :label="$t('Subtitle')"
        />
        <ws-text-field
            class="mt-4"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].description"
            :label="$t('Description')"
            area
        />
        <ws-select
            class="mt-4"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].action"
            :items="buttonActionsSelect"
            :label="`${$t('ActionOnClick')} “${block.config.button_text}” `"
        />
        <ws-text-field
            v-if="block.config.elements[selectedItemIndex].action === 'link'"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].link"
            :label="$t('Link')"
        />
        <ws-avalon-text-input
            v-if="['dialog' , 'sidebar'].includes(block.config.elements[selectedItemIndex].action)"
            @input="updateSettings"
            :label="$t('SideBarText')"
            v-model="block.config.elements[selectedItemIndex].additional_text" />
        <ws-accordion
            class="mt-6"
            :items="imageAccordeonItems">
          <template #item.image>
            <image-param-picker
                @input="updateSettings"
                settings
                :vertical="block.config.elements[selectedItemIndex].img_vertical"
                :horizontal="block.config.elements[selectedItemIndex].img_horizontal"
                :contain="block.config.elements[selectedItemIndex].img_contain"
                @vertical="block.config.elements[selectedItemIndex].img_vertical = $event; updateSettings()"
                @horizontal="block.config.elements[selectedItemIndex].img_horizontal = $event; updateSettings()"
                @contain="block.config.elements[selectedItemIndex].img_contain = $event; updateSettings()"
                class="pa-4"
                v-model="block.config.elements[selectedItemIndex].img"
            />
          </template>
        </ws-accordion>
      </template>

    </block-settings-navigation>

  </div>

</template>

<script>


import entitiesFunctions from "@/mixins/entitiesFunctions";
import {mapActions} from "vuex";

export default {
  name: "blockTilesSettings",
  mixins : [entitiesFunctions],
  init : 'courses/GET_COURSES_SELECT',
  init_callback(result) {
    this.coursesSelect = result && result !== true ?  result : []
  },
  components : {
    blockGeneralSettings : () => import("@/components/AvalonEditor/Dialogs/blocks/blockGeneralSettings"),
    imageParamPicker : () => import("@/components/AvalonEditor/UI/imageParamPicker"),
    blockSettingsNavigation : () => import("@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation"),
    wsAvalonTextInput : () => import("@/components/AvalonEditor/UI/wsAvalonTextInput/wsAvalonTextInput"),
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      selectedItemIndex : null ,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
      coursesSelect : [],
    }
  },
  computed : {
    plansAccordionItems() {
      let items = []
      if ( this.selectedCourse.payment_plans_select && this.selectedCourse.payment_plans_select.length > 0 ) {
        this.selectedCourse.payment_plans_select.forEach(el => {
          items.push({
            name : el.text,
            value : el.value,
            description : el.description,
            img : el.img,
          })
        })
      }
      return items
    },
    selectedCourse() {
      if ( !this.block.config.course ) {
        return {}
      }
      return this.coursesSelect.find(el => el.value === this.block.config.course ) || {}
    },
    imageAccordeonItems() {
      return [{name : this.$t('Image') , value : 'image'}]
    },
    itemsPerPageSelect() {
      return [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 }
      ]
    },
    buttonActionsSelect() {
      return [
        { text : this.$t('OpenDetailsSidebar') , value : "sidebar" },
        { text : this.$t('OpenDetailsDialog') , value : "dialog" },
        { text : this.$t('OpenLink') , value : "link" },
        { text : this.$t('RegisterOnCourse') , value : "course" },
      ]
    },
    sliderContentSelect() {
      return [
        { text: this.$t('TitleAndDetails'), value: 'heading' },
        { text: this.$t('Content'), value: 'items' },
        { text: this.$t('EditItem'), value: 'edit_item', hidden: true}
      ]
    },

    selectedDesignName() {
      if ( !this.block.config.design ) {
        return this.designSelect[0].text
      }
      let design = this.designSelect.find(el => el.value === this.block.config.design)

      if (!design ) {
        return this.designSelect[0].text
      }

      return design.text

    },
    designSelect() {
      return [
        { text : this.$t('Style') + ' 1' , value : 'style_1' },
        { text : this.$t('Style') + ' 2' , value : 'style_2' }
      ]
    }
  },
  methods : {
    ...mapActions('courses',['ADD_PAYMENT_PLAN_AVALON']),

    async editPlanDescription(value , item) {
      let data = {
        uuid : item.value,
        description : value,
        lang : this.$store.state.avalon.selectedLang
      }
      let result = await this.ADD_PAYMENT_PLAN_AVALON(data)
      if ( !result) {
        this.ERROR()
      }

      this.$store.state.avalon.blockPricingUpdateTrigger++
    },
    async editPlanImage(value , item) {
      let data = {
        uuid : item.value,
        img : value
      }
      let result = await this.ADD_PAYMENT_PLAN_AVALON(data)
      if ( !result) {
        this.ERROR()
      }

      this.$store.state.avalon.blockPricingUpdateTrigger++
    },
    addImage() {
      this.block.config.elements.push( { img : null })
      this.updateSettings()
    },
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>