<template>
<v-sheet color="transparent" >
  <!--  No footer Placeholder-->
  <v-sheet :color="wsLIGHTCARD" v-if="!block" class="pa-6">

    <h5 :style="`color : ${wsACCENT}`"
          style="max-width: 434px"
          class="font-weight-regular mx-auto text-center"
    >
      {{ $t('avalon.footer.blank') }}
    </h5>

    <div class="d-flex justify-center mt-4">
      <ws-button
          @click="chooseNewFooter"
          label="avalon.footer.create"
          class="mx-auto"
          :block="MOBILE_VIEW"
      />
    </div>

  </v-sheet>
  <!--  Footer Content -->
  <blockEditor
      v-else
      footer
      :block-id="block.id"
      :elements="elements"
      @delete="$emit('delete' , block.id)"
      @newElement="elements.push($event)"
      :key="'footer'"
  />
</v-sheet>
</template>

<script>
import blockEditor from "@/components/AvalonEditor/Editor/blocks/BlockEditor";
import {mapActions, mapState} from "vuex";
export default {
  name: "avalonFooterEditor",
  components : {
    blockEditor
  },
  props : {
    triggerUpdate : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      block : null,
      elements : [],
      trigger : 0,
    }
  },
  watch : {
    triggerUpdate() {
      this.initPage()
    },
    selectedLang() {
      this.initPage()
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang'])
  },
  methods : {
    ...mapActions('avalon',['GET_FOOTER_DRAFT']),
    addEmptyFooter() {
      let data = { number : null, type : 'blank' }
      this.$emit('add' , data)
    },
    chooseNewFooter() {
      let data = { number : null, type : 'choose' }
      this.$emit('add' , data)
    },
    async initPage() {
      let result = await this.GET_FOOTER_DRAFT()
      if ( !result ) {
        this.block = null
        this.elements = []
        return
      }
      this.block = result.block
      this.elements = result.elements
      this.trigger++
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>