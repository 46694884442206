<template>

  <v-sheet
      :style="`border : 1px solid ${wsBACKGROUND}`"
      class="wsRoundedLight py-4 mb-3"
      :color="child ? wsLIGHTCARD : null"
      width="100%"
  >


    <div class="d-flex align-center justify-space-between" >

      <v-icon class="ml-2 mb-4" :color="wsACCENT" >mdi-drag-vertical</v-icon>

      <v-row no-gutters>
        <v-col cols="6" class="pr-5 pl-3">
          <ws-text-field
              @change="editMenuItem"
              :placeholder="$t('EnterName')"
              :label="$t('Name')"
              v-model="item.text"
          />
          <ws-check-box
              @input="editMenuItem"
              v-model="item.open_in_new_window"
              class="ml-n2 mt-2"
              :small="false"
              :label="$t('OpenInWindow')"
              :color="wsACCENT"
              :text-color="wsDARKER"
              :disabled="item.children.length > 0"
          />
        </v-col>
        <v-col cols="6">

          <ft-select
              @input="handlePageSelect"
              :items="menuPagesSelect"
              :disabled="item.children.length > 0"
              no-activator
          >
              <ws-text-field
                  @change="handleValueChange"
                  @focus="initialValue=itemValue"
                  width="100%"
                  class="mt-n11"
                  v-model="itemValue"
                  :placeholder="$t('EnterLinkOrPage')"
                  :label="$t('Link')"
                  append-icon="mdi-menu-down"
                  :disabled="item.children.length > 0"
              />

          </ft-select>


          <h5
              v-if="!child"
              @click="$emit('open-submenu-edit' , item.value)"
              class="mt-n7 pointer  linkHover" :style="`color : ${wsACCENT}`">
            {{ $t(item.children.length === 0 ? 'AddSubmenuItems' : 'EditSubmenuItems' ) }}
            <span v-if="item.children.length > 0" >({{ item.children.length }})</span>
          </h5>

        </v-col>


      </v-row>

      <v-btn @click.stop="deleteMenuItem" :color="wsACCENT" icon class="mx-3 mb-4">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>

    </div>

  </v-sheet>


</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "mainMenuItem",
  props : {
    item : {
      type : Object,
      default() { return {}}
    },
    child : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      edit : false,
      itemValue : null,
      initialValue : null,
      checkUrl : true
    }
  },
  computed : {
    ...mapState('avalon' ,  [ 'selectedLang' ]  ),

    menuPagesSelect() {
      let items = []
      this.CURRENT_LANG_PAGES.forEach(page => {
        if ( page.is_published ) {
          items.push(
              {
                text: page.text,
                value : page.value,
                icon: 'mdi-file',
              },
          )
        }

      })

      this.$store.state.avalon.coursesSelect.forEach(course => {

        let page = ''
        if ( course.page && Object.keys(course.page) && Object.keys(course.page).length > 0 ) {
          page = course.page[this.selectedLang] || course.page[Object.keys(page)[0]]
        }
        if ( page ) {
          items.push(
              {
                text: course.text,
                value : page,
                icon: 'mdi-school-outline',
              },
          )
        }

      })

      return items
    },

    itemTypes() {
      let items =  [
        { text : this.$t('Page')      , value : 'page'    , icon : 'mdi-file'},
        { text : this.$t('Link')      , value : 'link'    , icon : 'mdi-link'},
      ]
      if ( !this.child ) {
        items.push({ text : this.$t('Submenu')   , value : 'submenu' , icon : 'mdi-menu' })
      }

      return items
    }
  },
  methods : {
    ...mapActions('avalon' , [
        'ADD_MENU_ITEM' ,
        'EDIT_MENU_ITEM',
        'DELETE_MENU_ITEM'
    ]),

    handlePageSelect( value) {
      let page = this.menuPagesSelect.find(el=> el.value === value)
      this.itemValue = page.text
      this.item.type = 'page'
      this.item.page = value
      this.editMenuItem()
    },
    handleValueChange(value) {

      if (  value === this.initialValue ) {
        return
      }

      // Regular expression to test if string is a URL

      const urlRegex = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
      var hashRegex = /^#\d{5}$/;
      value = value.trim()
      if ( !value ) {
        this.item.type = 'link'
        this.item.url = null
        this.itemValue = null
      } else if( urlRegex.test(value)) {
        this.itemValue = value
        this.item.type = 'link'
        this.item.url = value
        this.editMenuItem()
      } else if ( hashRegex.test(value) ) {
        this.item.type = 'block'
        this.item.url = null
        this.itemValue = value
        this.item.block = value
        this.editMenuItem()
      }
      else {
        this.notify(this.$t('PleaseEnterValidLink') , 'warning')
      }

      this.initialValue = null

    },
    addMenuItem() {
      this.ADD_MENU_ITEM(this.item.value)
    },
    deleteMenuItem() {
      this.DELETE_MENU_ITEM(this.item)
    },
    startEdit() {

      this.edit = true
      this.$nextTick(() => {
        let text = this.$refs.text
        let end = text.value.length || 1
        text.setSelectionRange(end,end)
        text.focus()
      })
    },

    async editMenuItem() {
      let data = JSON.parse(JSON.stringify(this.item))
      data.lang = this.selectedLang
      let result = await this.EDIT_MENU_ITEM(data)
      if ( !result ) {
        return
      }
      let index = this.$store.state.avalon.mainMenu.findIndex(el => el.value === result.value)
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.mainMenu[index] = result
      this.$store.state.avalon.mainMenu = this.COPY(this.$store.state.avalon.mainMenu)


    },

  },
  beforeMount() {
    if ( this.item.type === 'page' ) {
      this.initialValue = this.item.page_name
      this.itemValue =  this.item.page_name

    } else if ( this.item.type === 'block' ) {
      this.itemValue = this.item.block

    } else {
      this.itemValue = this.item.url
    }
  }
}
</script>

<style scoped>

</style>