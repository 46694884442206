<template>

  <VueDraggableResizable
      v-if="width"
      :w="width || 1" :h="height || 0" :x="x || 0" :y="y || 0" :z="z ||0"
      :draggable="false"
      :resizable="false"
  >
    <v-sheet
        color="transparent"
        light
        class="fill-height" >

      <certificate-text-view
          v-if="element.type === 'text' "
          :element="element"
          :certificate-record="certificateRecord"
      />

      <view-image
          v-if="element.type === 'image' "
          :element="element"
      />

      <view-rectangle
          v-if="element.type === 'rectangle' "
          :element="element"
      />
      <view-line
          v-if="element.type === 'line' "
          :element="element"
      />
      <certificate-q-r-code
          v-if="element.type === 'qrcode' "
          :value="element"
          :certificate-record="certificateRecord"
      />


    </v-sheet>
  </VueDraggableResizable>


</template>

<script>
import viewRectangle from "@/components/AvalonEditor/View/elements/viewRectangle";
import viewLine from "@/components/AvalonEditor/View/elements/viewLine";
import certificateTextView from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateTextView";
import viewImage from "@/components/AvalonEditor/View/elements/viewImage";
import certificateQRCode from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateQRCode";
import VueDraggableResizable from 'vue-draggable-resizable'
export default {
  name: "zeroElement",
  components: {
    certificateTextView,
    viewImage,
    viewRectangle,
    viewLine,
    certificateQRCode,
    VueDraggableResizable
  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    certificateRecord : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      x : 0,
      y : 0,
      z : 0,
      width : 0,
      height : 15,
    }
  },
  methods : {
    initElement() {

      this.x = this.element.x1
      this.y = this.element.y1
      this.z = this.element.z
      this.width = this.element.x2 - this.element.x1
      this.height = this.element.y2 - this.element.y1
    },
  },
  mounted() {
    this.initElement()
  }
}
</script>

<style scoped>

</style>