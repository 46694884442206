<template>

  <v-sheet
      class="fill-height d-flex align-center "
      :class="[
                { 'align-center' :  config.valign === 'center'  },
                { 'align-baseline' :  !config.valign || config.valign === 'top'  },
                { 'align-end' :  config.valign === 'bottom'  },
                { 'justify-center' : config.align === 'center' },
                { 'justify-end' : config.align === 'right' },
                { 'justify-start' : config.align === 'left' }
            ]"
      :style="elementStyle"
      :color="element.config && element.config.has_background ? GET_AVALON_COLOR(config.background_color) : 'transparent'"

  >
    <div
        v-html="text"
        :style="textStyle"
        style="white-space : pre-line !important;"
    />
  </v-sheet>

</template>

<script>

export default {
  name: "viewButton",
  components : {},
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    certificateRecord : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {


    text() {

      if ( !this.element.config.is_dynamic) {
        return this.element.text
      }
      if ( this.certificateRecord.user_id ) {
        return this.dynamicText
      }
      return this.dynamicTextTypeName

    },
    dynamicTextTypeName() {
      if ( !this.config.content_type ) {
        return ''
      }
      let text = this.$t('DynamicText')
      let type = this.dynamicTextTypeSelect.find(el => el.value === this.config.content_type )
      if ( type ) {
        text = type.text
      }
      return `{${text}}`
    },
    dynamicText() {
      if ( !this.certificateRecord.user_id) {
        return
      }
      if ( !this.config.content_type ) {
        return ''
      }
      switch(this.config.content_type) {
        case 'student_name' : return this.certificateRecord.user_name
        case 'date' : return  this.TIME_YMD( this.certificateRecord.date)
        case 'course_name' : return this.certificateRecord.course_name
        case 'business_name' : return this.certificateRecord.business_name
        case 'result' : return this.certificateRecord.result
        case 'certificate_number' : return this.certificateRecord.number
      }
      let text = this.$t('DynamicText')
      let type = this.dynamicTextTypeSelect.find(el => el.value === this.config.content_type )
      if ( type ) {
        text = type.text
      }
      return `{${text}}`
    },
    dynamicTextTypeSelect() {
      return [
        { text : this.$t('SchoolName') , value : 'business_name' },
        { text : this.$t('StudentName') , value : 'student_name' },
        { text : this.$t('Date') , value : 'date' },
        { text : this.$t('CourseName') , value : 'course_name' },
        { text : this.$t('Result') , value : 'result' },
        { text : this.$t('CertificateNumber') , value : 'certificate_number' },
      ]
    },
    textStyle() {

      let style = ''

      if ( this.config.font_size ) {
        style += `font-size : ${this.config.font_size}px;`
      }
      if ( this.config.color ) {
        style += `color : ${this.config.color};`
      }
      if ( this.config.strong ) {
        style += `font-weight : 700;`
      }
      if ( this.config.align ) {
        style += `text-align : ${this.config.align};`
      }

      return style
    },

    config() {
      return this.element.config || {}
    },
    elementStyle() {
      let style = ''

      if ( !this.element.config ) {
        return ''
      }
      if ( this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px; `
      }
      if ( this.element.config.padding_top) {
        style += `padding-top : ${this.element.config.padding_top}px;  `
      }
      if ( this.element.config.padding_bottom) {
        style += `padding-bottom : ${this.element.config.padding_bottom}px;  `
      }
      if ( this.element.config.padding_left) {
        style += `padding-left : ${this.element.config.padding_left}px;  `
      }
      if ( this.element.config.padding_right) {
        style += `padding-right : ${this.element.config.padding_right}px;  `
      }

      if ( this.element.config.is_outlined ) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important ;`
      }

      return style
    }

  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>