<template>

  <!-- Layout Shadow -->
  <v-sheet
      ref="grid"
      :style="layoutStyle"
      :color="element.active ? wsBACKGROUND : 'transparent'"
      style="opacity: 0.5"
      class="fill-height" >

  </v-sheet>

</template>

<script>
export default {
  name: "zeroElement",
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    config : {
      type : Object,
      default() { return {} }
    },
    blockId : {},
    cellWidth : {},
    cellHeight : {}
  },
  watch : {
    cellWidth()  {
     this.synchronizeResizeHorizontal()
    },
    'config.gridGapVertical'() {
      this.synchronizeResizeVertical()
    },
    'config.rows'() {
      this.synchronizeResizeVertical()
    }
  },
  computed : {
    layoutStyle() {
      let x1 = !this.MOBILE_VIEW ? this.element.x1 : this.element.smX1
      let x2 = !this.MOBILE_VIEW ? this.element.x2 : this.element.smX2
      let y1 = !this.MOBILE_VIEW ? this.element.y1 : this.element.smY1
      let y2 = !this.MOBILE_VIEW ? this.element.y2 : this.element.smY2
      return  `grid-area : ${y1 || 1} / ${x1 || 1} / ${(y2 || 2)+1} / ${(x2 || 4)+1}; z-index : 10;`
    }
  },
  methods : {
    synchronizeResizeHorizontal() {

      let elementCellsWidth = (this.element.x2 - this.element.x1) + 1
      let width = (elementCellsWidth*this.cellWidth ) + ((elementCellsWidth-1) * this.config.gridGapHorizontal )
      let x = 0

      if ( this.element.x1 > 1 )  {
        x = (this.element.x1 - 1) * (this.cellWidth + this.config.gridGapHorizontal )
      }

      this.element.width = width
      this.element.x = x
      this.element = this.COPY(this.element)
      this.$emit('update' , this.COPY(this.element))

    },
    synchronizeResizeVertical() {

      let elementCellsHeight = (this.element.y2 - this.element.y1) + 1
      let height = (elementCellsHeight*this.cellHeight ) + ((elementCellsHeight-1) * this.config.gridGapVertical )
      let y = 0
      if ( this.element.y1 > 1 )  {
        y = (this.element.y1 - 1) * (this.cellHeight + this.config.gridGapVertical )
      }

      this.element.height = height
      this.element.y = y
      this.element = this.COPY(this.element)
      this.$emit('update' , this.COPY(this.element))

    }
  }

}
</script>

<style scoped>

</style>