var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(!(_vm.SM || _vm.MOBILE_VIEW) ? 'padding: 80px 80px' : 'padding : 24px')},[_c('portal',{attrs:{"disabled":_vm.recent.length,"to":"no_sidebar_news_portal"}},[_c('ws-link',{staticClass:"noUnderline",attrs:{"disabled":_vm.editorMode,"to":_vm.businessPublicLink(_vm.entity.return_page_alias ? ("page/" + (_vm.entity.return_page_alias)) : ''),"color":_vm.getColor('single_news_category')}},[_c('h4',{staticClass:"d-flex align-center pointer mb-12",style:(_vm.getTextCss('category'))},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.getColor('single_news_category')}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(_vm.entity.return_page_name || _vm.$t('Return'))+" ")],1)])],1),_c('div',{staticClass:"flex-row ",class:[{'d-flex' : !(_vm.SM || _vm.MOBILE_VIEW)}]},[_c('v-sheet',{staticClass:"flex-grow-1 mx-auto",attrs:{"color":"transparent","max-width":!_vm.recent.length ? 800 : 600,"width":"100%"}},[_c('v-sheet',{staticStyle:{"width":"100%"},attrs:{"color":"transparent"}},[_c('portal-target',{attrs:{"name":"no_sidebar_news_portal"}}),_c('h3',{style:(_vm.getTextCss('title'))},[_vm._v(" "+_vm._s(_vm.entity.title)+" ")]),_c('h4',{staticClass:"mt-6",style:(_vm.getTextCss('date'))},[_vm._v(" "+_vm._s(_vm.$time(_vm.entity.date).regularTime())+" ")]),_c('ws-text-viewer',{staticClass:"pb-6 mt-7",attrs:{"value":_vm.entity.content,"is-public":"","avalon":"","avalon-style":_vm.pageStyle,"video-height":"500"}})],1)],1),(_vm.recent.length)?_c('v-sheet',{style:(_vm.sidebarStyle),attrs:{"color":"transparent","width":!(_vm.SM || _vm.MOBILE_VIEW) ? 400 : null,"min-width":!(_vm.SM || _vm.MOBILE_VIEW) ? 400 : null}},[_c('h5',{class:[
              { 'mb-16' : !(_vm.SM || _vm.MOBILE_VIEW) },
              { 'mb-6' : (_vm.SM || _vm.MOBILE_VIEW) }
          ],style:(_vm.getTextCss('readMore'))},[_vm._v(" "+_vm._s(_vm.$t('ReadMore'))+" ")]),_vm._l((_vm.recent),function(item,index){return _c('ws-link',{key:index,attrs:{"to":_vm.businessPublicLink(("news/" + (item.alias))),"color":_vm.getColor('single_news')}},[_c('v-sheet',{staticClass:"mb-6",attrs:{"color":_vm.getColor('single_news_recentBg')}},[_c('ws-img',{attrs:{"src":item.img,"height":"200","horizontal-position":"center","vertical-position":"center"}}),_c('div',{class:[
              { 'pa-5' : !(_vm.SM || _vm.MOBILE_VIEW) },
              { 'pa-5' : (_vm.SM || _vm.MOBILE_VIEW) }

          ]},[_c('h5',{style:(_vm.getTextCss('recentDate'))},[_vm._v(" "+_vm._s(_vm.$time(item.date).regularTime())+" ")]),_c('h5',{staticClass:"mt-5",style:(_vm.getTextCss('recentTitle'))},[_vm._v(_vm._s(item.title)+" ")]),_c('h5',{staticClass:"mt-5 mr-2 d-flex align-center",style:(_vm.getTextCss('recentDetails')),on:{"click":function($event){return _vm.$emit('open' , item.uuid)}}},[_vm._v(" "+_vm._s(_vm.$t('MoreDetails'))+" "),_c('v-icon',{attrs:{"color":_vm.getColor('single_news_recentDetails'),"small":""}},[_vm._v("mdi-arrow-right")])],1)])],1)],1)})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }