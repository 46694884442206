<template>
  <div class="px-3">
    <!-- Navigation buttons-->
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex mb-5" v-if="config.elements && config.elements.length > config.items_per_page">
        <v-sheet
            @click="previousPage"
            :color=" CONFIG_PARAM_COLOR('styles' , this.config.style, 'ux_arrow_bg') +(!previousIsAvailable ? '66' : '') "
            :class="[{pointer : previousIsAvailable}]"
            :v-ripple="previousIsAvailable"
            class="d-flex justify-center align-center mr-2"
            style="border-radius: 50%"
            width="50"
            height="50"
        >
          <v-icon :color="CONFIG_PARAM_COLOR('styles' , this.config.style, 'ux_arrow') +(!previousIsAvailable ? '66' : '') "
          >
            mdi-arrow-left
          </v-icon>
        </v-sheet>
        <v-sheet
            @click="nextPage"
            :color="CONFIG_PARAM_COLOR('styles' , this.config.style, 'ux_arrow_bg') +(!nextIsAvailable ? '66' : '')  "
            :class="[{pointer : nextIsAvailable}]"
            :v-ripple="nextIsAvailable"
            class="d-flex justify-center align-center"
            style="border-radius: 50%"
            width="50"
            height="50"
        >
          <v-icon :color="CONFIG_PARAM_COLOR('styles' , this.config.style, 'ux_arrow') + (!nextIsAvailable ? '66' : '') "
          >
            mdi-arrow-right
          </v-icon>
        </v-sheet>
      </div>
    </div>
    <!-- Items Images-->
    <v-fade-transition mode="out-in">
      <v-row :key="currentPage" >
        <v-col
            v-for="(item , i) in itemsFiltered" :key="i"
            :cols="12"
            :md="12"
            :lg="columnsSizes[config.items_per_row || 3]"

        >
          <v-img
              v-if="item.img"
              :src="item.img"
              class="wsRoundedLight"
              :aspect-ratio="config.tiles_aspect_ratio || 1"
              transition="xxx"
          />
        </v-col>
      </v-row>
    </v-fade-transition>
    

  </div>
</template>

<script>
export default {
  name: "gallerySlider",
  props : {
    config : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      currentPage : 0,
      columnsSizes : {
        1 : 12,
        2 : 6,
        3 : 4,
        4 : 3,
        6 : 2,
        12 : 1
      }
    }
  },
  computed : {
    items() {
      return this.config.elements || []
    },

    itemsFiltered() {

      if (!this.config.elements || this.config.elements.length === 0 ) {
        return []
      }

      let minimumIndex = this.currentPage * this.config.items_per_page
      let maximumIndex = (this.currentPage+1) * this.config.items_per_page

      let items = this.config.elements
      items = items.filter((el, index) =>

          index >= minimumIndex && index < maximumIndex

      )

      return items
    },
    nextIsAvailable() {
      if ( !this.config.elements ) {
        return false
      }
      return this.config.elements.length > (this.currentPage*this.config.items_per_page + this.config.items_per_page )
    },
    previousIsAvailable() {
      return this.currentPage !== 0
    }
  },
  methods : {
    nextPage() {
      if ( !this.nextIsAvailable ) {
        return
      }
      this.currentPage++
    },
    previousPage() {
      if ( !this.previousIsAvailable ) {
        return
      }
      this.currentPage--
    }
  }
}
</script>

<style scoped>

</style>