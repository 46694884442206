<template>
  <div>

    <div style="position: relative">
      <v-sheet color="transparent" min-height="564" height="564" ref="wrapper" :style="wrapperStyle"  >
        <template v-for="(element) in elements">
          <certificate-zero-element-view
              style="position:absolute;"
              :ref="'layout_' + element.id"
              :element="element"
              :key="'layout_' + element.id"
              :active="vdrActive"
              :certificate-record="certificateRecord"
          />
        </template>
      </v-sheet>
    </div>

  </div>
</template>

<script>

import certificateZeroElementView
  from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateCanvasElementView";
import {mapActions, mapState} from "vuex";

export default {
  name: "BlockZero",
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    newElementTrigger : {
      type : Number,
      default : 0
    },
    certificateRecord : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
    certificateZeroElementView
  },
  data() {
    return {

      pauseResize : false,
      selectedElement : null,

      // rows : 10,
      // columns : 24,
      rowHeight : 24,
      gridGapVertical : 4,
      gridGapHorizontal : 4,
      vdrActive : false,

      displayResize : false,
      contentReady : false,

      x1 : 2,
      x2 : 5,
      y1 : 1,
      y2 : 2,

      dragging : false,

      width: 10,
      height: 10,
      x: 0,
      y: 0,

      colors : ['primary lighten-1' , 'red lighten-1' ,'blue lighten-1' ,'green lighten-1' ,'teal lighten-1' ,'pink lighten-1' ],

    }
  },
  computed : {
    ...mapState('avalon',['rowHeightChangeTrigger']),
    maxHeight() {

      if ( !Array.isArray(this.elements)) {
        return 0
      }
      if ( this.elements.length < 1 ) {
        return 0
      }

      let array = this.elements.map(el => el.y2)
      return Math.max(...array)
    },
    maxHeightSM() {

      if ( !Array.isArray(this.elements)) {
        return 0
      }
      if ( this.elements.length < 1 ) {
        return 0
      }

      if ( this.elements.length === 0 ) {
        return 0
      }
      let array = this.elements.map(el => el.smY2)
      return Math.max(...array)
    },
    rows() {
      return !this. SM ? (this.avalonBlockConfig(this.block, 'rows') || 12) : (this.avalonBlockConfig(this.block, 'rows_sm') || 12)
    },
    columns() {
      return this.avalonBlockConfig(this.block, 'columns') || 24
    },
    wrapperStyle() {

      let columnsValue = ''
      for ( let i = 0; i< this.columns ; i++) {
        columnsValue += '1fr '
      }

      let rowsValue = ''
      for ( let j = 0; j< this.rows ; j++) {
        rowsValue += `${this.rowHeight}px `
      }

      return '' +
          `position : relative;` +
          `display: grid;` +
          `transition: all 0.3s ease;` +
          `grid-gap: ${this.gridGapVertical}px ${this.gridGapHorizontal}px;` +
          `grid-template-columns : ${columnsValue}  ;` +
          `grid-template-rows : ${rowsValue} ;` +
          `width : 100% ;`

    },
    blockStyle() {
      let style = '';
      style += 'padding : 25px 50px'
      return style
    }
  },
  watch: {
    maxHeight(value) {
      this.$emit('maxHeight' , value)
      if ( value === this.rows ) {
        if (!this.block.config.rows ) {
          this.block.config.rows = this.rows
          this.block.config = Object.assign({} , this.block.config)
        }
        this.pauseResize = true
        setTimeout(()=> {
          this.block.config.rows++
          this.$store.state.avalon.rowHeightChangeTrigger++
          this.pauseResize = false

        },150)
      }
    },
    maxHeightSM(value) {
      this.$emit('maxHeightSM' , value)

      if ( value > this.block.config.rows_sm) {
        this.block.config.rows_sm = value
        this.block = Object.assign({} , this.block)
        this.$emit('updateBlock')
      }

      if ( value === this.block.config.rows_sm ) {
        if (!this.block.config.rows_sm ) {
          this.block.config.rows_sm = this.rows
          this.block.config = Object.assign({} , this.block.config)
        }
        this.pauseResize = true
        setTimeout(()=> {
          this.block.config.rows_sm++
          this.$store.state.avalon.rowHeightChangeTrigger++
          this.pauseResize = false
        },150)
      }
    },
    newElementTrigger() {
      this.initBlock()
    }
  },
  methods : {
    ...mapActions('avalonPage',[]),

    syncronizeResize(element) {
      if ( this.$refs['layout_'+ element.id].length === 0  ) {
        return
      }
      let contentBounds = this.$refs['layout_'+ element.id][0].$el.getBoundingClientRect()
      let wrapperBounds = this.$refs.wrapper.getBoundingClientRect()

      element.height = contentBounds.height
      element.width = contentBounds.width
      element.x = contentBounds.x - wrapperBounds.x
      element.y =  contentBounds.y - wrapperBounds.y

    },
    synchronizeGrid(element, x,y, width = null, height = null , ) {


      if ( !width) {
        width = element.width
      }
      if ( !height ) {
        height = element.height
      }

      let cellWidth = this.$refs.cell[1].getBoundingClientRect().width
      let cellHeight = this.$refs.cell[1].getBoundingClientRect().height
      let columnWidth = cellWidth  + this.gridGapHorizontal
      let rowHeight = cellHeight + this.gridGapVertical

      this.$emit('cell_dimensions', {
        width : cellWidth,
        height : cellHeight,
        gapHorizontal : this.gridGapHorizontal,
        gapVertical : this.gridGapVertical,
      })

      if ( !this. SM ) {
        element.x1 =  Math.floor( x/columnWidth ) + (x%columnWidth < Math.floor(columnWidth * 0.5) ? 1 : 2)
        element.x2 =  Math.floor( (x+width)/columnWidth ) + (  (x+width)%columnWidth > Math.floor(columnWidth * 0.5) ? 1 : 0)
        element.y1 =  Math.floor( y/rowHeight ) + (y%rowHeight < Math.floor(rowHeight * 0.5) ? 1 : 2)
        element.y2 =  Math.floor( (y+height)/rowHeight ) + (  (y+height)%rowHeight > Math.floor(rowHeight * 0.5) ? 1 : 0)
      } else {
        element.smX1 =  Math.floor( x/columnWidth ) + (x%columnWidth < Math.floor(columnWidth * 0.5) ? 1 : 2)
        element.smX2 =  Math.floor( (x+width)/columnWidth ) + (  (x+width)%columnWidth > Math.floor(columnWidth * 0.5) ? 1 : 0)
        element.smY1 =  Math.floor( y/rowHeight ) + (y%rowHeight < Math.floor(rowHeight * 0.5) ? 1 : 2)
        element.smY2 =  Math.floor( (y+height)/rowHeight ) + (  (y+height)%rowHeight > Math.floor(rowHeight * 0.5) ? 1 : 0)
      }

      this.$nextTick(()=> {
        element = JSON.parse(JSON.stringify(element))
      })


    },
    handleResize({x, y, width, height , noEdit}, element) {

      if (this.pauseResize) {
        return
      }

      if ( !noEdit ) {
        this.vdrActive = true
        element.active = true
      }

      element.x = x
      element.y = y
      element.width = width
      element.height = height

      this.synchronizeGrid(element, x,y,width,height , )

    },
    handleDrag({x, y} , element) {
      this.vdrActive = true
      element.active = true
      element.x = x
      element.y = y

      this.synchronizeGrid(element, x,y);

    },

    // technical
    cellStyle(row,column) {
      return `grid-area : ${row} / ${column} / ${row} / ${column} ; `
    },
    initElement(element) {
      element.width = 1;
      element.height = 1;
      element.x = 0;
      element.y = 0;
      element.z = 25;
      this.syncronizeResize(element);
    },
    initBlock() {

      this.$emit('maxHeight' , this.maxHeight)
      this.$emit('maxHeightSM' , this.maxHeightSM)


      this.contentReady = false
      setTimeout(() => {
        this.$nextTick( () => {


          let cellWidth = this.$refs.cell[1].getBoundingClientRect().width
          let cellHeight = this.$refs.cell[1].getBoundingClientRect().height

          this.$emit('cell_dimensions', {
            width : cellWidth,
            height : cellHeight,
            gapHorizontal : this.gridGapHorizontal,
            gapVertical : this.gridGapVertical,
          })

          this.elements.forEach((element) => {
            element.width = 1;
            element.height = 1;
            this.syncronizeResize(element);
          },1000)
          this.elements = Object.assign({},this.elements)
          this.$nextTick( () => {
            this.contentReady = true
          })

        } )
      });


    }
  },

  mounted() {
    this.initBlock()

  },
}
</script>

<style scoped>
</style>