<template>


  <div v-if="!($store.state.avalon.previewMode || $store.state.avalon.sideMenu)">
    <div :style="headerStyle" style="z-index : 1000 !important"  >
      <v-expand-x-transition>
        <avalon-header-settings
            @close="displaySettings = false "
            v-if="displaySettings"
            v-model="displaySettings"
        />
      </v-expand-x-transition>

      <v-hover v-slot="{hover}">

        <v-sheet :color="headerColor"   width="100%" >
          <div style="position:relative; width: 100%">

            <!-- Block Edit Buttons -->
            <v-fade-transition>
              <v-sheet
                  :color="wsACCENT + '66' "
                  v-if="(hover || edit) && editorMode"
                  class="d-flex justify-center align-center fill-height"
                  style="position:absolute ; width: 100%;">
                <!-- Add Element  -->
                <div style="z-index: 999">
                  <ws-button
                      label="EditMenu"
                      @click="displaySettings = true"
                  />
                </div>
              </v-sheet>
            </v-fade-transition>

            <!-- Header Designs Desktop -->
            <div v-if="!(SM || MOBILE_VIEW)" style="pointer-events: none">
              <header-design-default
                  v-if="!header.config.design || header.config.design === 'default'"
                  preview
              />
              <header-design-dream
                  v-else-if="header.config.design === 'dream_style' "
                  :preview="preview"
              />


            </div>

            <!-- Header Designs Mobile -->
            <div v-else style="pointer-events: none">
              <headerDesignDefaultMobile preview />
            </div>


          </div>


        </v-sheet>

      </v-hover>

    </div>
  </div>
  <view-header
      v-else
      @scroll-to-block="$emit('scroll-to-block' , $event )"
  />


</template>

<script>

import {mapActions, mapState} from "vuex";
import avalonHeaderSettings from "@/components/AvalonEditor/Dialogs/avalonHeaderSettings";
import viewHeader from "@/components/AvalonEditor/View/header/viewHeader";
import headerDesignDefault from "@/components/AvalonEditor/View/header/UI/headerDesignDefault";
import headerDesignDefaultMobile from "@/components/AvalonEditor/View/header/UI/headerDesignDefaultMobile";
import headerDesignDream from "@/components/AvalonEditor/View/header/UI/headerDesignDream";

export default {
  name: "avalonHeaderEditor",
  components : {
    avalonHeaderSettings,
    viewHeader,
    headerDesignDefault,
    headerDesignDefaultMobile,
    headerDesignDream
  },
  data() {
    return {
      showMobileMenu : false,
      edit : false,
      displaySettings : false,
      blockElements : [],
      cellDimensions : {},
      entity : {},
    }
  },
  computed : {
    ...mapState('avalon',['editorMode' , 'header' , 'sideMenu', 'mainMenu' , 'selectedLang' , 'updateHeaderTrigger' , 'scrollPosition']),

    buttonTextColor() {
      if ( this.header.config.style && this.header.config.style_button ) {

        return this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.header.config.style ,
            this.header.config.style_button === 'primary'
                ? 'button_text'
                : 'button_bg'
        )
      }
      return this.GET_AVALON_COLOR(this.header.config.button_text_color)
    },
    layout() {
      if ( !this.header.config.layout ) {
        return 1
      }
      return this.header.config.layout
    },

    headerStyle() {

      if ( this.header.config.sticky ) {
        let style = `position : fixed; z-index : 300 ;  top : 43 ; left : ${this.sideMenu ? 280 : 0}px; right : 0;`
        if ( this.MOBILE_VIEW ) {
          style += `width : ${this.MOBILE_WIDTH}; margin-left : auto; margin-right : auto;`
        }
        if ( this.header.config.overlap && this.header.config.blur ) {
          style += `backdrop-filter: blur(${this.header.config.blur}px);
                   -webkit-backdrop-filter: blur(${this.header.config.blur}px);`
        }
        return style
      }

      return 'z-index : 300 ; position : relative '

    },
    headerColor() {
      if ( this.header.config.overlap ) {
        let color = this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )

        let opacity = (Math.round(this.header.config.overlay_opacity*2.55) ).toString(16)
        if (opacity.length === 1 ) {
          opacity = '0' + opacity
        }
        if ( this.header.config.overlay_opacity === 0 ) {
          opacity = '00'
        }
        return color + opacity  ;
      }
      return this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )
    },
    headerWidth() {

      if ( this.header.config.width ) {
        return this.header.config.width
      }

      if (this.header.config.full_width) {
        return '100%'
      }

      if (this.avalonConfig.header.full_width) {
        return '100%'
      }

      if ( this.avalonConfig.header.width ) {
        return this.avalonConfig.header.width
      }

      return ''
    },
    style() {
      let style = ""

      if ( this.avalonBlockConfig(this.block, 'vertical_padding') ) {

        style += `padding-top : ${this.block.config.padding_top}px;`
        style += `padding-bottom : ${ this.avalonConfig.block.padding_bottom}px;`

      }

      return style
    },
    blockWidth() {
      if ( this.avalonBlockConfig(this.block, 'full_width')) {
        return '100%'
      }
      if ( this.avalonBlockConfig(this.block, 'width') ) {
        return this.avalonBlockConfig(this.block, 'width').toString()
      }
      return '100%'
    },
  },
  watch : {
    selectedLang() {
      this.updateMenu()
    }
  },
  methods : {
    // technical
    ...mapActions('avalon' , [
        'GET_MAIN_MENU_DRAFT'
    ]),

    async updateMenu() {
      let result = await this.GET_MAIN_MENU_DRAFT(this.selectedLang)
      if ( !result ) {
        return
      }
      this.$store.state.avalon.mainMenu = result.main_menu

    },
    layoutStyle(hover ) {
      let style = 'transition : all 0.3s ease;'

      style += `background-color : ${ this.avalonConfig.colors.background_main }` + ';'
      if ( !this.header.config ) {
        this.$store.state.avalon.header.config = {}
      }

        style += `height : ${this.header.config.height || 50}px`



      if ( (hover || this.edit) && this.editorMode ) {
        style += `-webkit-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `-moz-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
      }

      return style
    },

  },
  mounted() {
  },

}
</script>

<style scoped>

</style>