<template>

  <!-- Content Settings-->
  <div v-if="content">
    <ws-text-field
        @change="updateSettings"
        v-model="block.config.title"
        :label="$t('Title')"
        :placeholder="$t('EnterBlockTitle')"
        clearable
    />
    <ws-text-field
        class="mt-7"
        @change="updateSettings"
        v-model="block.config.subtitle"
        :placeholder="$t('EnterBlockSubtitle')"
        :label="$t('Subtitle')"
        clearable
    />

    <h4 class="mt-6" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Align') }}</h4>
    <ws-button-group
        v-model="block.config.heading_align"
        @input="updateSettings"
        :items="headingAlignSelect"
        class="mb-2 mt-2"
    />
  </div>
  <!-- Design Settings-->
  <div v-else-if="format">
    <h4 style="font-size: 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.vertical_padding') }}</h4>
    <!-- Auto Vertical paddings -->
    <ws-button-group
        v-model="autoPadding"
        @input="changePadding($event)"
        :items="paddingsSelect"
        class="mb-5 mt-2"
    />
    <!-- Manual Vertical paddings -->
    <div v-if="!MOBILE_VIEW">

      <a-parameter-slider
          @change="updateSettings(); checkAutoPadding();"
          v-model="block.config.padding_top"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="format.padding.top"
          light
          min="0"
          max="350"
          class="mt-2"

      />
      <a-parameter-slider
          @change="updateSettings(); checkAutoPadding();"
          @input="$emit('editing' , true)"
          v-model="block.config.padding_bottom"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="format.padding.bottom"
          min="0"
          max="350"
          light
          class="mt-4"
      />

    </div>
    <div v-if="MOBILE_VIEW">

      <a-parameter-slider
          @change="updateSettings(); checkAutoPadding(); "
          @input="$emit('editing' , true)"
          v-model="block.config.padding_top_sm"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="format.padding.top"
          min="0"
          max="350"
          class="mt-2"
      />
      <a-parameter-slider
          @change="updateSettings(); checkAutoPadding();"
          @input="$emit('editing' , true)"
          v-model="block.config.padding_bottom_sm"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="format.padding.bottom"
          min="0"
          max="350"
          class="mt-4"
      />

    </div>

    <div v-if="!MOBILE_VIEW" class="mt-7">
      <a-parameter-switch
          @input="updateSettings"
          v-model="block.config.full_width"
          label="format.full_width"
          class="mt-2"
          light
      />
      <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />
    </div>

    <!-- Visibility Desktop/Mobile -->
    <a-parameter-switch
        v-if="MOBILE_VIEW"
        @input="updateSettings"
        v-model="block.config.visibility_desktop"
        class="mt-2"
        light
    />
    <a-parameter-switch
        v-if="!MOBILE_VIEW"
        @input="updateSettings"
        v-model="block.config.visibility_mobile"
        label="visibility.mobile"
        class="mt-2"
        light
    />
    <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />

  </div>


</template>

<script>
export default {
  name: "blockGeneralSettings",
  components : {
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    },
    content : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      autoPadding : 'm',
      headingAlignSelect : [
        { icon : 'mdi-format-align-left' , value : 'left' },
        { icon : 'mdi-format-align-center' , value : 'center' },
        { icon : 'mdi-format-align-right' , value : 'right' },
      ]
    }
  },
  computed : {
    paddingsSelect() {
      return [
        { text : 'M' , value : 'm' },
        { text : 'L' , value : 'l' },
        { text : 'XL' , value : 'xl' }
      ]
    },
  },
  watch : {
    MOBILE_VIEW() {
      this.checkAutoPadding()
    },
  },
  methods : {
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    changePadding(size) {
      let sizes = {
        m : 10,
        l : 75,
        xl : 150,
      }
      if ( !this.MOBILE_VIEW ) {
        this.block.config.padding_top = sizes[size]
        this.block.config.padding_bottom = sizes[size]
      } else {
        this.block.config.padding_top_sm = sizes[size]
        this.block.config.padding_bottom_sm = sizes[size]
      }
      this.updateSettings()
    },
    checkAutoPadding() {
      if ( !this.MOBILE_VIEW ) {
        if ( this.block.config.padding_top === 10 && this.block.config.padding_bottom === 10 ) {
          this.autoPadding = 'm'
        }
        else if ( this.block.config.padding_top === 75 && this.block.config.padding_bottom === 75 ) {
          this.autoPadding = 'l'
        }
        else if ( this.block.config.padding_top === 150 && this.block.config.padding_bottom === 150 ) {
          this.autoPadding = 'xl'
        }
        else {
          this.autoPadding = null
        }
      } else {
        if ( this.block.config.padding_top_sm === 10 && this.block.config.padding_bottom_sm === 10 ) {
          this.autoPadding = 'm'
        }
        else if ( this.block.config.padding_top_sm === 75 && this.block.config.padding_bottom_sm === 75 ) {
          this.autoPadding = 'l'
        }
        else if ( this.block.config.padding_top_sm === 150 && this.block.config.padding_bottom_sm === 150 ) {
          this.autoPadding = 'xl'
        }
        else {
          this.autoPadding = null
        }
      }
    },
  },
  mounted() {
    this.checkAutoPadding();
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>