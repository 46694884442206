<template>
  <div
       :style="!(SM || MOBILE_VIEW) ? 'padding: 80px 80px' : 'padding : 24px'"
  >

    <portal :disabled="recent.length" to="no_sidebar_news_portal">
      <ws-link
          :disabled="editorMode"
          :to="businessPublicLink(entity.return_page_alias ? `page/${ entity.return_page_alias}` : '')"
          class="noUnderline"
          :color="getColor('single_news_category')"
      >

        <h4
            :style="getTextCss('category')"
            class="d-flex align-center pointer mb-12"
        >
          <v-icon
              class="mr-3"
              :color="getColor('single_news_category')"
          >
            mdi-arrow-left
          </v-icon>
          {{ entity.return_page_name || $t('Return') }}
        </h4>
      </ws-link>
    </portal>

    <div class="flex-row "
         :class="[{'d-flex' : !(SM || MOBILE_VIEW)}]"
    >

      <v-sheet
          color="transparent"
          class="flex-grow-1 mx-auto"
          :max-width="!recent.length ? 800 : 600"
          width="100%"
      >
        <v-sheet color="transparent"  style="width: 100%">

          <portal-target name="no_sidebar_news_portal"></portal-target>

          <h3 :style="getTextCss('title')" >
            {{  entity.title  }}
          </h3>

          <h4  class="mt-6"
               :style="getTextCss('date')"
          >
            {{ $time(entity.date).regularTime() }}
          </h4>

          <ws-text-viewer
              :value="entity.content"
              is-public
              class="pb-6 mt-7"
              avalon
              :avalon-style="pageStyle"
              video-height="500"
          />


        </v-sheet>
      </v-sheet>

      <v-sheet
          v-if="recent.length"
          color="transparent"
          :width="!(SM || MOBILE_VIEW) ? 400 : null"
          :style="sidebarStyle"
          :min-width="!(SM || MOBILE_VIEW) ? 400 : null"
      >

        <h5 :style="getTextCss('readMore')"
            :class="[
                { 'mb-16' : !(SM || MOBILE_VIEW) },
                { 'mb-6' : (SM || MOBILE_VIEW) }
            ]"
        >
          {{ $t('ReadMore') }}
        </h5>
        <ws-link
            v-for=" (item , index) in recent"
            :key="index"
            :to="businessPublicLink(`news/${item.alias}`)"
            :color="getColor('single_news')"
        >
        <v-sheet
            :color="getColor('single_news_recentBg')"
            class="mb-6"
        >

          <ws-img
              :src="item.img"
              height="200"
              horizontal-position="center"
              vertical-position="center"
          />

          <div :class="[
                { 'pa-5' : !(SM || MOBILE_VIEW) },
                { 'pa-5' : (SM || MOBILE_VIEW) }

            ]">

            <h5 :style="getTextCss('recentDate')">
              {{ $time(item.date).regularTime() }}
            </h5>

            <h5 :style="getTextCss('recentTitle')"
                class="mt-5">{{ item.title }}
            </h5>


            <h5 @click="$emit('open' , item.uuid)"
                :style="getTextCss('recentDetails')"
                class="mt-5 mr-2 d-flex align-center">
              {{ $t('MoreDetails') }}
              <v-icon
                  :color="getColor('single_news_recentDetails')"
                  small>mdi-arrow-right</v-icon>
            </h5>

          </div>


        </v-sheet>
        </ws-link>

      </v-sheet>
    </div>


  </div>
</template>

<script>
export default {
  name: "newsDesignDefault",
  props : {
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    recent : {
      type : Array,
      default() {
        return []
      }
    }
  },
  computed : {
    pageStyle() {
      return this.entity.style || 'light_1'
    },
    sidebarStyle() {
      let style = ""

      if (!(this.SM || this.MOBILE_VIEW)) {

        style += `margin-left: 32px; padding-left: 40px;`
        style += `border-left : 1px solid ${this.getColor('single_news_recentBg')} !important;`

      }

      return style
    }
  },
  methods : {
    getTextCss(param) {
      return this.CONFIG_TEXT_CSS(this.pageStyle , 'single_news' , param,null,null,this.EMPTY_BLOCK_PLACEHOLDER,this.WINDOW_WIDTH)
    },
    getColor(param) {
      return this.BLOCK_COLOR_PARAM(this.EMPTY_BLOCK_PLACEHOLDER ,  param, this.pageStyle)
    }
  }
}
</script>

<style scoped>

</style>