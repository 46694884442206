<template>

      <v-sheet @mousedown="dragStart"
               class="d-flex" color="transparent"
               :class="MOBILE_VIEW ? 'resize-handle-sm' : 'resize-handle' "
      >
        <vue-draggable-resizable
            ref="element_layout"
            :draggable="false"
            :resizable="false"
            :y="y"
            axis="y"
            :z="300"
            :w="30"
            :h="30"
            class="resize-cursor"
            :class="[{ 'grabbingCursor' : isDragging  }]"
        >
          <v-sheet
              :style="`border: 1px solid ${wsBACKGROUND} !important`"
              width="30" :color="isDragging ? (wsACCENT + '22') : 'white'" dark class="wsRoundedLight d-flex justify-center py-1">
            <v-icon :color="wsACCENT">
              mdi-unfold-more-horizontal
            </v-icon>
          </v-sheet>

        </vue-draggable-resizable>

      </v-sheet>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: "heightResizeHandle",
  props : {
    value : {
      type : Number,
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
    maxHeight : {
      type : Number,
      default : 12
    },
  },
  components : {
    VueDraggableResizable
  },
  data() {
    return {
      isDragging : false,
      startY : 0,
      initialY : 0,
      initialRows : 0,
      y : 0,
      timeout : null
    }
  },
  computed : {
    rowHeight() {
      let value = 24

      if ( this.EDITOR_FONT_COEFFICIENT(this.block.config.width) !== 1 && !this.MOBILE_VIEW ) {
        return value * this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      }

      return value

    },
    gridGapVertical() {
      let coefficient = this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      if ( this.MOBILE_VIEW  ) {
        return 4
      }
      let value = this.block.config.gridGapVertical || 4

      return value * coefficient
    },
  },
  methods : {
    handleDrag(event) {
      if ( !this.isDragging ) {
        return this.dragStop()
      }
      this.$emit('dragging' , true)
      let deltaY = event.clientY - this.startY


      let rows = parseInt(deltaY/(this.rowHeight+this.gridGapVertical))
      let newRows = parseFloat(rows) + parseInt(this.initialRows)
      if ( newRows !== 0 && newRows >= this.maxHeight  ) {
        this.$emit('input' , newRows)
        if ( this.timeout ) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.$emit('update')
          this.timeout = null
        } , 500)
      }

    },
    dragStart(event) {
      window.addEventListener('mouseup', this.dragStop);
      window.addEventListener('mousemove', this.handleDrag);
      this.isDragging = true
      this.vdrActive = true
      this.startY = event.clientY
      this.initialY = this.y
      this.initialRows = this.value
    },
    dragStop() {
      window.removeEventListener('mouseup', this.dragStop);
      window.removeEventListener('mousemove', this.handleDrag);
      this.$emit('dragging' , false)
      this.isDragging = false;
    },
  }
}
</script>

<style scoped>
.resize-handle {
  position: absolute;
  right: 50px;
  bottom: 50px;
  cursor:row-resize;
  user-select: none;
}
.resize-handle-sm {
  position: absolute;
  right: -20px;
  bottom: 50px;
  cursor:row-resize;
  user-select: none;
}
.resize-cursor {
  cursor:row-resize;
}
</style>