<template>
  <v-fade-transition mode="out-in">
    <v-sheet
        v-if="!loading"
        :color="CONFIG_PARAM_COLOR('styles' , pageStyle , 'bg' )"
        key="loading"
    >

      <v-sheet color="transparent" v-if="header.config.sticky" :height="SM ?  header.config.heightSM : header.config.height"/>


      <div :style="`width: 100%; max-width: ${!entity.design || entity.design === 'default' ? 800 : 1240}px; margin: 0 auto;` ">
        <news-design-default
            v-if="entity.design === 'second'"
            :entity="entity"
            :recent="recentNews"
        />

        <newsDesignSecond
            v-else-if="!entity.design || entity.design === 'default'"
            :entity="entity"
            :recent="recentNews"
        />
      </div>


    </v-sheet>
    <v-sheet
        v-else
        :color="loadingColor"
        class="fill-height d-flex justify-center align-center pa-16"
        style="height: 80vh !important"
        key="content"
    >
      <div style="width: 150px">
        <v-progress-linear
            indeterminate
            size="82"
            color="#ffffff66"
        />
      </div>
    </v-sheet>
  </v-fade-transition>


</template>

<script>
import {mapActions, mapState} from "vuex";
import newsDesignDefault from "@/components/AvalonEditor/View/news/newsDesignDefault.vue";
import newsDesignSecond from "@/components/AvalonEditor/View/news/newsDesignSecond.vue";

export default {
  name: "viewNews",
  components :{
    newsDesignDefault,
    newsDesignSecond
  },
  props : {
    alias : {
      type : String
    },
    news : {
      type : Object
    }
  },
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      title: this.entity.title || 'Cторінка'  ,
    }
  },
  data() {
    return {
      entity : {},
      recentNews : [],
      loading : false,
    }
  },
  computed : {
    ...mapState('avalon' , [
      'header',
      'editorMode'
    ]),

    loadingColor() {
      // return this.GET_AVALON_COLOR(this.header.config.background_color)
      return this.header.config.style
          ? this.CONFIG_PARAM_COLOR('styles', this.header.config.style, 'bg')
          : this.GET_AVALON_COLOR(this.header.config.background_color)
    },

    pageStyle() {
      return this.entity.style || 'light_1'
    }
  },
  watch : {
    alias() {
      this.initPage()
    },
    news : {
      handler() {
        this.entity = this.COPY(this.news)
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_BUSINESS_NEWS_PUBLIC']),

    async initPage() {
      if ( this.news ) {
        this.entity = this.COPY(this.news)
        this.$emit('ready')
        return
      }
      this.loading = true
      let result =  await this.GET_BUSINESS_NEWS_PUBLIC(this.alias);
      this.loading = false
      if (!result) {
        return
      }
      this.entity = result.news
      this.recentNews = result.recent
      this.$emit('ready')
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>