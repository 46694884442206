<template>
  <v-sheet style="position: relative" color="transparent"  >

    <v-sheet :color="backgroundColor" v-if="item.title || item.text" class="pa-4 d-flex align-end fill-height" style="position: absolute; bottom: 0; left : 0; right : 0;">
      <div>
        <h4 :class="textClass" v-if="item.title" :style="BLOCK_TEXT_CSS(block , block.config.style , 'gallery-slide-tile' , 'title')" > {{ item.title }}</h4>
        <h4 :class="textClass" v-if="item.text" :style="BLOCK_TEXT_CSS(block , block.config.style , 'gallery-slide-tile' , 'text')">{{ item.text }}</h4>
      </div>
     </v-sheet>

    <ws-img :height="height" :src="item.img" />

  </v-sheet>
</template>

<script>
export default {
  name: "gallerySlideVertical",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    blockConfig : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
      default() { return { config : {}} }
    }
  },
  computed : {
    backgroundColor() {
      const hexColor = this.GET_AVALON_COLOR(this.block.config.overlay_color || '#000000'); // Replace with your hex color value

      let opacity = !this.block.config.overlay_opacity && this.block.config.overlay_opacity !== 0 ? 30 : this.block.config.overlay_opacity
      const red = parseInt(hexColor.slice(1, 3), 16);
      const green = parseInt(hexColor.slice(3, 5), 16);
      const blue = parseInt(hexColor.slice(5, 7), 16);
      const alpha = opacity / 100;

      return `rgba(${red}, ${green}, ${blue}, ${alpha})`;

    },

    textClass() {
      let textClass = ''
      if ( this.blockConfig.description_align ) {
        return `text-${this.blockConfig.description_align}`
      }
      return textClass
    },
    height() {
      let multiplier = 1
      if ( this.SM || this.MOBILE_VIEW ) {
        multiplier = 0.9
      }
      return (this.blockConfig.image_height || 400) * multiplier
    }
  }
}
</script>

<style scoped>

</style>