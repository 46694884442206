<template>
  <v-sheet  class="fill-height pointer align-center justify-center "
            :style="elementStyle"
            :color="!element.config.outlined ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
  >
    <v-fade-transition>
      <vue-qrcode v-show="display" ref="qrCode" :size="300"  :text="`https://westudy.ua/certificate/check?id=${certificateRecord.number}`" />
    </v-fade-transition>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import VueQrcode from 'vue-qrcode-component'

export default {
  name: "elementButtonEditor",
  components : {
    VueQrcode
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    certificateRecord : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      element : { config : {}},
      display : false
    }
  },
  computed : {
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px !important;`
      }

      return style
    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
    setTimeout(()=> {
      this.$refs.qrCode.$el.getElementsByTagName('img')[0].style.width = '100%'
      this.$refs.qrCode.$el.getElementsByTagName('img')[0].style.height = '100%'
      this.display = true
    },100)
  }
}
</script>

<style scoped>
.qr-code-container {
  width: 100%;
  height: 100%;
  background-color : coral;
  display : flex;
}

.qr-code-container  >  img {
  width: 100% !important;
  height: 100% !important;
}
</style>