<template>
  <div
      style="padding: 80px 8px"
      :key="trigger"
  >

    <ws-link
        :disabled="editorMode"
        :to="businessPublicLink(entity.return_page_alias ? `page/${ entity.return_page_alias}` : '')"
        class="noUnderline"
        :color="getColor('single_news_category')"
    >

      <h4
          :style="getTextCss('category')"
          class="d-flex align-center pointer mb-12"
      >
        <v-icon
            class="mr-3"
            :color="getColor('single_news_category')"
        >
          mdi-arrow-left
        </v-icon>
        {{ entity.return_page_name || $t('Return') }}
      </h4>
    </ws-link>

    <v-sheet
        color="transparent"
        class="flex-grow-1 mx-auto"
        width="100%"
    >
      <v-sheet color="transparent"  style="width: 100%">

        <portal-target name="no_sidebar_news_portal"></portal-target>

        <h3 :style="getTextCss('title')" >
          {{  entity.title  }}
        </h3>

        <h4  class="mt-6"
             :style="getTextCss('date')"
        >
          {{ $time(entity.date).regularTime() }}
        </h4>

        <ws-text-viewer
            :value="entity.content"
            is-public
            class="pb-6 mt-7"
            avalon
            :avalon-style="pageStyle"
            video-height="500"
        />


      </v-sheet>
    </v-sheet>


    <div class="mt-6"  v-if="recent.length">
      <h5 :style="getTextCss('readMore')"
          class="mb-6"
      >
        {{ $t('ReadMore') }}
      </h5>

      <v-row no-gutters>
        <v-col :cols="!(SM||MOBILE_VIEW) ? 4 : 12"
               v-for=" (item , index) in recent"   :key="index"
               :class="[{'pr-6' : (index+1)%3 !== 0}]"
               class="mb-6"
        >
          <ws-link
              :to="businessPublicLink(`news/${item.alias}`)"
              :color="getColor('single_news')"
              class="pointer"
          >
          <v-sheet
              :color="getColor('single_news_recentBg')"
              class="flex-column fill-height "
              style="position: relative"
          >

            <ws-img
                :src="item.img"
                height="180"
                horizontal-position="center"
                vertical-position="center"
            />

            <div

                :class="[
                { 'pa-6' : !(SM || MOBILE_VIEW) },
                { 'pa-4' : (SM || MOBILE_VIEW) }
            ]"
            >
              <h5 :style="getTextCss('recentDate')">
                {{ $time(item.date).regularTime() }}
              </h5>

              <h5 :style="getTextCss('recentTitle')"
                  class="mt-5">{{ item.title }}
              </h5>


                <h5 @click="$emit('open' , item.uuid)"
                    :style="getTextCss('recentDetails')"
                    class="mt-5 mr-2 d-flex align-center">
                  {{ $t('MoreDetails') }}
                  <v-icon
                      :color="getColor('single_news_recentDetails')"
                      small>mdi-arrow-right</v-icon>
                </h5>


            </div>


          </v-sheet>
          </ws-link>
        </v-col>
      </v-row>


    </div>




  </div>
</template>

<script>
export default {
  name: "newsDesignSecond",
  props : {
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    recent : {
      type : Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      trigger : 0
    }
  },
  computed : {
    pageStyle() {
      return this.entity.style || 'light_1'
    },
    sidebarStyle() {
      let style = ""

      if (!(this.SM || this.MOBILE_VIEW)) {

        style += `margin-left: 32px; padding-left: 40px;`
        style += `border-left : 1px solid ${this.getColor('single_news_sideBorder')} !important;`

      }

      return style
    }
  },
  methods : {
    getTextCss(param) {
      return this.CONFIG_TEXT_CSS(this.pageStyle , 'single_news' , param,null,null,this.EMPTY_BLOCK_PLACEHOLDER,this.WINDOW_WIDTH)
    },
    getColor(param) {
      return this.BLOCK_COLOR_PARAM(this.EMPTY_BLOCK_PLACEHOLDER ,  param, this.pageStyle)
    }
  }
}
</script>

<style scoped>

</style>