var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"fill-height d-flex align-center ",class:[
              { 'align-center' :  _vm.config.valign === 'center'  },
              { 'align-baseline' :  !_vm.config.valign || _vm.config.valign === 'top'  },
              { 'align-end' :  _vm.config.valign === 'bottom'  },
              { 'justify-center' : _vm.config.align === 'center' },
              { 'justify-end' : _vm.config.align === 'right' },
              { 'justify-start' : _vm.config.align === 'left' }
          ],style:(_vm.elementStyle),attrs:{"color":_vm.element.config && _vm.element.config.has_background ? _vm.GET_AVALON_COLOR(_vm.config.background_color) : 'transparent'}},[_c('div',{staticStyle:{"white-space":"pre-line !important"},style:(_vm.textStyle),domProps:{"innerHTML":_vm._s(_vm.text)}})])}
var staticRenderFns = []

export { render, staticRenderFns }