<template>
  <portal to="dialog_top" :disabled="!display">
    <v-fade-transition>
      <v-sheet
          v-if="display"
          :color="wsACCENT + 'bc' "
          style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 999999999999999 ;"
          class="d-flex align-center justify-center"
      >

        <v-sheet
            light
            @click.stop
            width="1000"
            :class="[
              {'fill-height '  :   fullscreen || SM || fillHeight  },
              {'wsRoundedHalf' : !(fullscreen || SM) },
          ]"
            height="90%"
            class="wsRoundedHalf d-flex "
            elevation="5"
        >
          <!-- Navigation-->
          <v-sheet width="300" :color="wsBACKGROUND" class="overflow-y-auto wsRoundedHalfLeft">

            <h2 :style="`color : ${wsDARKER}`" style="font-size: 19px" class="pa-6 py-4 "> {{ $t(!certificates ? 'AddBlock' : 'AddCertificate') }}</h2>

            <ws-avalon-navigation-list
                v-model="selectedCategory"
                :items="categoriesSelectSystem"
                mandatory
                no-edit
            />

            <ws-avalon-navigation-list
                class="mt-3"
                v-model="selectedCategory"
                :items="categoriesSelectGeneral"
                mandatory
                no-edit
            />

            <ws-avalon-navigation-list
                class="mt-3"
                v-model="selectedCategory"
                :items="categoriesSelectThematic"
                mandatory
                no-edit
            />


          </v-sheet>
          <!-- Blocks -->
          <v-sheet
              width="700"
              class="fill-height overflow-y-auto  d-flex flex-column wsRoundedHalfRight "
              style="position : relative"
          >
            <v-sheet style="position:absolute; top : 0 ; bottom : 62px; right : 0; z-index : 9" width="23" color="white"></v-sheet>
            <!-- Header -->
            <div class="d-flex align-center pa-3 px-6 mb-2 justify-space-between">

              <div class="d-flex">

                <v-sheet
                    @click="gridView = false"
                    :color="!gridView ? (wsATTENTION + '44') : 'transparent' "
                    class="d-flex align-center justify-center wsRoundedLight pointer"
                    height="34"
                    width="34"
                    icon
                    v-ripple
                >
                  <v-icon size="20" :color="!gridView ? wsATTENTION : wsACCENT">mdi-view-agenda-outline</v-icon>
                </v-sheet>

                <v-sheet
                    @click="gridView = true"
                    :color="gridView ? (wsATTENTION + '44') : 'transparent' "
                    class="d-flex align-center justify-center wsRoundedLight pointer"
                    height="34"
                    width="34"
                    icon
                    v-ripple
                >
                  <v-icon :color="gridView ? wsATTENTION : wsACCENT" size="20">mdi-grid-large</v-icon>
                </v-sheet>


              </div>

              <v-btn :color="wsDARKER" icon @click="display = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </div>
            <!-- Blocks -->
            <div class="overflow-y-auto pb-6" style="height: 100%">

            <!-- Saved Blocks Placeholder-->
              <div  v-if="selectedCategory === 'saved' && blocksFiltered.length === 0" class="d-flex fill-height align-center justify-center">
                <div>
                  <div class="d-flex justify-center">
                    <v-icon  :color="wsACCENT" size="84">mdi-bookmark-outline</v-icon>
                  </div>

                  <h3 style="max-width: 500px" class="mt-4 text-center">{{ $t(!certificates ? 'avalon.block.saved.empty.title' : 'avalon.block.saved.empty.certificates_title') }}</h3>
                  <h5 style="max-width: 500px" class="font-weight-regular mt-4 text-center">{{ $t(!certificates ? 'avalon.block.saved.empty.text' : 'avalon.block.saved.empty.certificates_text') }}</h5>
                </div>

              </div>
              <div v-if="!gridView" style="position : relative">

                <div class="overflow-x-hidden px-12" style="position: absolute; width : 1500px; transform: scale(0.4666);
                  transform-origin: top left;">

                  <v-hover class="mb-8"  v-slot="{hover}"    v-for="(block) in blocksFiltered" :key="block.id" >
                  <v-sheet
                      @click.stop="selectBlock(block.id)"
                      :color="wsACCENT"
                      :style="`border :  4px solid ${!hover ? wsBACKGROUND : wsDARKLIGHT};`"
                      class="pointer"
                  >



                    <view-block
                        v-if="!certificates"
                        :color="hover ? wsACCENT : 'transparent' "
                        :style="`opacity:${ hover ? 0.5 : 1}`"
                        style="transition: all 0.3s ease; pointer-events: none"

                        ref="block"
                        :block="block"
                        :elements="getBlockElements(block.id)"
                        preview

                    />
                    <certificate-viewer
                        v-else
                        :style="`opacity:${ hover ? 0.5 : 1}`"
                        style="transition: all 0.3s ease"
                        class="pointer"
                        ref="block"
                        :block="block"
                        :elements="getBlockElements(block.id)"
                        preview
                    />


                  </v-sheet>
                  </v-hover>

                </div>

              </div>
              <div v-else class="pl-6">
                <v-row no-gutters>
                    <v-col  cols="6" class="pr-1" >
                      <template   v-for="(block,index) in  blocksFiltered">
                        <div
                            v-if="index%2 === 0"
                            @click.stop="selectBlock(block.id)"
                            :key="index + 'left' + block.id"
                        >
                          <preview-block
                              :class="index !== 0 ? 'mt-6' : null"
                              width="320"
                              :block="block"
                              :elements="getBlockElements(block.id)"
                              :is-certificate="certificates"
                          >
                          </preview-block>
                        </div>
                      </template>
                    </v-col>
                    <v-col  cols="6"  class="pl-1" >
                      <template v-for="(block,index) in  blocksFiltered">
                        <div
                            v-if="index%2 !== 0"
                            @click="selectBlock(block.id)"
                            :key="index + 'right' + block.id"
                        >
                          <preview-block
                              :class="index > 1 ? 'mt-3' : null"
                              width="320"
                              :block="block"
                              :elements="getBlockElements(block.id)"
                              :is-certificate="certificates"
                          >
                          </preview-block>
                        </div>

                      </template>
                    </v-col>


                </v-row>


              </div>

            </div>
            <!-- Buttons -->
            <div class="d-flex justify-end px-6 py-5  justify-end">
              <ws-button
                  @click="display = null"
                  label="Cancel"
                  outlined
                  class="mr-3"
              />
<!--              <ws-button-->
<!--                  label="Choose"-->
<!--              />-->
            </div>



          </v-sheet>




        </v-sheet>


      </v-sheet>
    </v-fade-transition>
  </portal>

</template>

<script>
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
import { mapState , mapActions} from "vuex";
import previewBlock from "@/components/AvalonEditor/Templates/previewBlock";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import certificateViewer from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateViewer";

export default {
  name: "avalonNewBlockDialog",
  components : {
    previewBlock,
    wsAvalonNavigationList,
    viewBlock,
    certificateViewer
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    number : {
      type : Number ,
      default : 0
    },
    footer : {
      type : Boolean,
      default : false
    },
    certificates : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      blocks : [],
      elements : [],
      entityData : {},
      display : false,
      selectedCategory : '',
      gridView : false,
      categories : []
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),

    items() {
      return this.getCategoryBlocks(this.selectedCategory)
    },

    categoriesSelectSystem() {
      return [
        { text : this.$t(!this.certificates ? 'AddEmptyBlock' : "CreateNewCertificate") ,
          value : 'zero' ,
          color : this.wsDARKER,
          action : () => {
            this.$emit('save', this.entityData)
          },
          noEdit : true,
        }
      ]

    },
    categoriesSelectGeneral() {
      if ( this.categories.length === 0) {
        return []
      }

      let items = this.COPY(this.categories)

      items = items.filter( el => !el.is_thematic)

      items.unshift({ text : this.$t(!this.certificates ? 'SavedBlocks' : 'SavedCertificates') ,
        value : 'saved' ,
        noEdit : true,
      })

      return items
    },
    categoriesSelectThematic() {
      if ( this.categories.length === 0) {
        return []
      }

      let items = this.COPY(this.categories)

      items = items.filter( el => el.is_thematic)

      return items
    },
    blocksFiltered() {
      let items = this.blocks
      if ( items.length === 0 ) {
        return []
      }
      if ( this.footer ) {
        items = items.filter(el=> el.is_footer)
      }

      return this.getCategoryBlocks(this.selectedCategory)
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input', this.display)
      }
    },
    number() {
      this.entityData = {
        number : this.number
      }
    }
  },
  methods : {
    ...mapActions('avalon', ['GET_TEMPLATE_BLOCKS']),
    ...mapActions('certificates' , [ 'GET_CERTIFICATE_TEMPLATES' ]),

    selectBlock(blockId) {
      if ( this.LOADING ) {
        return
      }
      this.entityData.id = blockId; this.$emit('save', this.entityData)
    },

    getCategoryBlocks() {
      if (this.blocks.length === 0 ) {
        return []
      }

      return this.blocks.filter( el => el.category === this.selectedCategory )

    },
    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },

  },
  async mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    this.entityData = JSON.parse(JSON.stringify(
        {
          number : this.number
        }
    ))

    let result = !this.certificates ? await this.GET_TEMPLATE_BLOCKS(this.selectedLang) : await this.GET_CERTIFICATE_TEMPLATES()
    if ( !result ) {
      return
    }
    this.categories = result.categories

    if ( this.categories.length > 0 ) {
      this.selectedCategory = this.categories[0].value
    }

    this.blocks = result.blocks
    this.elements = result.elements


  }
}
</script>

<style scoped>

</style>