<template>

  <v-hover  v-slot="{hover}">

  <v-sheet
      :width="width"
      :height="height"
      style="position: relative ; transition: all 0.3s ease"
      class="overflow-hidden "
      :style="`
        outline :  ${!hover ? 1 : 2}px solid ${!hover ? wsBACKGROUND : wsDARKLIGHT};
        margin-top : 1px;
        opacity:${ !ready ? 0 : 1}
        `"

  >
    <v-sheet
        width="1500"
        class="overflow-hidden pointer"
        style="transform-origin: top left; transition: all 0.3s ease"
        :style="`transform : scale(${width/1500});`"
        :color="hover ? wsACCENT : 'transparent' "
    >
      <view-block
          v-if="!isCertificate"
          :style="`opacity:${ !ready ? 0 : hover ? 0.5 : 1}`"
          style="transition: all 0.3s ease; pointer-events : none"
          ref="block"
          :block="block"
          :elements="elements"
          preview
      />
      <certificate-viewer
          v-else
          :style="`opacity:${ !ready ? 0 : hover ? 0.5 : 1}`"
          style="transition: all 0.3s ease"
          class="pointer"
          ref="block"
          :block="block"
          :elements="elements"
          preview
      />
    </v-sheet>


  </v-sheet>
  </v-hover>

</template>

<script>
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import certificateViewer from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateViewer";
export default {
  name: "previewBlock",
  components : {
    viewBlock,
    certificateViewer
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    width : {
      type : Number,
      default : 600
    },
    isSelected : {
      type : Boolean,
      default : false
    },
    isCertificate : {
      type : Boolean,
    }
  },
  data() {
    return {
      height : 250,
      ready : false
    }
  },
  methods : {
    getHeight() {
      let block = this.$refs.block
      if ( !block ) {
        return
      }
      this.height = block.$el.clientHeight * (this.width / 1500)
    }
  },
  beforeMount() {
    if ( !this.block.config.style ) {
      this.block.config.style = 'light_1'
      this.block = this.COPY(this.block)
    }
  },
  mounted() {

    console.log('Preview Block Mounted Function')

    setTimeout(() => {
      this.getHeight()
      this.ready = true
    }, 350)
  }


}
</script>

<style scoped>

</style>