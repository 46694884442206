var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('ft-select',{ref:"toolbarWrapper",attrs:{"items":_vm.fontStyles,"delay-close":""},on:{"input":_vm.formatHeading,"expand":function($event){return _vm.$emit('expand' , $event);}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
return [_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"color":"transparent","min-width":"110"},on:{"click":function($event){return _vm.$emit('expand' , true)}}},[_c('h5',{staticClass:"text-no-wrap",style:(("color  : " + _vm.wsACCENT))},[_vm._v(_vm._s(text || _vm.$t('font.p') + ' 1'))]),_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.selectedFontStyle),callback:function ($$v) {_vm.selectedFontStyle=$$v},expression:"selectedFontStyle"}}),_c('v-menu',{key:"color_picker_menu",attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mx-0",attrs:{"color":_vm.GET_AVALON_COLOR(_vm.elementColor),"text":"","min-width":"35","width":"35"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-circle")])],1)]}}]),model:{value:(_vm.colorDropdown),callback:function ($$v) {_vm.colorDropdown=$$v},expression:"colorDropdown"}},[_c('v-sheet',{staticClass:"pa-2 salvation-toolbar",attrs:{"width":"250"}},[_c('colorParamPicker',{key:"color_picker",attrs:{"only-colors":""},on:{"input":_vm.formatColor},model:{value:(_vm.elementColor),callback:function ($$v) {_vm.elementColor=$$v},expression:"elementColor"}})],1)],1),_vm._l((_vm.toolbarItemsSelect),function(item,i){return [(!item.separator && !item.children && !item.linkDropdown)?_c('div',{key:i,staticClass:"pr-1"},[_c('v-btn',{staticClass:"px-0 mx-0",attrs:{"color":_vm.formatIsSet(item.value) ? _vm.wsBACKGROUND : _vm.wsACCENT,"min-width":"30","elevation":"0","width":"30","height":"30","text":!_vm.formatIsSet(item.value)},on:{"click":function($event){return _vm.format(item.value)}}},[_c('v-icon',{attrs:{"size":"20","color":_vm.wsACCENT}},[_vm._v(_vm._s(item.icon)+" ")])],1)],1):(item.linkDropdown)?_c('v-menu',{key:i + 'link_dropdown',attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mx-0",attrs:{"color":!!_vm.config.link ? _vm.wsBACKGROUND : _vm.wsACCENT,"min-width":"30","elevation":"0","width":"30","height":"30","text":!_vm.config.link},on:{"click":function($event){return _vm.format(item.value)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":_vm.wsACCENT}},[_vm._v(_vm._s(item.icon)+" ")])],1)]}}],null,true),model:{value:(_vm.linkDropdown),callback:function ($$v) {_vm.linkDropdown=$$v},expression:"linkDropdown"}},[_c('v-sheet',{staticClass:"pa-3 mt-1 ",staticStyle:{"border-radius":"8px"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('InsertLink'),"width":"300px","placeholder":"https://example.com"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('div',{staticClass:"mt-3 d-flex justify-space-between"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.format(['link' , false ])}}},[_c('v-icon',{attrs:{"color":_vm.config.link ? _vm.wsACCENT : _vm.wsBACKGROUND}},[_vm._v("mdi-delete-outline")])],1),_c('ws-button',{attrs:{"label":"Apply"},on:{"click":function($event){_vm.format( ['link' , _vm.url] ); _vm.linkDropdown = false}}})],1)],1)],1):(item.children)?_c('ft-select',{key:i + 'dropdown',attrs:{"items":item.children,"min-width":"0","delay-close":""},on:{"input":function($event){return _vm.format($event)},"expand":function($event){return _vm.$emit('expand' , $event);}}},[_c('v-btn',{staticClass:"px-0 mx-0",attrs:{"color":_vm.wsACCENT,"min-width":"30","elevation":"0","width":"30","height":"30","text":""}},[_c('v-icon',{attrs:{"size":"20","color":_vm.wsACCENT}},[_vm._v(_vm._s(_vm.getFormatDropdownIcon(item))+" ")])],1)],1):_c('v-divider',{key:i + 'divider',staticClass:"mx-2 my-2",style:(("border-color : " + _vm.wsBACKGROUND)),attrs:{"vertical":""}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }