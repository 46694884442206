<template>

  <div   style="position: relative">
    <v-hover #default="{hover}">
      <v-sheet
          color="transparent"
          style="transition: background-color 0.2s ease"
          class="pt-3"
      >
        <div class="d-flex justify-space-between align-center">
          <h5 :style="`color : ${wsDARKER}`"
              :class="[{'font-weight-regular' : !labelText}]"

          >
            {{ $t(label) }}
          </h5>


          <h5 @click="paramAction"
              :style="`color : ${wsACCENT}`"
              style="margin-top: 2px"
              class="pointer text-no-wrap"
          >
            {{  getItemText(selectedParam) || $t('Choose')  }}
            <v-icon :color="wsACCENT">{{ emitSetup && selectedParam ==='custom' ? "mdi-chevron-right" : "mdi-menu-down" }}</v-icon>
          </h5>

        </div>
        <v-divider style="transition: all 0.15s ease"  class="mt-3" :style="`border-color : ${!hover ? wsDARKLIGHT : wsACCENT}; `" />
      </v-sheet>
    </v-hover>

    <v-expand-transition>
      <v-sheet
          v-if="display"   v-click-outside="() => display = false"
          style="z-index: 999999999; width: 100%; border-radius: 8px"
          max-height="400"
      >
        <v-list
            class="overflow-y-auto"
            style="border-radius: 8px"
            dense >

          <v-list-item-group mandatory :color="wsACCENT" v-model="selectedParam">
            <v-hover
                v-for="(item,i) in items"
                v-slot="{hover}"
                :key="i"
            >
              <v-list-item
                  :value="item"
                  @click="selectedParam = item; display = false"
                  class="transition-ease-in-out"
                  :style="`background-color: ${hover ? wsLIGHTCARD : ''}`"
                  :color="wsACCENT"
              >
                <v-list-item-title :style="`color : ${wsACCENT}`" >
                  {{getItemText(item)}}
                </v-list-item-title>
              </v-list-item>
            </v-hover>
          </v-list-item-group>

        </v-list>

      </v-sheet>
    </v-expand-transition>

  </div>

</template>

<script>

import fonts_sizes from '@/assets/json/fonts_sizes.json'

export default {
  name: "fontWeightSelect",
  props : {
    value : {
      type : String
    },
    paramStyle : {
      type : Boolean,
      default : false
    },
    fontStyle : {
      type : Boolean,
      default : false
    },
    fontCase : {
      type : Boolean,
      default : false
    },
    noHover : {
      type : Boolean,
      default : false
    },
    labelText : {
      type : String
    },
    labelTextRaw : {
      type : String
    },
    family : {
      type : String,
      default : 'Montserrat'
    },
    emitSetup : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      uuid : null,
      search : '',
      selectedParam : '',
      display : false,
      label : 'FontWeight',
      weightSelect : [ 100 , 200 , 300 , 400 , 500 , 600 ,700 ,800 ,900 ],
      paramSelect : [ 'h1', 'h2','h3' ,'h4' , 'h5' , 'p' ,'h6' , 'custom'],
      styleSelect : ['normal' , 'italic'],
      caseSelect : ['none' , 'uppercase' , 'lowercase'],
      type : 'weight',
      fonts_sizes : {}
    }
  },
  computed : {
    triggerId() {
      return this.$store.state.avalon.paramDropdownId
    },
    familyWeights() {
      let items = this.weightSelect

      if ( this.family && this.fonts_sizes[this.family]) {
        return this.fonts_sizes[this.family]
      }

      return items
    },
    items() {
      let items = this.familyWeights

      if ( this.type === 'style' ) {
        items = this.styleSelect
      }

      if ( this.type === 'case' ) {
        items = this.caseSelect
      }

      if ( this.type === 'param' ) {
        items = this.paramSelect
      }

      return items
    },
    fontsFiltered() {
      let items = this.$store.state.avalon.fonts

      if ( this.search && this.search.length > 2 ) {
        items = items.filter(el => el.toLowerCase().includes(this.search.toLowerCase().trim()))
      }

      return items
    }
  },
  watch : {
    // triggerId() {
    //   if ( this.$store.state.avalon.paramDropdownId !== this.uuid ) {
    //     this.notify('i want')
    //   }
    // },
    display() {
      if (this.display){
        this.$store.state.avalon.paramDropdownId = this.uuid
      }
    },
    familyWeights : {
      handler() {
        if ( this.type !== 'weight' ) {
          return
        }
        if ( !this.familyWeights.includes(this.value)) {
          this.selectedParam = this.familyWeights[0]
        }
      },
      deep : true
    },
    selectedParam() {
      if ( this.selectedParam !== this.value ) {
        this.$emit('input' , this.selectedParam)
      }
    },
    value() {
      if ( this.selectedParam !== this.value ) {
        this.selectedParam = this.value
      }
    }
  },
  methods : {
    paramAction() {
      if ( this.emitSetup && this.selectedParam === 'custom' ) {
        this.$emit('emit-setup')
        return
      }
      this.display = !this.display
    },
    getItemText(item) {
      if ( this.type === 'weight' ) {
        return item
      }
      if ( this.type === 'param' ) {
        if ( item === 'custom' ) {
          return this.$t('CustomFontParam')
        }
        return this.$t(`avalon.color_styles.${item}`)
      }
      return this.$t(`avalon.config.fonts.${this.type}.${item}`)
    }
  },
  beforeMount() {
    this.fonts_sizes = fonts_sizes
    this.uuid = this.$uuid.v4()
  },
  async mounted() {
    if ( this.value ) {
      this.selectedParam = this.value
    }
    if ( this.fontStyle  ) {
      this.type = 'style'
      this.label = 'Style'
    }
    if ( this.paramStyle ) {
      this.type = 'param'
      this.label = 'Style'
    }
    if ( this.fontCase ) {
      this.type = 'case'
      this.label = 'FontCase'
    }
    if ( this.labelTextRaw ) {
      this.label = this.labelTextRaw
    } else if ( this.labelText ) {
      this.label = this.$t(this.labelText)
    }

    this.selectedParam = this.value || ''
  }
}
</script>

<style scoped>

</style>