<template>
    <vue-draggable-resizable
        ref="element_layout"
        :w="elW" :h="elH" :x="elX" :y="elY" :z="element.z"
        @activated="activateElement"
        :draggable="false"
        :on-resize-start="resizeStart"
        v-click-outside="deselect"

        :min-height="resizableElementsArray.includes(element.type) ? textActualHeight : null"
        :style="getOpacityStyle + !element.active ? 'cursor : grab' : null"
        :class="[{ grabbingCursor : isDragging || isResizing  }  ]"
        class="wsRoundedLight"
        class-name-handle="vdr-handle"
        class-name-active="vdr-active"
        class-name-vdr="vdr"
        style="transform-style: preserve-3d;"
        key="draggable"
        :id="`element_${element.id}`"
    >
      <editElementToolbar
          v-if="isSelected && !edit && !isDragging && !isResizing && !$store.state.avalon.elementSettingsOn"
          :quill="quillVariable"
          :quillReadyTrigger="quillReadyTrigger"
          @action="toolbarAction"
          :display="isSelected && !edit && !isDragging && !isResizing && !$store.state.avalon.elementSettingsOn"
          :element-id="element.id"
          :text-editor="element.type === 'text' && allowEditText"
          @expand="toolbarOpened = $event"
          :is-overlapping="isOverlapping"
          :element="element"
      />
      <v-sheet
          v-show="isReady"
          @mousedown="dragStart"
          @mouseover="mouseOver = true"
          @mouseleave="mouseOver = false"
          :class="[{ activeBorder   : mouseOver }]"
          ref="element_content"
          color="transparent"
          class="fill-height elementDrag"
          :style="isDragging ? 'pointer-events: none' : ''"
      >
        <!-- Old Text Aditor -->
        <v-sheet
            v-if="false"
            :color="element.config && element.config.has_background ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
            :class="[
                { 'align-center' :  element.config.valign === 'center'  },
                { 'align-baseline' : !element.config.valign || element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
            :style="textElementStyle"
            class="fill-height d-flex "
        >
          <a-editor
              :id="'ae_element_' + element.id"
              :key="element.id + 'element'"
              @input="editElement"
              v-model="element.text"
              :element-height="elH"
              :element-config="element.config"
              @toolbarDefaultAction="toolbarAction"
              @updateHeight="updateHeight"
              @edit="edit = $event"
              @toolbarOpened="toolbarOpened = $event"
              :is-selected="isSelected"
              element
              :block="block"
          />
        </v-sheet>
        <element-text-editor
            v-if="element.type === 'text' "
            :quill="quillVariable"
            @quillReady="quillReady"
            :key="'text-editor' + element.id"
            v-model="element"
            @input="editElement()"
            @focus="textFocused = $event; allowEditText = true;  $emit('text-focus' , $event)"
            @update-height="updateHeight"
            @actual-height-update="textActualHeight = $event"
            :block="block"
            :trigger-deselect="triggerDeselect"
            :toolbar-opened="toolbarOpened"
            :cell-width="cellWidth"
            :is-ready="true"
            :is-selected="isSelected"
            :height="elH"
            style="user-select: none "
            :disabled="!textEditReady"
        />
        <element-image-editor
            v-if="element.type === 'image' "
            v-model="element"
            :template="template"
            :block-style="block.config.style"
            :id="'ae_element_' + element.id"
            @update-height="updateHeight(true)"
            @actual-height-update="textActualHeight = $event"
        />
        <element-button-editor
            :id="'ae_element_' + element.id"
            :block-style="block.config.style"
            v-if="element.type === 'button' || element.type === 'modal' "
            v-model="element"
        />
        <element-course-name-editor
            :id="'ae_element_' + element.id"
            v-if="element.type === 'course_name' "
            v-model="element"
        />
        <element-course-price-editor
            :id="'ae_element_' + element.id"
            v-if="element.type === 'course_price' "
            v-model="element"
        />
        <element-rectangle-editor
            v-if="element.type === 'rectangle' "
            v-model="element"
            :block="block"
        />
        <element-line-editor
            v-if="element.type === 'line' "
            v-model="element"
            :block-style="block.config.style"
        />
        <element-list-editor
            :id="'ae_element_' + element.id"
            v-if="element.type === 'list' "
            v-model="element"
            :block-style="block.config.style"
            :block="block"
            @update-height="updateHeight(true)"
            @actual-height-update="textActualHeight = $event"
        />
        <element-form-editor
            v-if="element.type === 'form' "
            v-model="element"
            :block-style="block.config.style"
            :block="block"
            @update-height="updateHeight()"
            @actual-height-update="textActualHeight = $event"
        />
        <element-accordion-editor
            :id="'ae_element_' + element.id"
            v-if="element.type === 'accordion' "
            v-model="element"
            :block-style="block.config.style"
            @update-height="updateHeight(true)"
            @actual-height-update="textActualHeight = $event"
        />

        <element-video-editor
            v-if="element.type === 'video' "
            v-model="element"
            :template="template"
        />

        <!-- Course Elements-->
        <element-course-content-editor
            v-if="element.type === 'course-content' "
            v-model="element"
            :block-style="block.config.style"
            @update-height="updateHeight"
            @actual-height-update="textActualHeight = $event"
            :id="'ae_element_' + element.id"
        />
        <element-course-pricing-editor
            v-if="element.type === 'course_pricing' "
            v-model="element"
            :block-style="block.config.style"
        />


        <v-expand-x-transition>
          <avalon-element-settings
              @close="displaySettings = false "
              v-if="displaySettings"
              v-model="displaySettings"
              :element="element"
              @update="element = $event"
              @sync-grid="synchronizeGrid"
              :block-style="block.config.style"
          />
        </v-expand-x-transition>

      </v-sheet>

    </vue-draggable-resizable>
</template>

<script>
import {mapActions, mapState} from "vuex";
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: "zeroElement",
  components : {
    editElementToolbar : () => import("@/components/AvalonEditor/Editor/elements/editElementToolbar"),
    avalonElementSettings : () => import("@/components/AvalonEditor/Dialogs/elements/AvalonElementSettings"),
    elementImageEditor : () => import("@/components/AvalonEditor/Editor/elements/elementImageEditor"),
    elementButtonEditor : () => import("@/components/AvalonEditor/Editor/elements/elementButtonEditor"),
    elementCourseNameEditor : () => import("@/components/AvalonEditor/Editor/elements/elementCourseNameEditor"),
    elementCoursePriceEditor : () => import("@/components/AvalonEditor/Editor/elements/elementCoursePriceEditor"),
    elementRectangleEditor : () => import("@/components/AvalonEditor/Editor/elements/elementRectangleEditor"),
    elementLineEditor : () => import("@/components/AvalonEditor/Editor/elements/elementLineEditor"),
    elementFormEditor : () => import("@/components/AvalonEditor/Editor/elements/elementFormEditor"),
    elementTextEditor : () => import("@/components/AvalonEditor/Editor/elements/elementTextEditor"),
    elementAccordionEditor : () => import("@/components/AvalonEditor/Editor/elements/elementAccordionEditor"),
    elementVideoEditor : () => import("@/components/AvalonEditor/Editor/elements/elementVideoEditor"),
    elementListEditor : () => import("@/components/AvalonEditor/Editor/elements/elementListEditor"),
    elementCourseContentEditor : () => import("@/components/AvalonEditor/Editor/elements/elementCourseContentEditor"),
    elementCoursePricingEditor : () => import("@/components/AvalonEditor/Editor/elements/elementCoursePricingEditor"),
    VueDraggableResizable,
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    active : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
    template : {
      type : Boolean,
      default : false
    },
    blockElementsEditing : {
      type : Boolean,
      default : false
    },
    displayCanvas : {
      type : Boolean,
      default : false
    },
    disableParentBorders : {
      type : Boolean,
      default : false
    },
    maxHeight : {
      type : Number,
    },
    maxHeightSM : {
      type : Number,
    },
    cellWidth : {},
    cellHeight : {},

    overlapElementsArray : {
      type : Array,
      default() { return [] }
    },
    arrangeMobileOverlapTrigger : {
      type : Number,
      default : 0
    },

    moveBelowData : {
      type : Object
    },
    blockReadyTimeout : {
      type : Boolean,
      default : false
    }


  },
  data() {
    return {
      elX : 0,
      elY : 0,
      elW : 0,
      elH : 0,
      resizableElementsArray : [
          'text' ,
          'list' ,
          'accordion',
          'course-content',
          'form'
      ],
      resizableElementsArrayNoText : [
        'list' ,
        'accordion',
        'course-content',
        'form'
      ],
      isReady : false,
      quillVariable : null,
      quillReadyTrigger : 0,

      // dragging
      isDragging : false,
      startX : null,
      startY : null,
      initialX : null,
      initialY : null,
      // resizing
      resizeHandle : '',
      isResizing : false,
      initialWidth : null,
      initialHeight : null,
      initialX2 : 0,
      initialY2 : 0,

      mouseOver : false,

      isSelected : false,
      edit : false,
      textFocused : false,
      element : {},
      toolbarOpened : false,
      displaySettings : false,
      isParentLocked : true,
      isResizable : true,
      textToolbarExpand : false,
      triggerDeselect : 0,
      textActualHeight : 0,

      bufferElement : {},
      dragTimeout : null,

      // Move Below Variables
      heightDifference : 0,
      readyToUpdateHeight : false,
      bufferY2 : null,
      bufferY : null,
      // Overlapping
      allowEditText : false,
      textEditReady : false,
      textEditReadyTimeout : null,

      updateDelay : false
    }
  },
  computed : {
    ...mapState('avalon',[
        'elementsResizeTrigger' ,
        'rowHeightChangeTrigger' ,
        'sideMenu' ,
        'difference' ,
        'differenceStartY' ,
        "differenceStartX1" ,
        "differenceStartX2",

    ]),

    isOverlapping() {
      if ( !this.isSelected && !this.MOBILE_VIEW ) {
        return
      }

      return this.block.elements.filter( el =>
          el[this.DIM_KEY['y1']] <= this.element[this.DIM_KEY['y2']] &&
          el[this.DIM_KEY['y1']] >= this.element[this.DIM_KEY['y1']] &&
          el[this.DIM_KEY['x1']] <= this.element[this.DIM_KEY['x2']] &&
          el[this.DIM_KEY['x2']] >= this.element[this.DIM_KEY['x1']] &&
          el.id !== this.element.id
      ).length > 0

    },

    CELLS_WIDTH() {
      return this.element[this.DIM_KEY['x2']] - this.element[this.DIM_KEY['x1']] +1
    },
    CELLS_HEIGHT() {
      return this.element[this.DIM_KEY['y2']] - this.element[this.DIM_KEY['y1']] +1
    },
    x() {
      return (this.element[this.DIM_KEY['x1']] - 1) * (this.cellWidth+this.gridGapHorizontal)
    },
    y(){
      return (this.element[this.DIM_KEY['y1']] - 1) * (this.cellHeight+this.gridGapVertical)
    },
    height() {
      return (this.CELLS_HEIGHT * this.cellHeight) + ((this.CELLS_HEIGHT-1)*this.gridGapVertical )
    },
    width() {
      return (this.CELLS_WIDTH * this.cellWidth) + ((this.CELLS_WIDTH-1)*this.gridGapHorizontal )
    },
    ELEMENT_DIMENSIONS() {
      return {
        x : this.x || 0,
        y : this.y | 0,
        width : this.width || 0,
        height : this.height || 0
      }
    },

    getOpacityStyle() {
      if (this.isResizing || this.isDragging) {
        return 'opacity : 0.5'
      }
      return 'opacity : 1'
    },
    textElementStyle() {
      let style = ''
      if ( this.element.type !== 'text') {
        return ''
      }
      if ( !this.element.config ) {
        return ''
      }
      if ( this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px; `
      }
      if ( this.element.config.padding_top) {
        style += `padding-top : ${this.element.config.padding_top}px;  `
      }
      if ( this.element.config.padding_bottom) {
        style += `padding-bottom : ${this.element.config.padding_bottom}px;  `
      }
      if ( this.element.config.padding_left) {
        style += `padding-left : ${this.element.config.padding_left}px;  `
      }
      if ( this.element.config.padding_right) {
        style += `padding-right : ${this.element.config.padding_right}px;  `
      }

      if ( this.element.config.is_outlined ) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important ;`
      }

      return style

    },
    gridGapVertical() {
      if ( this.MOBILE_VIEW ) {
        return 4
      }
      let value = this.block.config.gridGapVertical || 4

      return value * this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
    },
    gridGapHorizontal() {
      if ( this.MOBILE_VIEW ) {
        return 4
      }
      let value = this.block.config.gridGapHorizontal || 4

      return value * this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
    },
  },
  watch : {

    blockElementsEditing() {

      if ( this.blockElementsEditing && this.$store.state.avalon.selectedElement !== this.element.id ) {
        this.bufferY = this.element[this.DIM_KEY['y1']]
        this.bufferY2 = this.element[this.DIM_KEY['y2']]
      }
    },
    CELLS_HEIGHT(value, old) {
      this.heightDifference  = value - old
    },
    moveBelowData : {
      handler(value , old) {
        if ( this.EQ(value , old ) ) {
          return
        }
        if ( value ) {
          this.handleMoveBelow(value)
        }
      },
      deep : true
    },
    ELEMENT_DIMENSIONS : {
      handler(value) {
        this.elX = value.x
        this.elY = value.y
        this.elW = value.width
        this.elH = value.height
      },
      deep : true
    },
    displayCanvas(value) {
      let element = this.$refs.element_layout
      element.active = value
    },
    displaySettings(value) {
      let element = this.$refs.element_layout
      element.active = value
    },
    value : {
      handler() {
        if ( this.value !== this.element ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    },
    element : {
      handler() {
        if ( this.value !== this.element ) {
          this.$emit('input' , this.element )
        }
      },
      deep : true
    },
    isSelected(value) {
      if ( value ) {
        setTimeout(()=> {
          this.$store.state.avalon.selectedElement = this.element.id
        },1)
      }
    },

    // Overlapp handle functions
    isOverlapping(value) {
      this.$emit('is-overlapping' , {value : value , id : this.element.id})
    },
    arrangeMobileOverlapTrigger() {
      if ( this.overlapElementsArray.includes(this.element.id)) {
        this.updateHeight()
        this.toolbarMoveBelow()
        this.overlapElementsArray = this.overlapElementsArray.filter(el => el.id === this.element.id)
      }
    },
    MOBILE_VIEW(){
      this.checkIfHeightIsWrong()
    }


  },
  methods : {
    ...mapActions('avalonPage',[
        'EDIT_ELEMENT'
    ]),

    // function for rearange button view, to check if element height is wrong
    checkIfHeightIsWrong(){
      if ( !this.MOBILE_VIEW ) {
        return
      }
      setTimeout(()=>{
         let domElement = document.getElementById('ae_element_' + this.element.id )
         if ( domElement ) {
           let height = domElement.getBoundingClientRect().height
           if ( this.elH - height < - 40 ) {
             this.$emit('display-mobile-rearrange' , true)
             this.$emit('is-overlapping' , {value : true , id : this.element.id})
           }
         }
      },400)
    },
    activateElement() {
      this.isSelected = true;
      this.$store.state.avalon.selectedElement = this.element.uuid
      if ( this.element.type === 'text') {
        if ( this.textEditReadyTimeout ) {
          clearTimeout(this.textEditReadyTimeout)
        }
        this.textEditReadyTimeout = setTimeout(() => {
          this.textEditReady = true
        },300)

      }
    },
    // resize
    resizeStart(handle,event) {


      this.isResizing = true
      window.addEventListener('mousemove', this.handleResize);
      window.addEventListener('mouseup', this.resizeStop);

      this.resizeHandle = handle

      this.startX = event.clientX
      this.startY = event.clientY
      this.initialX = this.elX
      this.initialY = this.elY
      this.initialX2 = this.element[this.DIM_KEY['x2']]
      this.initialY2 = this.element[this.DIM_KEY['y2']]
      this.initialWidth = this.elW
      this.initialHeight = this.elH

      return false
    },
    resizeStop() {

      window.removeEventListener('mousemove', this.handleResize);
      window.removeEventListener('mouseup', this.resizeStop);
      this.isResizing = false;
      this.$emit('display-vdr' , false)
      this.element.active = false
      this.editElement()
    },
    handleResize(event) {
      this.$emit('display-vdr' , true)
      this.element.active = true

      if ( !this.isResizing ) {
        return
      }

      this.isResizing = true

      let deltaX = event.clientX - this.startX
      let deltaY = event.clientY - this.startY

      let x = this.initialX
      let y = this.initialY

      let x2 = this.elX+ this.elW
      let y2 = this.elY+ this.elH

      let width = this.initialWidth
      let height = this.initialHeight

      if ( this.resizeHandle.includes('r')) {
        if ( width + deltaX > 0) {
          width +=  deltaX
        } else {
          width =  this.cellWidth
        }
      }

      if ( this.resizeHandle.includes('l')) {
        if ( width - deltaX > this.cellWidth  ) {
          x +=  deltaX
          width +=  -deltaX
        } else {
          x = this.initialX + this.initialWidth - this.cellWidth
          width = this.cellWidth
        }
      }

      if ( this.resizeHandle.includes('b')) {
        if ( height + deltaY > 0) {
          height += deltaY
        } else {
          height = this.cellHeight
        }

      }

      if ( this.resizeHandle.includes('t')) {
        if ( height - deltaY > this.cellHeight  ) {
          y +=  deltaY
          height += -deltaY
        } else {
          y = this.initialY + this.initialHeight - this.cellHeight
          height = this.cellHeight
        }

      }

      if ( x < 0 ) {
        x = 0
        width = x2
      }

      if ( y < 0 ) {
        y = 0
        height = y2
      }


      if ( x + width > this.EDITOR_BLOCK_WIDTH(this.block.config.width , true , this.block.config.full_width || false ) ) {
        width = this.EDITOR_BLOCK_WIDTH(this.block.config.width , true , this.block.config.full_width || false ) - this.initialX
      }

      if ( this.textActualHeight && this.textActualHeight !== 0 && this.resizableElementsArray.includes(this.element.type)  && this.textActualHeight > height   ) {
        height = this.textActualHeight
      }

      this.elX= x
      this.elY= y
      this.elW = width
      this.elH = height



      this.synchronizeGrid()

    },
    // dragging
    dragStart(event) {
      if ( this.textFocused || this.textEditReady ) {
        return
      }
      window.addEventListener('mouseup', this.dragStop);
      this.dragTimeout = setTimeout(()=> {
        this.isDragging = true
        window.addEventListener('mousemove', this.handleDrag);

        this.startX = event.clientX
        this.startY = event.clientY
        this.initialX = this.elX
        this.initialY = this.elY
        this.initialX2 = this.element[this.DIM_KEY['x2']]
        this.initialY2 = this.element[this.DIM_KEY['y2']]
        this.dragTimeout = null
      },150)

    },
    dragStop() {
      if (this.dragTimeout) {
        clearTimeout(this.dragTimeout)
        window.removeEventListener('mouseup', this.dragStop);
        return
      }
      window.removeEventListener('mousemove', this.handleDrag);
      window.removeEventListener('mouseup', this.dragStop);
      this.isDragging = false;
      this.$emit('display-vdr' , false)
      this.element.active = false
      this.editElement()
    },
    handleDrag( event ) {

      this.$emit('display-vdr' , true)
      this.element.active = true
      // new drag
      if ( !this.isDragging ) {
        return
      }
      if ( this.textFocused ) {
        return this.dragStop()
      }

      let deltaX = event.clientX - this.startX
      let deltaY = event.clientY - this.startY
      let x = this.initialX + deltaX
      let y = this.initialY + deltaY

      if ( x < 0 ) {
        x = 0
      }
      if ( y < 0 ) {
        y = 0
      }

      if ( x + this.elW  >= this.EDITOR_BLOCK_WIDTH(this.block.config.width , true , this.block.config.full_width || false)   ) {
        x = this.EDITOR_BLOCK_WIDTH(this.block.config.width , true , this.block.config.full_width || false) - this.elW
      }

      // old code
      this.$emit('text-focus' , false)
      this.isDragging = true
      this.$store.state.avalon.selectedElement = this.element.id

      this.elX = x
      this.elY = y

      this.synchronizeGrid()

    },

    // Grid Synchronize Functions
    synchronizeGrid(heightUpdate = false , saveElement = false) {
      // textEditing = null , isDragging = false
      let initialDimensions = {
        width : this.element.x2 - this.element.x1,
        widthSM : this.element.smX2 - this.element.smX1,
        height : this.element.y2 - this.element.y1,
        heightSM : this.element.smY2 - this.element.smY1
      }

      let columnWidth = this.cellWidth  + this.gridGapHorizontal
      let rowHeight = this.cellHeight + this.gridGapVertical

      this.element[this.DIM_KEY['x1']] =  Math.round( this.elX/columnWidth ) + 1
      this.element[this.DIM_KEY['y1']] =  Math.round( this.elY/rowHeight ) + 1

      if ( this.isDragging ) {
        this.element[this.DIM_KEY['x2']] = this.element[this.DIM_KEY['x1']] + initialDimensions[this.DIM_KEY['width']]
        this.element[this.DIM_KEY['y2']] = this.element[this.DIM_KEY['y1']] + initialDimensions[this.DIM_KEY['height']]
      } else  {
        this.element[this.DIM_KEY['x2']] =  Math.floor( (this.elX+this.elW)/columnWidth ) + 1
        this.element[this.DIM_KEY['y2']] =  Math.floor( (this.elY+this.elH)/rowHeight ) + 1
      }
      this.$emit('check-below' , null)

      if ( this.readyToUpdateHeight ) {
        if ( heightUpdate && this.textActualHeight !== this.height && this.textFocused) {
          this.updatedY = this.element[this.DIM_KEY['y2']]
          this.triggerMoveBelow(this.initialY2 , this.element[this.DIM_KEY['y2']] )
        } else {
          if (heightUpdate && this.resizableElementsArrayNoText.includes(this.element.type) && this.textActualHeight !== this.height ) {
            this.updatedY = this.element[this.DIM_KEY['y2']]
            this.triggerMoveBelow(this.initialY2 , this.element[this.DIM_KEY['y2']] )
          }
        }
      }
      if ( saveElement ) {
        this.editElement()
      }

      this.$emit('input' , this.COPY(this.element))


    },

    // Auto Resize and Move Below Functions
    updateHeight(saveElement = false) {

      if ( !this.blockReadyTimeout) {
        return
      }
      if ( !this.readyToUpdateHeight ) {
        return
      }
      if (this.textActualHeight && this.textActualHeight !== 0 && !(this.textFocused && this.height > this.textActualHeight) ) {
        this.elH = this.textActualHeight
      } else if (this.textActualHeight && this.textActualHeight !== 0 && this.textActualHeight !== this.height ) {
        this.elH = this.textActualHeight
      }

      this.initialHeight = this.height
      this.initialY2 = this.element[this.DIM_KEY['y2']]
      this.synchronizeGrid(true , saveElement)
    },
    triggerMoveBelow(old, value  ) {
      if ( !this.readyToUpdateHeight ) {
        return
      }
      if ( this.isDragging || this.isResizing ) {
        return
      }
      this.updatedY = value
      this.$emit('check-below' , {
        old, value ,
        resize : this.element.config.resize ,
        x1 : this.element[this.DIM_KEY['x1']],
        x2 : this.element[this.DIM_KEY['x2']],
      })
    },
    handleMoveBelow() {

      if ( this.moveBelowData.resize === 'none' ) {
        return
      }

      let regularCondition = this.element[this.DIM_KEY['y1']] > this.moveBelowData.old
      let belowCondition = regularCondition && this.element[this.DIM_KEY['x1']] <= this.moveBelowData.x2 && this.element[this.DIM_KEY['x2']] >= this.moveBelowData.x1

      let condition = this.moveBelowData.resize === 'all' ? regularCondition : belowCondition

      if (  condition ) {

        let updateDistance = this.moveBelowData.value - this.moveBelowData.old

        this.element[this.DIM_KEY['y1']] += updateDistance
        this.element[this.DIM_KEY['y2']] += updateDistance

        this.$emit('input' , this.COPY(this.element))
        this.$emit('update-array' ,this.COPY(this.element)  )
      }

    },

    // Overlap Toolbar Actions
    toolbarMoveBelow() {

      let overlapElements = this.block.elements.filter( el =>
          el[this.DIM_KEY['y1']] <= this.element[this.DIM_KEY['y2']] &&
          el[this.DIM_KEY['y1']] >= this.element[this.DIM_KEY['y1']] &&
          el[this.DIM_KEY['x1']] <= this.element[this.DIM_KEY['x2']] &&
          el[this.DIM_KEY['x2']] >= this.element[this.DIM_KEY['x1']] &&
          el.id !== this.element.id
      )

      if (overlapElements.length === 0) {
        return
      }
      let x1 = Math.min(...overlapElements.map(el => el[this.DIM_KEY['x1']]))
      let x2 = Math.max(...overlapElements.map(el => el[this.DIM_KEY['x2']]))
      let y1 = Math.min(...overlapElements.map(el => el[this.DIM_KEY['y1']]))

      let elementsToMove = this.block.elements.filter( el =>
          el[this.DIM_KEY['y1']] >= y1 &&
          el[this.DIM_KEY['x1']] <= x2 &&
          el[this.DIM_KEY['x2']] >= x1 &&
          el.id !== this.element.id
      )
      let updateDistance = this.element[this.DIM_KEY['y2']] - y1 + 1
      elementsToMove.forEach(item => {
        let index = this.block.elements.findIndex(el => el.id === item.id)
        if ( index !== -1 ) {
          this.block.elements[index][this.DIM_KEY['y1']] += updateDistance
          this.block.elements[index][this.DIM_KEY['y2']] += updateDistance
          this.$emit('update-array' ,this.COPY(this.block.elements[index])  )
        }
      })
      this.$emit('update-block' , this.block)
    },
    toolbarMoveUp() {

      let elementsToMove = this.block.elements.filter( el =>
          el[this.DIM_KEY['y1']] > this.element[this.DIM_KEY['y2']] &&
          el[this.DIM_KEY['x1']] <= this.element[this.DIM_KEY['x2']] &&
          el[this.DIM_KEY['x2']] >= this.element[this.DIM_KEY['x1']] &&
          el.id !== this.element.id
      )
      if (elementsToMove.length === 0) {
        return
      }
      let y1 = Math.min(...elementsToMove.map(el => el[this.DIM_KEY['y1']]))

      let updateDistance = y1 - this.element[this.DIM_KEY['y2']] - 1
      elementsToMove.forEach(item => {
        let index = this.block.elements.findIndex(el => el.id === item.id)
        if ( index !== -1 ) {
          this.block.elements[index][this.DIM_KEY['y1']] -= updateDistance
          this.block.elements[index][this.DIM_KEY['y2']] -= updateDistance
          this.$emit('update-array' ,this.COPY(this.block.elements[index]))
        }
      })
      this.$emit('update-block' , this.block)
    },

    // Text
    quillReady(quill) {
      this.quillVariable = quill
      this.quillReadyTrigger++
    },

    // Technical
    toolbarAction($event) {
      this.toolbarOpened = true
      setTimeout(() => {
        this.toolbarOpened = false
      },100)


      this.$emit('toolbarAction' , { action : $event , id : this.element.id , element : this.element })
      if ( $event === 'edit') {
        this.displaySettings = true
      }
      if ( $event === 'layer+' ) {
        this.element.z++
        this.editElement()
      }
      if ( $event === 'layer-' ) {
        this.element.z--
        this.editElement()
      }
      if ( $event === 'move_all_below' ) {
        this.toolbarMoveBelow()
      }
      if ( $event === 'move_all_up' ) {
        this.toolbarMoveUp()
      }


      if ( $event === 'valign_top' ) {
        this.element.config.valign = null
        this.editElement()
      }
      if ( $event === 'valign_center' ) {
        this.element.config.valign = 'center'
        this.editElement()
      }
      if ( $event === 'valign_bottom' ) {
        this.element.config.valign = 'bottom'
        this.editElement()
      }

    },
    async editElement() {
      if ( this.updateDelay ) {
        clearTimeout(this.updateDelay)
      }
      this.updateDelay = setTimeout(async () => {
        await this.EDIT_ELEMENT(this.element)
        this.$emit('input' , this.COPY(this.element))
      }, 100)

    },
    deselect() {

      if ( this.isSelected && !this.toolbarOpened) {
        if ( this.textEditReadyTimeout ) {
          clearTimeout(this.textEditReadyTimeout)
        }
        this.allowEditText = false
        this.textEditReady = false
        this.isSelected = false
        this.triggerDeselect++
        this.$emit('block-elements-editing' , false)
        if ( this.$store.state.avalon.selectedElement === this.element.id ) {
          this.$store.state.avalon.selectedElement = null
        }
      }
    },

   

  },
  mounted() {
    if ( this.value ) {
      this.element = this.COPY(this.value)
    }
    this.isReady = true

    if ( this.element.new ) {
      this.readyToUpdateHeight = true
    } else {
      setTimeout(() => { this.readyToUpdateHeight = true ; } , 2000)
    }

  }
}
</script>

<style scoped>
.activeBorder {
  outline: 1px solid #ED8A58 !important;
}
.notActiveBorder {

  outline: 1px transparent !important;
}
</style>