<template>

  <div  v-if="design.includes('slider')" >
    <block-slider
        :block="block"
        :navigation-width="navigationWidth"
        :content-width="contentWidth"
    >
      <template #item="{item}">
        <component
            :is="slideComponent"
            :item="item"
            :block-config="block.config"
            :block="block"
        />
      </template>

        <template v-if="['slider_wide'].includes(design)"  #non-moving-part="{item,index}">
          <v-slide-x-transition mode="out-in">
          <component
              :is="slideComponent"
              :item="item"
              :block-config="block.config"
              :block="block"
              non-moving-part
              :key="index"
          />
          </v-slide-x-transition>
        </template>

    </block-slider>
  </div>

  <gallery-tiles
      v-else-if="design.includes('tiles')"
      :config="config"
  />




</template>

<script>
import blockSlider from "@/components/AvalonEditor/Editor/blocks/slider/blockSlider";
import galleryTiles from "@/components/AvalonEditor/Editor/blocks/gallery/galleryTiles";
import gallerySlideVertical from "@/components/AvalonEditor/Editor/blocks/gallery/UI/gallerySlideVertical";
import gallerySlideWide from "@/components/AvalonEditor/Editor/blocks/gallery/UI/gallerySlideWide";

export default {
  name: "blockGallery",
  components : {
    blockSlider,
    gallerySlideVertical,
    gallerySlideWide,

    galleryTiles,

  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    width : {
      default : 300
    },
    navigationWidth : {
      type : Number,
      default : 0
    },
    contentWidth : {
      type : Number,
      default : 840
    }
  },
  computed : {
    slideComponent() {
      switch (this.design) {
        case 'slider_vertical' : return 'gallerySlideVertical'
        case 'slider_wide' : return 'gallerySlideWide'
        default : return 'span'
      }
    },
    design() {
      return this.config.design || 'tiles_regular'
    },
    config() {
      return this.block.config || {}
    },

  }
}
</script>

<style scoped>

</style>