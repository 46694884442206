<template>
  <!-- Content Settings-->
  <div v-if="!format && !isStyle">

    <block-settings-navigation
        v-model="selectedContentCategory"
        :items="navigationSelect"
        :return-action="selectedItemIndex !== null ? () => { selectedItemIndex = null ; selectedContentCategory='items'  } : null"
        portal
    >
      <template #item.heading>
        <block-general-settings
            @input="updateSettings"
            v-model="block.config"
            :block="block"
            content
        />
      </template>
      <template #item.items>
        <draggable
            v-model="block.config.elements"
            :forceFallback="true"
            :empty-insert-threshold="120"
            v-bind="opt"
            @start="drag = true"
            @change="updateSettings"
            @end="drag = false"
            tag="div"
            handle=".drag"
        >
          <transition-group class="d-flex flex-wrap" type="transition" :name="!drag ? 'flip-list' : null">
            <v-hover
                #default="{hover}"
                v-for="(item,index) in block.config.elements" :key="index"
            >
              <v-sheet
                  @click="selectItem(index)"
                  :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                  style="position: relative"
                  class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-3 "
                  :class="[{'mr-3' : index%2 === 0}]"
              >
                <div style="position: absolute; bottom: 5px; right: 5px" class="d-flex">
                  <v-btn
                      v-if="hover"
                      @click.stop="duplicateItem(index)"
                      style="background-color: #FFFFFF; "
                      :color="wsACCENT"
                      class="mr-1"
                      small
                      icon >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="hover"
                      @click.stop="deleteItem(index)"
                      style="background-color: #FFFFFF;"
                      :color="wsACCENT"
                      small
                      icon >
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>


                <ws-img class="drag" :src="item.img" width="130" height="90" />
              </v-sheet>
            </v-hover>
          </transition-group>
        </draggable>
        <portal to="block_settings_footer" :disabled="items.length === 0">
          <image-param-picker
              @input="addImage"
              :button-only="items.length > 0"
              height="500"
              button-only-block-style
              cal
              :class="[{'mb-2' : items.length > 0}]"
              no-border
          />
        </portal>

      </template>
      <template #item.edit_item>
        <ws-text-field
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].title"
            :label="$t('Title')"
            :placeholder="$t('EnterTitle')"
            clearable
            avalon-style
        />
        <ws-text-field
            class="mt-5"
            @change="updateSettings"
            v-model="block.config.elements[selectedItemIndex].text"
            :label="$t('Description')"
            :placeholder="$t('Description')"
            clearable
            avalon-style
        />

        <image-param-picker
            class="mt-8"
            @input="updateSettings"
            v-model="block.config.elements[selectedItemIndex].img"
        />
      </template>
    </block-settings-navigation>

  </div>
  <!-- Format Settings-->
  <div v-else-if="format">
    <!-- Tiles Format Settings-->
    <div v-if="block.config.design === 'tiles_regular'">
      <!-- Columns-->
      <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.blocks_in_row') }}</h4>
      <ws-button-group
          v-model="block.config.items_per_row"
          @input="$emit('input' , COPY(block.config))"
          :items="itemsPerPageSelect"
          class="mb-2 mt-2"
      />
      <!-- Aspect ratio -->
      <a-parameter-select
          class="mt-10"
          @input="updateSettings"
          v-model="block.config.tiles_aspect_ratio"
          :items="aspectRatioSelect"
          label="format.aspect_ratio.image"
          :label-color="wsACCENT"
          :value-color="wsACCENT"
          null-text="Default"
      />
      <v-divider
          class="mt-3 pb-3"
          :style="`border-color : ${wsDARKLIGHT}`"
      />

    </div>
    <!-- Slider Format Settings-->
    <div v-if="['slider_wide' , 'slider_vertical'].includes(block.config.design)">
      <!-- Columns-->
      <ws-button-group
          v-model="block.config.description_align"
          @input="updateSettings"
          :items="alignmentSelect"
          label-avalon="format.slider.texts_horizontal_alignment"
          class="mb-2 mt-2"
      />

      <a-parameter-slider
          @change="updateSettings(); "
          v-model="block.config.image_height"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="ImageHeight"
          min="200"
          max="900"
          class="mt-4"
          unit="px"
          light
      />

      <a-parameter-slider
          v-if="block.config.design === 'slider_wide'"
          @change="updateSettings(); "
          v-model="block.config.slide_width_percent"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="ImageWidth"
          unit="%"
          min="40"
          max="100"
          class="mt-4"
          light
      />
      <a-parameter-slider
          @change="updateSettings(); "
          v-model="block.config.auto_change_slide"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          label="format.slider.auto_change_slide"
          :unit="$t('sec')"
          min="0"
          max="15"
          class="mt-4"
          light
      />

      <a-parameter-switch
          class="mt-7 "
          v-model="block.config.hide_arrows"
          @input="updateSettings"
          label="format.hide_arrows"
          light
      />
      <v-divider
          class="mt-3 pb-3"
          :style="`border-color : ${wsDARKLIGHT}`"
      />

      <div v-if="block.config.design === 'slider_vertical' ">
        <a-parameter-slider
            @change="updateSettings"
            v-model="block.config.overlay_opacity"
            :track-color="wsDARKLIGHT"
            :value-color="wsACCENT"
            label="BackgroundFade"
            min="0"
            max="100"

            light
        />

        <color-param-picker
            @input="updateSettings"
            v-model="block.config.overlay_color"
            label="OverlayColor"
            :label-color="wsDARKER"
            class="pt-5"
            divider
            font-regular

        />
      </div>


    </div>

  </div>
  <!-- Style Settings-->
  <div v-else-if="isStyle">
  </div>
</template>

<script>
import draggable from "vuedraggable";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
import blockGeneralSettings from "@/components/AvalonEditor/Dialogs/blocks/blockGeneralSettings";
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";

export default {
  name: "blockGallerySettings",
  components : {
    draggable,
    imageParamPicker,
    blockSettingsNavigation,
    blockGeneralSettings,
    colorParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      selectedItemIndex : null ,
      selectedContentCategory : null,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
      alignmentSelect : [
        { icon : 'mdi-format-align-left' , value : 'left' },
        { icon : 'mdi-format-align-center' , value : 'center' },
        { icon : 'mdi-format-align-right' , value : 'right' },
      ],
      itemsPerPageSelect : [
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 }
      ],
      aspectRatioSelect : [
        { text : '1:1'  , value : 1      },
        { text : '2:3'  , value : 0.6667 },
        { text : '3:2'  , value : 1.5    },
        { text : '3:4'  , value : 0.75   },
        { text : '4:3'  , value : 1.3333 },
        { text : '9:16' , value : 0.5625 },
        { text : '16:9' , value : 1.7778 }
      ],
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text: this.$t('TitleAndDetails'), value: 'heading' },
        { text: this.$t('Content'), value: 'items' },
        { text: this.$t('EditItem'), value: 'edit_item', hidden: true}
      ]
    },
    items() {
      return this.block.config.elements || []
    }
  },
  methods : {
    selectItem(index) {
      this.selectedItemIndex = index;
      this.selectedContentCategory='edit_item'
    },
    addImage(image = null) {
      this.block.config.elements.push( { img : image })
      this.updateSettings()
    },
    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.block.config)
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.block.config)
    },
    duplicateItem(index) {
      let item =  this.COPY(this.block.config.elements[index])
      this.block.config.elements.splice(index , 0 , item)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    }
  },
  beforeMount() {
    if ( !this.block.config.overlay_opacity && this.block.config.overlay_opacity !== 0 ) {
      this.block.config.overlay_opacity = 30
    }
    if ( !this.block.config.overlay_color && this.block.config.overlay_color !== 0 ) {
      this.block.config.overlay_color = 'text_dark'
    }
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>