<template>
  <div class="d-flex align-center">

    <!-- Text Size style-->
    <ft-select
        ref="toolbarWrapper"
        @input="formatHeading"
        v-model="selectedFontStyle"
        :items="fontStyles"
        delay-close
        @expand="$emit('expand' , $event);"

    >
      <template #default="{text}">
        <v-sheet
            @click="$emit('expand' , true)"
            class="d-flex align-center"
            color="transparent"
            min-width="110"
        >
          <h5 class="text-no-wrap" :style="`color  : ${wsACCENT}`">{{ text || $t('font.p') + ' 1' }}</h5>
          <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
        </v-sheet>
      </template>

    </ft-select>

    <!-- Text Color-->
    <v-menu
        v-model="colorDropdown"
        key="color_picker_menu"
        :close-on-content-click="false"
        offset-y
    >
      <template v-slot:activator="{attrs,on}">
        <v-btn class="px-0 mx-0" :color="GET_AVALON_COLOR(elementColor)" v-bind="attrs" v-on="on" text min-width="35" width="35">
          <v-icon>mdi-circle</v-icon>
        </v-btn>
      </template>
      <v-sheet  class="pa-2 salvation-toolbar" width="250">
        <colorParamPicker
            key="color_picker"
            @input="formatColor"
            v-model="elementColor"
            only-colors
        />
      </v-sheet>
    </v-menu>

    <!-- Format Buttons -->
    <template
        v-for="(item, i ) in toolbarItemsSelect"
    >
      <!-- Regular button-->
      <div v-if="!item.separator && !item.children && !item.linkDropdown" :key="i" class="pr-1">
        <v-btn
            @click="format(item.value)"
            :color="formatIsSet(item.value) ? wsBACKGROUND : wsACCENT "
            class="px-0 mx-0"
            min-width="30"
            elevation="0"
            width="30"
            height="30"
            :text="!formatIsSet(item.value)"
        >
          <v-icon size="20" :color="wsACCENT">{{  item.icon  }} </v-icon>
        </v-btn>
      </div>
      <!-- Link dropdown-->
      <v-menu
          v-model="linkDropdown"
          v-else-if="item.linkDropdown"
          :key="i + 'link_dropdown'"
          offset-y
          :close-on-content-click="false"

      >
        <template v-slot:activator="{attrs,on}">
          <v-btn
              v-bind="attrs" v-on="on"
              @click="format(item.value)"
              :color="!!config.link ? wsBACKGROUND : wsACCENT "
              class="px-0 mx-0"
              min-width="30"
              elevation="0"
              width="30"
              height="30"
              :text="!config.link"
          >
            <v-icon size="20" :color="wsACCENT">{{  item.icon  }} </v-icon>
          </v-btn>
        </template>
        <v-sheet style="border-radius: 8px" class="pa-3 mt-1 ">
          <ws-text-field
              v-model="url"
              :label="$t('InsertLink')"
              width="300px"
              placeholder="https://example.com"
          />
          <div class="mt-3 d-flex justify-space-between">

            <v-btn @click="format(['link' , false ])" icon>
              <v-icon :color="config.link ? wsACCENT : wsBACKGROUND" >mdi-delete-outline</v-icon>
            </v-btn>

            <ws-button
                @click="format( ['link' , url] ); linkDropdown = false"
                label="Apply"
            />

          </div>
        </v-sheet>

      </v-menu>
      <!-- Regular dropdown-->
      <ft-select
          v-else-if="item.children"
          @input="format($event)"
          @expand="$emit('expand' , $event);"
          :key="i + 'dropdown'"
          :items="item.children"
          min-width="0"
          delay-close
      >
        <v-btn
            :color="wsACCENT"
            class="px-0 mx-0"
            min-width="30"
            elevation="0"
            width="30"
            height="30"
            text
        >
          <v-icon size="20" :color="wsACCENT">{{ getFormatDropdownIcon(item)   }} </v-icon>
        </v-btn>
      </ft-select>
      <!-- Divider -->
      <v-divider
          v-else
          :style="`border-color : ${wsBACKGROUND}`"
          :key="i + 'divider'"
          class="mx-2 my-2"
          vertical
      />
    </template>


  </div>
</template>

<script>
// import Quill from 'quill'
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
export default {
  name: "editTextElementToolbarButtons",
  components : {
    colorParamPicker
  },
  props : {
    elementId : {},
    elementColor : {},
    quill : {},
    quillReadyTrigger : {
      type : Number
    }
  },
  data() {
    return {
      url : '',
      colorDropdown : false,
      linkDropdown : false,
      selectedFontStyle : '',
      selectionIndex : 0,
      selectionRange : 0,
      config : {
        bold : false,
        italic : false,
        underline : false,
        list : false,
        list_ordered : false,
        align_left : false,
        align_right : false,
        align_center : false,
        link : null
      }
    }
  },
  computed : {
    toolbarItemsSelect() {
      return [
        { value : 'bold' , icon : 'mdi-format-bold'   },
        { value : 'italic' , icon : 'mdi-format-italic'   },
        { value : 'underline' , icon : 'mdi-format-underline'   },
        { icon : 'mdi-link' , linkDropdown : true   },
        { value : 'align' , icon : 'mdi-format-align-left' , children : [
            { value : [ 'align' , false]   , icon : 'mdi-format-align-left'   },
            { value : [ 'align' , 'center'] , icon : 'mdi-format-align-center'   },
            { value : [ 'align' , 'right']  , icon : 'mdi-format-align-right'   },
          ]
        },
        { value : 'list' , icon : 'mdi-format-list-bulleted'   },
        { value : ['list' , 'ordered'] , icon : 'mdi-format-list-numbered'   },
        { value : 'clear' , icon : 'mdi-cancel'   },
        { separator : 'true'  },
      ]

    },
    fontName() {
      return this.fontStyles.find(el => el.value = ['header' , 1 ] ).text
    },
    fontStyles() {
      return [
        { text : this.$t('avalon.color_styles.h1') , value : 1 },
        { text : this.$t('avalon.color_styles.h2') , value : 2 },
        { text : this.$t('avalon.color_styles.h3') , value : 3 },
        { text : this.$t('avalon.color_styles.h4') , value : 4 },
        { text : this.$t('avalon.color_styles.h5') , value : 5 },
        { text : this.$t('avalon.color_styles.p')  , value : false},
        { text : this.$t('avalon.color_styles.h6') , value : 6 },
      ]
    }
  },
  watch : {
    quillReadyTrigger() {
      if ( this.quill ) {
        this.quill.on('selection-change', this.handleSelectionChange);
      }
    },
    colorDropdown(value) {
        this.$emit('expand',value)
    },
    linkDropdown(value) {
      this.url = this.config.link
      this.$emit('expand',value)
    },
    selectionIndex() {
      this.$emit('selection-index' , this.selectionIndex )
    },
    selectionRange() {
      this.$emit('selection-range' , this.selectionRange )
    }
  },
  methods : {
    formatColor(value) {
      this.quill.setSelection(this.selectionIndex,this.selectionRange)

      if ( value.includes('#') )  {
        this.format(['color' , value , 'user'])
      } else {
        this.format(['wsColor' , 'ws_' + value ] ,  )
      }

    },
    format(value) {
        const format = this.quill.getFormat();
        const isSet = !!format[value];
        let param = true
        let param2 = null

        if ( Array.isArray(value) ) {
          param2 = value[2] || null
          param = value[1]
          value = value[0]
        }

        if ( value === 'clear' ) {
          this.quill.removeFormat(this.selectionIndex, this.selectionRange);
          return
        }
        if ( !isSet ) {
          this.quill.format(value, param , param2);
        } else {
          this.quill.format(value, false);
        }

        if ( param === true ) {
          this.config[value] = !isSet
        } else {
          this.config[`${value}_${param}`] = !isSet
        }


    },
    formatHeading(value) {
      this.quill.setSelection(this.selectionIndex,this.selectionRange)
      this.quill.format('header' , value )
    },

    handleSelectionChange(range) {
      if ( !range ) {
        return
      }
      const format = this.quill.getFormat();
      this.config.bold = !!format.bold;
      this.config.italic = !!format.italic;
      this.config.underline = !!format.underline;
      this.config.list = !!format.list &&  format.list !== 'ordered';
      this.config.list_ordered = format.list === 'ordered';
      this.config.align_left = format.align === false;
      this.config.align_center = format.align === 'center';
      this.config.align_right = format.align === 'right';

      this.config.link = format.link ? format.link : null

      this.selectedFontStyle = format.header ? format.header : 'p'


      if (range) {
        this.selectionIndex = range.index
        this.selectionRange = range.length
      }
    },

    // technical
    formatIsSet(value) {
      if ( Array.isArray(value) ) {
        return this.config[`${value[0]}_${value[1]}`]
      }
      return this.config[value]

    },
    getFormatDropdownIcon(item) {

      let selectedChild = item.children.find( el => this.formatIsSet(el.value))

      if ( selectedChild ) {
        return selectedChild.icon
      }
      return item.icon
    }


  },
  mounted() {

    if ( this.quill ) {
      this.quill.on('selection-change', this.handleSelectionChange);
    }
  },
  beforeDestroy() {
    this.quill.off('selection-change', this.handleSelectionChange);
  }
}
</script>

<style scoped>

</style>