<template>
  <div>
    <div v-if="label" class="d-flex justify-space-between align-center mb-2">
      <h5 style="color : #4F5A6E; font-size: 13px; font-weight: 500 " >  {{  label  }} </h5>
      <ws-tooltip v-if="tooltip" :text="tooltip">
        <v-icon :color="wsDARKLIGHT">mdi-information-outline</v-icon>
      </ws-tooltip>
    </div>
    <v-sheet
        :style="`border-bottom : 1px solid ${wsDARKLIGHT}`"
        class="py-2"
        ref="containerRef"
        min-height="40"
    >
      <h5 v-if="displayPlaceholder"
          :style="`color : ${wsDARKLIGHT}`"
          style="position: absolute; font-weight: 400; font-size: 14px;">
        {{ $t('EnterText') }}
      </h5>
      <quill-editor
          v-model="text"
          @input="processTextInput"
          style="width: 100%; font-size: 50px; padding: 0 !important; margin: 0 !important"
          :options="editorOptions"
          ref="quillRef"
          @blur="editText"
          :id="`ws-avalon-text-input`"
      />

      <portal to="dialog_top">
        <v-sheet
            class="px-2 wsRoundedLight"
            style="position : absolute; z-index : 999999999999999999; transition: opacity 0.3s ease; "
            :style="`left : ${x}px; top : ${y - 70}px; border : 1px solid ${wsBACKGROUND}; opacity : ${displayMenu ? 1 : 0}; ${!displayMenu ? 'pointer-events: none' : ''}`"

        >
          <avalon-text-editor-toolbar-absolute
              v-if="ready"
              :quill="quill"
              :quillReadyTrigger="quillReadyTrigger"
              @width="width = $event"
              @selection="handleSelection"
          />
        </v-sheet>

      </portal>


    </v-sheet>
  </div>

</template>

<script>
import {mapActions} from "vuex";


import {quillEditor} from 'vue-quill-editor'
// import Quill from 'quill'
import 'quill/dist/quill.core.css'
import avalonTextEditorToolbarAbsolute from "@/components/AvalonEditor/UI/wsAvalonTextInput/avalonTextEditorToolbarAbsolute";

export default {
  name: "elementButtonEditor",
  components : {
    quillEditor,
    avalonTextEditorToolbarAbsolute
  },
  props : {
    value : {
      type : String,
    },
    label : {
      type : String,
    },
    tooltip : {
      type : String,
    },
  },
  data() {
    return {
      displayMenu : true,
      text : '',
      ready : false,
      quillReadyTrigger : 0,
      quill : null,
      x : -1000,
      y : -1000,
      width : 200,
      displayPlaceholder : true,
    }
  },
  computed : {
    editorOptions() {
      return {
        scrollingContainer: null,
        modules : {
          toolbar : false
        },
        placeholder : false,
        theme: 'bubble',
        fontSize: 20,
        padding : 0
      }
    },
    domElement() {
      return this.$refs.containerRef
    },
  },
  watch : {
    value() {
      if ( this.text !== this.value ) {
        this.text = this.value
      }
    },
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    processTextInput() {
      this.displayPlaceholder = false
      let quill = this.$refs.quillRef
      if ( quill  ) {
        let text = quill.$el.textContent
        console.log('quill' , text ,  text === ' ' ,  typeof text === "undefined" )

        if ( !text || text === ' ' || typeof text === "undefined") {
          this.displayPlaceholder = true
        }
      }
      let tags = ['p','li','ol']
      tags.forEach(tag => {
        const domElements = document.querySelectorAll(`#ws-avalon-text-input ${tag}`);
        domElements.forEach(domElement => {
          domElement.style.fontFamily = "AmbulatoriaD"
          domElement.style.fontWeight = 400
          domElement.style.fontSize= '14px'
          if ( tag === 'li') {
            domElement.style.marginLeft = '-24px'
            domElement.style.paddingRight= '0px'
          }

        })
      })


    },
    editText() {
      this.$emit('input' , this.text);
    },

    handleSelection({ y , range }) {
      if (range ) {
        if ( range.length ) {
          let windowWidth = window.innerWidth
          this.x = windowWidth - this.width - 62
          this.y = y


          this.displayMenu = true
          return
        }
      }
      this.displayMenu = false
    },

  },
  mounted(){

    this.processTextInput();
    if ( this.value ) {
      this.text = this.value
    }
    setTimeout(()=> {
      this.quill = this.$refs.quillRef.quill
      this.$refs.quillRef.quill.blur()
      this.ready = true
      this.quillReadyTrigger++
      this.processTextInput();

    } , 300)

  },

}
</script>

<style scoped lang="scss">
@import "~@/assets/css/quill-disable-padding.css";
</style>