<template>

  <v-hover #default="{hover}"  >
  <v-sheet
      @click="$emit('click')"
      :width="width + 8"
      :height="height + 16"
      style="position: relative; border-radius: 2px "
      :style="`border : ${isSelected ? 2 : 1}px solid ${hover || isSelected ? wsDARKLIGHT : wsBACKGROUND}`"
      class="overflow-hidden py-2 px-1 pointer"
  >
    <v-sheet
        width="700"
        :style="`transform : scale(${width/700});`"
        class="overflow-hidden "
        style="transform-origin: top left;"
    >
      <view-list
          v-if="element.type === 'list' "
          :element="element"
          :block-style="blockStyle"
          ref="element"
      />
    </v-sheet>


  </v-sheet>
  </v-hover>

</template>

<script>

export default {
  name: "previewBlock",
  components : {
    viewList : () => import("@/components/AvalonEditor/View/elements/viewList")
  },
  props : {
    blockStyle : {
      type : String,
      default: 'light_1'
    },
    element : {
      type : Object,
      default() { return {} }
    },
    width : {
      type : Number,
      default : 600
    },
    isSelected : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      height : 10,
      ready : false
    }
  },
  methods : {
    getHeight() {
      let element = this.$refs.element
      if ( !element ) {
        return
      }
      this.height = element.$el.clientHeight * (this.width / 700)
    }
  },
  mounted() {
    setTimeout(() => {
      this.getHeight()
      this.ready = true
      setTimeout(() => {
        this.getHeight()
        this.ready = true
      },400)
    } , 10 )
  }


}
</script>

<style scoped>

</style>